import { Footer } from "../../components/Footer";
import { NavBar } from "../../components/NavBar";

export const PublicLayout: React.FC = ({
    children
}) => {
    return (
        <>
            <div className="pt-20 min-h-screen">
                <NavBar />
                {children}
            </div>
            <Footer />
        </>
    );
};