import clsx from "clsx";
import { HTMLProps } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { InputError } from "../Inputs";

interface SmartTextAreaProps {
  register: UseFormRegisterReturn;
  label?: string;
  error?: string;
}

export function SmartTextArea({
  register,
  label,
  error,
  ...props
}: Partial<SmartTextAreaProps & HTMLProps<HTMLTextAreaElement>>): JSX.Element {
    const id = `${props.name}-${label?.replace(' ', '')}`;

    return (
        <div className="w-full">
            {label &&
                <label htmlFor={id} className="block text-sm font-medium text-black mb-1">{label}</label>
            }
            {register &&
                <textarea id={id} className={clsx(
                "h-44 w-full border border-gray-200 p-2 rounded-md",
                  error && "border-2 border-red-500 outline-none"
                )} {...register} {...props}></textarea>
            }
            {error && <InputError error={error} />}
        </div>
    );
}