import { createContext, useContext } from "react";
import { IApiClientProgram, IApiClientProgramPopulated, IApiProgram, IApiProgramDay, IApiProgramWeek } from "../../../api/models/program";
import { SetKeys } from "./ProgramEditorProvider";
import { DropResult } from 'react-beautiful-dnd';

export interface IProgramEditorContext {
    program?: IApiProgram;
    programWeeks?: IApiProgramWeek[];
    programDays?: IApiProgramDay[];
    clientProgram?: IApiClientProgramPopulated;
    edit?: IApiProgramDay;
    isUpdating: boolean;
    notFound?: boolean;
    getProgramDay: (dayNo: number, programWeekId: string) => IApiProgramDay | null;
    editProgramDay: (dayNo?: number, programWeekId?: string) => void;
    editProgramSet: (workoutStepId: string, workoutSetId: string, key: SetKeys, value: number) => void;
    editProgramDayMeta: (name: string) => void;
    saveProgramDay: () => void;
    deleteProgramDay: (id: string) => void;
    deleteProgramWeek: (id: string) => void;
    addWeek: (programWeekId?: string) => void;
    addExercise: (exerciseId: string) => void;
    addExercises: (selection: string[], workoutStepId?: string) => void;
    removeExercise: (workoutStepId: string) => void;
    addSet: (workoutStepId: string) => void;
    addSuperset: (exerciseId: string, workoutStepId: string) => void;
    removeSet: (workoutStepId: string, workoutSetId: string) => void;
    handleDragAndDrop: (result: DropResult) => void;
}

const placeholderFunc = () => { throw new Error('Not implemented.'); };

export const ProgramEditorContext = createContext<IProgramEditorContext>({
    programWeeks: [],
    programDays: [],
    isUpdating: false,
    getProgramDay: placeholderFunc,
    editProgramDay: placeholderFunc,
    editProgramSet: placeholderFunc,
    editProgramDayMeta: placeholderFunc,
    saveProgramDay: placeholderFunc,
    deleteProgramDay: placeholderFunc,
    deleteProgramWeek: placeholderFunc,
    addWeek: placeholderFunc,
    addExercise: placeholderFunc,
    addExercises: placeholderFunc,
    removeExercise: placeholderFunc,
    addSet: placeholderFunc,
    addSuperset: placeholderFunc,
    removeSet: placeholderFunc,
    handleDragAndDrop: placeholderFunc,
});

export const useProgramEditor: () => IProgramEditorContext = () => useContext(ProgramEditorContext);