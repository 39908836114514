import { Dialog, Popover, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useProgramEditor } from "../ProgramEditor/ProgramEditorContext/ProgramEditorContext";
import { WorkoutEditorStep } from ".";
import clsx from 'clsx';
import { FiPlus, FiTrash2 } from 'react-icons/fi';
import { ExercisePicker } from '../ExercisePicker';
import { Button } from '../Button';

export const WorkoutEditor: React.FC = () => {
    const [exercisePickerIsOpen, setExercisePickerIsOpen] = useState(false);
    const [supersetParentId, setSupersetParentId] = useState<string>();

    const { edit: programDay, isUpdating, editProgramDay, editProgramDayMeta, saveProgramDay, deleteProgramDay, addExercises } = useProgramEditor();
    
    const handleAddExercises = (selected: string[]) => {
        addExercises(selected, supersetParentId);
        handleCloseExercisePicker();
    };

    const handleAddSupersetExercises = (exerciseStepId: string) => {
        setSupersetParentId(exerciseStepId);
        setExercisePickerIsOpen(true);
    };

    const handleCloseExercisePicker = () => {
        setExercisePickerIsOpen(false);
        setSupersetParentId(undefined);
    };

    const handleClose = () => {
        editProgramDay();
    };

    return (
        <>
        <Transition appear show={!!programDay} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-20 overflow-y-auto bg-gray-800 bg-opacity-80"
                    onClose={handleClose}
                    >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                        >
                        &#8203;
                        </span>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                        >
                        <div className="inline-block w-full max-w-3xl my-8 text-left align-middle transition-all transform">
                            <div className="grid grid-cols-2 gap-4">
                                <div className="bg-white shadow-xl rounded-md p-6 col-span-2 flex flex-col min-h-screen-padded">
                                    {/* {isUpdating &&
                                        <RelativeLoader />
                                    } */}
                                    {!isUpdating && programDay &&
                                    <>
                                    <div className="flex items-center border-b">
                                        <div>
                                            <Dialog.Title
                                                as="h3"
                                                className="text-xl font-medium leading-6 text-gray-900"
                                            >
                                                Day {programDay?.dayNo + 1}
                                            </Dialog.Title>
                                            <div className="mt-2 pb-4">
                                                <input type='text' value={programDay?.name} placeholder='Workout name (optional)' className="text-sm text-gray-500 outline-none" onChange={e => editProgramDayMeta(e.currentTarget.value)} />
                                            </div>
                                        </div>
                                        <div className="ml-auto flex items-center">
                                            {programDay._id &&
                                                <Popover className="relative">
                                                {({ open }) => (
                                                    <>
                                                        <Popover.Button className="w-12 h-12 rounded-full flex items-center justify-center transition-all hover:bg-gray-50">
                                                            <FiTrash2 />
                                                        </Popover.Button>
                                                        <Transition
                                                            as={Fragment}
                                                            enter="transition ease-out duration-200"
                                                            enterFrom="opacity-0 translate-y-1"
                                                            enterTo="opacity-100 translate-y-0"
                                                            leave="transition ease-in duration-150"
                                                            leaveFrom="opacity-100 translate-y-0"
                                                            leaveTo="opacity-0 translate-y-1"
                                                            >
                                                            <Popover.Panel className="absolute z-10 w-screen max-w-xs mt-3 right-0">
                                                                <div className="overflow-hidden rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                                                                <div className="relative grid gap-8 bg-white p-4">
                                                                    Are you sure you want to delete Day {programDay.dayNo + 1}?
                                                                    <div className="grid gap-4 grid-cols-2">
                                                                        <Popover.Button as={Button} type='button' variant='transparent'>Cancel</Popover.Button>
                                                                        <Button type='button' variant='danger' onClick={() => deleteProgramDay(programDay._id ?? '')}>Delete</Button>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </Popover.Panel>
                                                            </Transition>
                                                    </>
                                                )}
                                            </Popover>
                                            }
                                        </div>
                                    </div>
                                    <Droppable droppableId='editor-workout-steps' type='WORKOUT_STEP'>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                className={clsx("w-full h-full transition-all", snapshot.isDraggingOver && "bg-blue-100 p-4")}>
                                                {programDay?.workoutSteps?.filter(workoutStep => !workoutStep.parentStepId).map(workoutStep =>
                                                    <WorkoutEditorStep 
                                                        workoutStep={workoutStep}
                                                        supersetSteps={programDay.workoutSteps.filter(step => step.parentStepId === workoutStep.workoutStepId)}
                                                        onAddSuperset={handleAddSupersetExercises}
                                                        key={workoutStep.workoutStepId} />
                                                )}
                                                {provided.placeholder}
                                                <div className='mt-0'>
                                                    <Button size="small" onClick={() => setExercisePickerIsOpen(true)}>Add Exercise +</Button>
                                                </div>
                                            </div>
                                        )}
                                    </Droppable>
                                    </>
                                    }
                                    <div className="mt-auto flex border-t pt-4">
                                        <div className="ml-auto">
                                            <Button onClick={saveProgramDay}>Save</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Transition.Child>
                    </div>
                    <ExercisePicker open={exercisePickerIsOpen} onMakeSelection={handleAddExercises} />
                    </Dialog>
                </Transition>
        </>
    );
};