import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { WORKOUT_DIFFICULTY, WORKOUT_LOCATION } from "../../../api/models/program";
import { SmartInput, SmartSelect, SmartTextArea } from '../../../components/SmartForm';
import { Button } from '../../../components/Button';
import { useMutation } from 'react-query';
import { createWorkoutProgram, updateWorkoutProgramCoreData } from '../../../api/program';
import { useHistory } from 'react-router-dom';
import { AppPathsAdmin } from '../../../routes/AppRoutes';
import { SmartImageInput } from '../../../components/SmartForm/SmartImageInput';
import { ChangeEvent, useState } from 'react';
import { toast } from 'react-toastify';

export interface CreateProgramFormInputs {
    _id: string,
    name: string;
    description: string;
    difficulty: WORKOUT_DIFFICULTY;
    image: string;
    video: string;
    previewVideo?: string;
    requiresPremium: boolean;
    location: WORKOUT_LOCATION;
    hidden: boolean;
}

interface CreateProgramFormProps {
    defaultValues?: CreateProgramFormInputs;
}

const schema = yup.object({
    name: yup.string().required("Program name is required"),
    description: yup.string().required("Program description is required"),
    difficulty: yup.number().required("Program difficulty is required"),
    image: yup.string().notRequired(),
    video: yup.string().notRequired(),
    previewVideo: yup.string().notRequired(),
    requiresPremium: yup.boolean().required("Program premium status is required"),
    location: yup.number().required("Program location is required"),
    hidden: yup.boolean().required("Program visibility status is required")
}).required();

const workoutDifficultyOptions = [
    {
        value: 0,
        label: 'Beginner'
    },
    {
        value: 1,
        label: 'Intermediate'
    },
    {
        value: 2,
        label: 'Advanced'
    },
];

const workoutLocationOptions = [
    {
        value: 0,
        label: 'Gym'
    },
    {
        value: 1,
        label: 'Home'
    },
];

export const CreateProgramForm: React.FC<CreateProgramFormProps> = ({
    defaultValues
}) => {
    const history = useHistory();
    const [image, setImage] = useState<File>();

    const { register, handleSubmit, formState: { errors } } = useForm<CreateProgramFormInputs>({
        resolver: yupResolver(schema),
        defaultValues
    });

    const createProgramRequest = useMutation(`${new Date()}-create-program`, async (values: CreateProgramFormInputs) => {
        const result = defaultValues ? await updateWorkoutProgramCoreData(values, defaultValues._id) : await createWorkoutProgram(values);
        
        if(!result.isError) {
            toast.success(defaultValues ? "Program has been updated!" : "Program has been created!");
            if (!defaultValues) history.push(AppPathsAdmin.editWorkoutProgram(result.content._id));
        }
    });

    const onSubmit = (values: CreateProgramFormInputs) => {
        const formData = new FormData();

        image && formData.append('image', image);
        formData.append('name', values.name);
        formData.append('description', values.description);
        formData.append('difficulty', values.difficulty.toString());
        formData.append('video', values.video);
        values.previewVideo && formData.append('previewVideo', values.previewVideo);
        values.requiresPremium && formData.append('requiresPremium', values.requiresPremium.toString());
        formData.append('location', values.location.toString());
        formData.append('hidden', (!!values.hidden).toString());

        createProgramRequest.mutate(formData as unknown as CreateProgramFormInputs);
    };

    const handleProgramImageSelect = (e: ChangeEvent<HTMLInputElement>) => {
        if(e.currentTarget.files && e.currentTarget.files[0]) {
            setImage(e.currentTarget.files[0]);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col h-full justify-center items-center'>
            <div className="relative w-full max-w-lg">
                <div className="w-full mb-4">
                    <SmartInput register={register('name')} placeholder='Workout name' label='Workout name' error={errors.name?.message} />
                </div>
                <div className="w-full mb-4">
                    <SmartTextArea register={register('description')} placeholder='Description' label='Description' error={errors.description?.message} />
                </div>
                <div className="w-full mb-4">
                    {/* <SmartImageInput register={register('image')} placeholder='Image' label='Image' error={errors.image?.message} />
                     */}
                     <label>Image</label>
                    <input className="block" type="file" typeof="image" accept="image/gif, image/jpeg, image/png" onChange={handleProgramImageSelect} />
                </div>
                <div className="w-full mb-4">
                    <SmartInput register={register('video')} placeholder='Video' label='Video (Vimeo ID)' error={errors.video?.message} />
                </div>
                <div className="w-full mb-4">
                    <SmartInput register={register('previewVideo')} placeholder='Preview video' label='Preview video (optional)' error={errors.previewVideo?.message} />
                </div>
                <div className="w-full mb-4">
                    <SmartSelect 
                        register={register('difficulty')} 
                        error={errors.difficulty?.message}
                        label='Select a level'
                        defaultOption='Select a level'
                        options={workoutDifficultyOptions} />
                </div>
                <div className="w-full mb-4">
                    <SmartSelect 
                        register={register('location')} 
                        error={errors.location?.message}
                        label='Select a location'
                        defaultOption='Select a location'
                        options={workoutLocationOptions} />
                </div>
                <div className="w-full mb-4">
                    <label>
                        <input type='checkbox' {...register('requiresPremium')} className="mr-2" />
                        Premium?
                    </label>
                </div>
                <div className="w-full mb-4">
                    <label>
                        <input type='checkbox' {...register('hidden')} className="mr-2" />
                        Hidden?
                    </label>
                </div>
            </div>
            <div className='mt-4 w-full max-w-lg'>
                <Button type='submit'>{defaultValues ? "Update" : "Save"}</Button>
            </div>
        </form>
    );
};