import { Menu, Transition } from '@headlessui/react';
import { useProgramEditor } from "../../ProgramEditor/ProgramEditorContext/ProgramEditorContext";
import { Fragment, useEffect, useRef, useState } from 'react';
import { FiLayers, FiMoreVertical, FiPlus, FiTrash2 } from 'react-icons/fi';
import { WorkoutStep } from '../../../api/models/program';

interface Props {
    workoutStep: WorkoutStep;
    onAddSuperset: (workoutStepId: string) => void;
}

export const WorkoutEditorStepMenu: React.FC<Props> = ({
    workoutStep,
    onAddSuperset
}) => {
    const { addSet, removeExercise } = useProgramEditor();

    return (
        <div className="text-right">
        <Menu as="div" className="relative inline-block text-left">
            <div>
            <Menu.Button className="w-12 h-12 rounded-full flex items-center justify-center transition-all hover:bg-gray-50">
                <FiMoreVertical
                    className="w-5 h-5"
                    aria-hidden="true"
                    />
                <span className="sr-only">Options</span>
            </Menu.Button>
            </div>
            <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            >
            <Menu.Items className="absolute z-20 right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1 ">
                    <Menu.Item>
                        {({ active }) => (
                        <button
                            className={`${
                            active ? 'bg-blue-500 text-white' : 'text-gray-900'
                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            onClick={() => addSet(workoutStep.workoutStepId)}
                        >
                            <FiPlus 
                                className="w-5 h-5 mr-2"
                                aria-hidden="true" 
                            />
                            Add set
                        </button>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                        <button
                            className={`${
                            active ? 'bg-blue-500 text-white' : 'text-gray-900'
                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            onClick={() => onAddSuperset(workoutStep.workoutStepId)}
                        >
                            <FiLayers 
                                className="w-5 h-5 mr-2"
                                aria-hidden="true" 
                            />
                            Create Superset
                        </button>
                        )}
                    </Menu.Item>
                </div>
                <div className="px-1 py-1 ">
                    <Menu.Item>
                        {({ active }) => (
                        <button
                            className={`${
                            active ? 'bg-red-200 text-red-600' : 'text-red-600'
                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            onClick={() => removeExercise(workoutStep.workoutStepId)}
                        >
                            <FiTrash2 
                                className="w-5 h-5 mr-2"
                                aria-hidden="true" 
                            />
                            Remove exercise
                        </button>
                        )}
                    </Menu.Item>
                </div>
            </Menu.Items>
            </Transition>
        </Menu>
        </div>
    );
};