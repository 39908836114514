import { yupResolver } from "@hookform/resolvers/yup";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import * as yup from 'yup';
import { string } from "yup";
import { userRegister } from "../../api/authentication";
import { RegisterDto } from "../../api/models/authentication";
import { useAuth } from "../../auth/AuthContext";
import { Button } from "../../components/Button";
import { InputError } from "../../components/Inputs";
import { SmartInput, SmartSelect } from "../../components/SmartForm";
import { SmartRadioGroup } from "../../components/SmartForm/SmartRadioGroup";
import { USER_ROLES, USER_STATUS } from "../../models/user";

export enum SUBSCRIPTION_PLAN {
    MONTHLY = 0,
    YEARLY = 1
}

export interface RegisterFormInputs {
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    subscriptionPlan: SUBSCRIPTION_PLAN;
}

const schema = yup.object({
    firstname: yup.string().required('First name is required'),
    lastname: yup.string().required('Last name is required'),
    email: yup.string().required('Email address is required'),
    password: yup.string().min(8, 'Password must be at least 8 characters long').required('Password is required'),
    subscriptionPlan: yup.number().required('Subscription plan is required')
}).required();

export const RegisterForm: React.FC = () => {
    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory();
    const { handleAutoLogin, isLoggedIn } = useAuth();

    const [error, setError] = useState<string>();
    const [subscriptionPlan, setSubscriptionPlan] = useState("0");

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<RegisterFormInputs>({
        resolver: yupResolver(schema)
    });

    const handleSubscriptionPlanChange = (val: SUBSCRIPTION_PLAN) => {
        setSubscriptionPlan(val.toString());
        setValue('subscriptionPlan', val);
    };

    const registerRequest = useMutation(`${new Date()}-register`, async (registerDto: RegisterDto) => {
        const result = await userRegister(registerDto);
        if(!result.isError) {
            handleAutoLogin(result.content.accessToken, result.content.refreshToken, result.content.user);
            history.push("/");
        } 
    });
    
    const onSubmit = async (values: RegisterFormInputs) => {
        // Block native form submission.
        setError(undefined);
    
        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          return;
        }
    
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);
    
        if(!cardElement) {
            return;
        }

        // Use your card Element with other Stripe.js APIs
        const {error, paymentMethod} = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });
    
        if (error) {
            console.log(error);
          setError(error.message);

        } else if(paymentMethod) {

          const registerDto: RegisterDto = {
            firstname: values.firstname,
            lastname: values.lastname,
            email: values.email,
            password: values.password,
            comparePassword: values.password,
            terms: true,
            marketing: true,
            stripePaymentIntentId: paymentMethod.id,
            subscriptionPlan: parseInt(subscriptionPlan),
            trial: true,
            role: USER_ROLES.PREMIUM,
            status: USER_STATUS.FIRST_TIME
          };

            registerRequest.mutate(registerDto);
        }
    };

    useEffect(() => {
        setValue('subscriptionPlan', SUBSCRIPTION_PLAN.MONTHLY);
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col h-full'>
            <div className="mb-4">
                <SmartInput register={register('firstname')} placeholder='First name' label='First name' error={errors.firstname?.message} />
            </div>
            <div className="mb-4">
                <SmartInput register={register('lastname')} placeholder='Last name' label='Last name' error={errors.lastname?.message} /> 
            </div>
            <div className="mb-4">
                <SmartInput register={register('email')} placeholder='Email address' label='Email address' error={errors.email?.message} />
            </div>
            <div className="mb-4">
                <SmartInput register={register('password')} type='password' placeholder='Password' label='Password' error={errors.password?.message} />
            </div>
            <div className="w-full">
                <SmartRadioGroup 
                    onChange={(val: any) => handleSubscriptionPlanChange(val as SUBSCRIPTION_PLAN)}
                    value={subscriptionPlan}
                    options={[
                        {
                            value: "0",
                            label: "Monthly (£14.99)",
                            description: "Monthly"
                        },
                        {
                            value: "1",
                            label: "Yearly (£119.99)",
                            description: "Yearly"
                        }
                    ]} />
            </div>
            <label className="block text-sm font-medium text-black mb-1">Payment details</label>
            <div className="border border-gray-200 p-4 rounded-md mb-4">
                <CardElement options={{ hidePostalCode: true }} />
            </div>
            <InputError error={error} />
            <div className="mt-4 w-full">
                <Button type="submit">Sign Up</Button>
            </div>
        </form>
    );
};