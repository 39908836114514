import { Link } from "react-router-dom";
import { IApiProgram } from "../../api/models/program";
import { AppPathsPublic } from "../../routes/AppRoutes";
import { config } from "../../config";
import { Image } from "../../components/Image";

interface ProgramListProps {
    programs: IApiProgram[];
    isLoading: boolean;
}

export const ProgramList: React.FC<ProgramListProps> = ({
    programs,
    isLoading
}) => {
    const { apiUri } = config;

    return (
        <div className="grid grid-cols-2 gap-6 2xl:gap-12 mt-6 mb-12 sm:grid-cols-3 lg:grid-cols-4 sm:mt-12">
            {isLoading &&
                <>
                    <div className="bg-gray-200 w-full portrait"></div>
                    <div className="bg-gray-200 w-full portrait"></div>
                    <div className="bg-gray-200 w-full portrait"></div>
                    <div className="bg-gray-200 w-full portrait"></div>
                </>
            }
            {!isLoading && programs.length === 0 &&
                <div className="col-span-full flex h-64 items-center justify-center font-bold text-lg">
                    No programs found.
                </div>
            }
            {!isLoading && programs.map(program =>
                <Link to={AppPathsPublic.programDetail(program._id)} className="w-full">
                    <div className="bg-gray-200 w-full portrait relative transition-opacity hover:opacity-80">
                        <Image className=" object-cover w-full h-full" src={`${apiUri}/api/file/${program.image}`} />
                    </div>
                    <div className="mt-4">
                        <div className="font-bold text-sm uppercase tracking-wide mb-2 sm:text-base">{program.name}</div>
                        {/* <p className="text-sm sm:text-base">{program.description}</p> */}
                    </div>
                </Link>       
            )}
        </div>
    );
};