import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { removeLiveWorkout } from "../../../api/admin/liveWorkout";
import { getLiveWorkout } from "../../../api/liveWorkout";
import { Button } from "../../../components/Button";
import { CreateLiveWorkoutForm } from "../../../forms/admin/CreateLiveWorkoutForm";
import { PublicLayout } from "../../../layouts/PublicLayout";

interface EditLiveWorkoutPageParams {
    id: string;
}

export const EditLiveWorkoutPage: React.FC = () => {
    const { id } = useParams<EditLiveWorkoutPageParams>();

    const { isLoading, error, data: liveWorkout, refetch } = useQuery(`${id}-live-workout`, async () => { 
        const result = await getLiveWorkout(id);
        if(!result.isError) {
            return result.content;
        }   
    });

    const removeLiveWorkoutRequest = useMutation(`${new Date()}-remove-live-workout`, async () => {
        const result = await removeLiveWorkout(id);
        
        if(!result.isError) {
            toast.success("Live workout has been removed!");
        }
    });

    return (
        <PublicLayout>
            <div className="px-6 py-24 bg-gray-50">
                <div className="w-full max-w-screen-xl m-auto">
                    <h1 className="text-4xl uppercase font-black font-display mb-4 tracking-widest text-center">Edit Live Workout</h1>
                    <p className="text-lg text-center max-w-3xl m-auto">Edit existing live workout</p>
                </div>
            </div>
            <div className="py-8 w-full max-w-lg mx-auto">
                {!isLoading && liveWorkout &&
                    <CreateLiveWorkoutForm defaultValues={liveWorkout} />
                }
                <div className="mt-4">
                    <Button variant="danger" onClick={() => removeLiveWorkoutRequest.mutate()}>Remove</Button>
                </div>
            </div>
        </PublicLayout>
    );
};