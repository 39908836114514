import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Button } from "../Button";
import { Modal } from "../Modal/Modal";

interface TimerModalProps {
    duration?: number;
    onClose(): void;
}

export const TimerModal: React.FC<TimerModalProps> = ({
    duration,
    onClose
}) => {
    const [timerStartDate, setTimerStartDate] = useState<Date | undefined>(new Date());
    const [seconds, setSeconds] = useState<number>();

    const resetTimer = () => {
        setTimerStartDate(new Date());
    };

    const handleClose = () => {
        setTimerStartDate(undefined);
        setSeconds(undefined);
        onClose();
    };

    useEffect(() => {
        if(timerStartDate && duration) {
            const timeInterval = setInterval(() => {
                const diff = DateTime.fromJSDate(new Date()).diff(DateTime.fromJSDate(timerStartDate)).toFormat("ss");
                const secondsToDisplay = duration - parseInt(diff);

                setSeconds(secondsToDisplay);
                if(secondsToDisplay === 0) {
                    setTimerStartDate(undefined);
                }
            }, 500);
    
            return () => clearInterval(timeInterval);
        }
    }, [timerStartDate]);

    useEffect(() => {
        setTimerStartDate(new Date());
    }, [duration]);

    return (
        <>
            <Modal isOpen={Boolean(duration)} closeModal={handleClose}>
                <div className="relative flex flex-col items-center justify-center">
                    <div className="w-64 h-64 rounded-full border-8 border-green-500 flex flex-col items-center justify-center">
                        <div className="text-8xl text-green-500">{seconds ?? '--'}</div>
                    </div>
                    <div className="mt-4 w-full">
                        <Button size="small" variant="gray" onClick={resetTimer}>
                            Reset
                        </Button>
                    </div>
                    <div className="mt-4 w-full">
                        <Button size="small" variant="danger" onClick={handleClose}>
                            Close
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};