import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { FormEventHandler, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { paymentMethodUpdate } from "../../api/authentication";
import { UpdatePaymentMethodDto } from "../../api/models/user";
import { Button } from "../../components/Button";
import { InputError } from "../../components/Inputs";
import { config } from "../../config";

interface IPaymentMethodProps {
    customTitle?: string,
    newUser?: boolean,
    history?: any
}

const UpdatePaymentMethodForm = ({ customTitle, newUser = false, history }: IPaymentMethodProps) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState<string>();

    const updatePaymentMethodRequest = useMutation(`${new Date()}-payment-method`, async (paymentMethodDto: UpdatePaymentMethodDto) => {
        const result = await paymentMethodUpdate(paymentMethodDto);
        if(!result.isError) {
            toast.success("Payment method has been successfully updated.");
            elements?.getElement("card")?.clear();
        } 
    });

    const handleFormSubmission = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
          }
      
          // Get a reference to a mounted CardElement. Elements knows how
          // to find your CardElement because there can only ever be one of
          // each type of element.
          const cardElement = elements.getElement(CardElement);
      
          if(!cardElement) {
              return;
          }
  
          // Use your card Element with other Stripe.js APIs
          const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
          });

        // const { error, token: paymentMethod } = await stripe.createToken(cardElement); 
      
          if (error) {
            console.log(error);
            setError(error.message);
  
          } else if(paymentMethod) {
  
            const paymentMethodDto: UpdatePaymentMethodDto = {
              stripePaymentIntentId: paymentMethod.id,
            };
  
            updatePaymentMethodRequest.mutate(paymentMethodDto);
          }
    };

    return (
        <form onSubmit={handleFormSubmission}>
            <label className="block text-sm font-medium text-black mb-1">Payment details</label>
            <div className="border border-gray-200 p-4 rounded-md mb-4">
                <CardElement options={{ hidePostalCode: true }} />
            </div>
            <InputError error={error} />
            <Button type="submit">Update Payment Method</Button>
        </form>
    );
};

export default UpdatePaymentMethodForm;