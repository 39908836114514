import clsx from "clsx";
import { BiX } from "react-icons/bi";
import { NavBarItemsLoggedIn, NavBarItemsLoggedOut } from ".";
import { useAuth } from "../../auth/AuthContext";

interface NavBarMobileMenuProps {
    open: boolean;
    closeMenu(): void;
}

export const NavBarMobileMenu: React.FC<NavBarMobileMenuProps> = ({
    open,
    closeMenu
}) => {
    const { isLoggedIn } = useAuth();
    
    return (
        <div className={clsx("fixed top-0 z-50 w-full max-w-xs h-screen transition-all bg-black text-white p-6 lg:hidden", open ? "right-0" : "-right-full")}>
            <div className="flex items-center pb-4 border-b border-white mb-4">
                <div className="font-display font-black text-2xl uppercase">Stronger <span className="font-normal">You</span></div>
                <button className="w-8 h-8 rounded-m bg-gray-50 flex items-center justify-center ml-auto lg:hidden" onClick={closeMenu}>
                    <BiX className="text-gray-600 w-6 h-6" />
                </button>
            </div>
            {!isLoggedIn && <NavBarItemsLoggedOut />}
            {isLoggedIn && <NavBarItemsLoggedIn />}
        </div>
    );
};