import { AddRecipeForm } from "../../../forms/admin/AddRecipeForm/AddRecipeForm";
import { PublicLayout } from "../../../layouts/PublicLayout";

export const CreateRecipePage: React.FC = () => {
    return (
        <PublicLayout>
            <div className="px-6 py-24 bg-gray-50">
                <div className="w-full max-w-screen-xl m-auto">
                    <h1 className="text-4xl uppercase font-black font-display mb-4 tracking-widest text-center">Create Recipe</h1>
                    <p className="text-lg text-center max-w-3xl m-auto">Create a new recipe</p>
                </div>
            </div>
            <div className="py-8">
                <AddRecipeForm />
            </div>
        </PublicLayout>
    );
};