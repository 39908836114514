import clsx from "clsx";
import { HTMLProps } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { InputError, ListInput } from "../Inputs";

interface SmartListInputProps {
  label?: string;
  error?: string;
  value: string[];
  onChange: (val: string[]) => void;
}

export function SmartListInput({
  label,
  error,
  value,
  onChange,
  ...props
}: Partial<SmartListInputProps & HTMLProps<HTMLInputElement>>): JSX.Element {
    const id = `${props.name}-${label?.replace(' ', '')}`;

    return (
        <div className="w-full">
            {label &&
                <label htmlFor={id} className="block text-sm font-medium text-black mb-1">{label}</label>
            }
            {onChange &&
                <ListInput value={value} onChange={onChange}  />
            }
            {error && <InputError error={error} />}
        </div>
    );
}