import { yupResolver } from "@hookform/resolvers/yup";
import { ChangeEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from 'yup';
import { createLiveWorkout, editLiveWorkout } from "../../../api/admin/liveWorkout";
import { Button } from "../../../components/Button";
import { InputError } from "../../../components/Inputs";
import { SmartInput, SmartSelect, SmartTextArea } from "../../../components/SmartForm";
import { SmartImageInput } from "../../../components/SmartForm/SmartImageInput";
import { WORKOUT_DIFFICULTY, WORKOUT_LOCATION } from "../../../models/liveWorkout";
import { AppPathsPublic } from "../../../routes/AppRoutes";

export interface CreateLiveWorkoutFormInputs {
    _id?: string;
    name: string;
    description: string;
    duration: number;
    muscleCategory: number;
    difficulty: WORKOUT_DIFFICULTY;
    image: string;
    video: string;
    previewVideo?: string;
    requiresPremium: boolean;
    location: WORKOUT_LOCATION;
}

interface CreateLiveWorkoutFormProps {
    defaultValues?: CreateLiveWorkoutFormInputs;
}

const schema = yup.object({
    name: yup.string().required('Name is required'),
    description: yup.string().required('Description is required'),
    duration: yup.number().required('Duration is required'),
    muscleCategory: yup.number().required('Muscle category is required'),
    difficulty: yup.number().required('Difficulty is required'),
    image: yup.string().notRequired(),
    video: yup.string().required('Video is required'),
    previewVideo: yup.string().notRequired(),
    requiresPremium: yup.boolean().notRequired(),
    location: yup.number().required('Location is required')
}).required();

const muscleCategoryOptions = [
    {
        value: 0,
        label: "Abdominals"
    },
    {
        value: 1,
        label: "Hamstrings"
    },
    {
        value: 2,
        label: "Calves"
    },
    {
        value: 3,
        label: "Shoulders"
    },
    {
        value: 4,
        label: "Adductors"
    },
    {
        value: 5,
        label: "Glutes"
    },
    {
        value: 6,
        label: "Quadriceps"
    },
    {
        value: 7,
        label: "Biceps"
    },
    {
        value: 8,
        label: "Forearms"
    },
    {
        value: 9,
        label: "Abductors"
    },
    {
        value: 10,
        label: "Triceps"
    },
    {
        value: 11,
        label: "Chest"
    },
    {
        value: 12,
        label: "Lower Back"
    },
    {
        value: 13,
        label: "Traps"
    },
    {
        value: 14,
        label: "Middle Back"
    },
    {
        value: 15,
        label: "Lats"
    },
    {
        value: 16,
        label: "Neck"
    },
    {
        value: 17,
        label: "Full Body"
    }
];

const workoutDifficultyOptions = [
    {
        value: 0,
        label: 'Beginner'
    },
    {
        value: 1,
        label: 'Intermediate'
    },
    {
        value: 2,
        label: 'Advanced'
    },
];

const workoutLocationOptions = [
    {
        value: 0,
        label: 'Gym'
    },
    {
        value: 1,
        label: 'Home'
    },
];

export const CreateLiveWorkoutForm: React.FC<CreateLiveWorkoutFormProps> = ({
    defaultValues
}) => {
    const [image, setImage] = useState<File>();

    const { register, handleSubmit, formState: { errors } } = useForm<CreateLiveWorkoutFormInputs>({
        resolver: yupResolver(schema),
        defaultValues
    });

    const createLiveWorkoutRequest = useMutation(`${new Date()}-create-live-workout`, async (values: CreateLiveWorkoutFormInputs) => {
        const result = defaultValues ? await editLiveWorkout(values, defaultValues._id!) : await createLiveWorkout(values);
        
        if(!result.isError) {
            toast.success(defaultValues ? "Live workout has been updated!" : "Live workout has been created!");
        }
    });

    const onSubmit = (values: CreateLiveWorkoutFormInputs) => {
        const formData = new FormData();

        image && formData.append('image', image);
        formData.append('name', values.name);
        formData.append('description', values.description);
        formData.append('duration', values.duration.toString());
        formData.append('muscleCategory', values.muscleCategory.toString());
        formData.append('difficulty', values.difficulty.toString());
        formData.append('video', values.video);
        values.previewVideo && formData.append('previewVideo', values.previewVideo);
        values.requiresPremium && formData.append('requiresPremium', values.requiresPremium.toString());
        formData.append('location', values.location.toString());
        defaultValues && defaultValues._id && formData.append('_id', defaultValues._id);

        createLiveWorkoutRequest.mutate(formData as unknown as CreateLiveWorkoutFormInputs);
    };

    const handleProgramImageSelect = (e: ChangeEvent<HTMLInputElement>) => {
        if(e.currentTarget.files && e.currentTarget.files[0]) {
            setImage(e.currentTarget.files[0]);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col h-full justify-center items-center'>
            <div className="relative w-full max-w-lg">
                <div className="w-full mb-4">
                    <SmartInput register={register('name')} placeholder='Workout name' label='Workout name' error={errors.name?.message} />
                </div>
                <div className="w-full mb-4">
                    <SmartTextArea register={register('description')} placeholder='Description' label='Description' error={errors.description?.message} />
                </div>
                <div className="w-full mb-4">
                    <SmartInput register={register('duration')} type='number' placeholder='Duration' label='Duration' error={errors.duration?.message} />
                </div>
                <div className="w-full mb-4">
                    {/* <SmartImageInput register={register('image')} placeholder='Image' label='Image' error={errors.image?.message} /> */}
                    <label>Image</label>
                    <input className="block" type="file" typeof="image" accept="image/gif, image/jpeg, image/png" onChange={handleProgramImageSelect} />
                </div>
                <div className="w-full mb-4">
                    <SmartInput register={register('video')} placeholder='Video' label='Video (Vimeo ID)' error={errors.video?.message} />
                </div>
                <div className="w-full mb-4">
                    <SmartInput register={register('previewVideo')} placeholder='Preview video' label='Preview video (optional)' error={errors.previewVideo?.message} />
                </div>
                <div className="w-full mb-4">
                    <SmartSelect 
                        register={register('muscleCategory')} 
                        error={errors.muscleCategory?.message}
                        label='Select a muscle'
                        defaultOption='Select a muscle'
                        options={muscleCategoryOptions} />
                </div>
                <div className="w-full mb-4">
                    <SmartSelect 
                        register={register('difficulty')} 
                        error={errors.difficulty?.message}
                        label='Select a level'
                        defaultOption='Select a level'
                        options={workoutDifficultyOptions} />
                </div>
                <div className="w-full mb-4">
                    <SmartSelect 
                        register={register('location')} 
                        error={errors.location?.message}
                        label='Select a location'
                        defaultOption='Select a location'
                        options={workoutLocationOptions} />
                </div>
                <div className="w-full mb-4">
                    <label>
                        <input type='checkbox' {...register('requiresPremium')} className="mr-2" />
                        Premium?
                    </label>
                </div>
            </div>
            <div className='mt-4 w-full max-w-lg'>
                <Button type='submit'>Save</Button>
            </div>
        </form>
    );
};