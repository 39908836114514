import clsx from "clsx";
import { ButtonHTMLAttributes} from "react";
import { Link } from "react-router-dom";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: 'primary' | 'info' | 'transparent' | 'gray' | 'danger';
    link?: string
    size?: 'small'
}

export const Button: React.FC<ButtonProps> = ({
    variant = 'primary',
    size,
    link,
    children,
    ...props
}) => {
    const buttonVariantStyles = clsx(
        "inline-block w-full text-center uppercase font-medium rounded-md text-lg transition-all hover:opacity-80",
        variant === 'primary' && "bg-black text-white hover:bg-gray-800",
        variant === 'transparent' && "bg-transparent text-base",
        variant === 'gray' && "bg-gray-100 text-base hover:bg-gray-200",
        variant === 'info' && "text-blue-900 bg-blue-100 hover:bg-blue-200",
        variant === 'danger' && "text-red-600 bg-red-200 hover:bg-red-300",
        size === 'small' ? "py-2 px-6" : "px-6 py-4"
    );

    if(link) return (
        <Link to={link} className={buttonVariantStyles}>
            {children}
        </Link>
    );

    return (
        <button 
            className={buttonVariantStyles}
            {...props}
        >
        {children}
        </button>
    );
};