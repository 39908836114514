import { createContext, useContext } from "react";
import { IUser } from "../models/user";
import { AuthResult } from "./authClient";

export interface IAuthContext {
    logIn: (email: string, password: string) => Promise<AuthResult>;
    logOut: () => Promise<void>;
    handleAutoLogin: (accessToken: string, refreshToken: string, user: IUser) => void;
    setUser: (user: IUser) => void;
    isLoggedIn: boolean,
    isLoading: boolean,
    isAdmin: boolean,
    isPremium: boolean,
    user?: IUser;
}

const placeholderFunc = () => { throw new Error('Not implemented.'); };

export const AuthContext = createContext<IAuthContext>({
    logIn: placeholderFunc,
    logOut: placeholderFunc,
    handleAutoLogin: placeholderFunc,
    setUser: placeholderFunc,
    isLoggedIn: false,
    isLoading: true,
    isPremium: false,
    isAdmin: false
});

export const useAuth: () => IAuthContext = () => useContext(AuthContext);