import { IApiNutrition, IApiNutritionDiaryEntry } from "../../api/models/nutrition";

interface NutritionTargetCardProps {
    nutritionTarget: IApiNutrition;
}

export const calculateCurrentMacroUsageFromDiaryEntries = (entries: IApiNutritionDiaryEntry[]) => {
    let protein = 0;
    let carbohydrates = 0;
    let fats = 0;
    let kcals = 0;

    entries.forEach(entry => {
        if(entry.recipe) {
            kcals = kcals + entry.recipe.kcals * entry.servings;
            protein = protein + entry.recipe.protein * entry.servings;
            carbohydrates = carbohydrates + entry.recipe.carbohydrates * entry.servings;
            fats = fats + entry.recipe.fats * entry.servings;
        }
    });

    return { currentKcals: kcals, currentProtein: protein, currentCarbohydrates: carbohydrates, currentFats: fats };
};

export const NutritionTargetCard: React.FC<NutritionTargetCardProps> = ({
    nutritionTarget
}) => {
    const { kcal, protein, carbohydrates, fats } = nutritionTarget.nutrition;
    const { currentKcals, currentProtein, currentCarbohydrates, currentFats } = calculateCurrentMacroUsageFromDiaryEntries(nutritionTarget.nutritionDiaryEntries);
    const kcalPercentage = (currentKcals / kcal) * 100;
    const proteinPercentage = (currentProtein / protein) * 100;
    const carbohydratesPercentage = (currentCarbohydrates / carbohydrates) * 100;
    const fatsPercentage = (currentFats / fats) * 100;

    return (
        <div className="w-full">
            <div className="flex space-between items-center">
                <div className="flex-1 font-bold">Kcals</div>
                <div><b>{currentKcals}</b>/{kcal} kcal</div>
            </div>
            <div className="relative w-full h-4 bg-gray-200 rounded-lg mb-4">
                <div className="h-4 bg-yellow-500 rounded-lg transition-all" style={{width: `${kcalPercentage > 100 ? 100 : kcalPercentage}%`}}></div>
            </div>
            <div className="flex space-between items-center">
                <div className="flex-1 font-bold">Protein</div>
                <div><b>{currentProtein}</b>/{protein}g</div>
            </div>
            <div className="relative w-full h-4 bg-gray-200 rounded-lg mb-4">
                <div className="h-4 bg-pink-500 rounded-lg transition-all" style={{width: `${proteinPercentage > 100 ? 100 : proteinPercentage}%`}}></div>
            </div>
            <div className="flex space-between items-center">
                <div className="flex-1 font-bold">Carbohydrates</div>
                <div><b>{currentCarbohydrates}</b>/{carbohydrates}g</div>
            </div>
            <div className="relative w-full h-4 bg-gray-200 rounded-lg mb-4">
                <div className="h-4 bg-blue-500 rounded-lg transition-all" style={{width: `${carbohydratesPercentage > 100 ? 100 : carbohydratesPercentage}%`}}></div>
            </div>
            <div className="flex space-between items-center">
                <div className="flex-1 font-bold">Fats</div>
                <div><b>{currentFats}</b>/{fats}g</div>
            </div>
            <div className="relative w-full h-4 bg-gray-200 rounded-lg mb-4">
                <div className="h-4 bg-green-500 rounded-lg transition-all" style={{width: `${fatsPercentage > 100 ? 100 : fatsPercentage}%`}}></div>
            </div>
        </div>
    );
};