import clsx from "clsx";
import { useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { FilterChangeTarget } from "./LiveWorkouts";

interface LiveWorkoutsFilterProps {
    handleFilterChange(target: FilterChangeTarget): void;
    selectedDifficulty: number[];
    selectedMuscleCategory: number[];
    selectedLocation: number[];
}

export const LiveWorkoutsFilter: React.FC<LiveWorkoutsFilterProps> = ({
    handleFilterChange,
    selectedDifficulty,
    selectedMuscleCategory,
    selectedLocation
}) => {
    const [filterOpen, setFilterOpen] = useState(false);

    return (
        <>
        <button className="relative flex items-center justify-center px-4 py-2 mb-4 w-full bg-gray-100 rounded-md sm:hidden" onClick={() => setFilterOpen(!filterOpen)}>
            <div className="font-bold uppercase">Filter</div>
            <div className="absolute top-0 right-0 h-full w-12 flex items-center justify-center">
                {filterOpen ? <BiChevronUp /> : <BiChevronDown />}
            </div>
        </button>
        <div className={clsx("flex-none w-full sm:border-r border-gray-100 sm:h-screen sm:pr-16 sm:sticky sm:inline-block sm:top-24 sm:w-auto", filterOpen ? "block border-b-2 mb-4 border-black" : "hidden sm:inline-block")}>
                <div className="mb-6">
                    <div className="font-display font-bold uppercase tracking-widest mb-2">Difficulty</div>
                    <div>
                        <label className="inline-flex items-center">
                            <input 
                                type="checkbox" 
                                className="form-checkbox" 
                                name='difficulty' 
                                value={0} 
                                checked={selectedDifficulty.includes(0)}
                                onChange={(e) => handleFilterChange({ property: 'difficulty', value: parseInt(e.currentTarget.value) as number})} />
                            <span className="ml-2">Beginner</span>
                        </label>
                    </div>
                    <div>
                        <label className="inline-flex items-center">
                            <input 
                                type="checkbox" 
                                className="form-checkbox" 
                                name='difficulty' 
                                value={1} 
                                checked={selectedDifficulty.includes(1)}
                                onChange={(e) => handleFilterChange({ property: 'difficulty', value: parseInt(e.currentTarget.value)})} />
                            <span className="ml-2">Intermediate</span>
                        </label>
                    </div>
                    <div>
                        <label className="inline-flex items-center">
                            <input 
                                type="checkbox" 
                                className="form-checkbox" 
                                name='difficulty' 
                                value={2} 
                                checked={selectedDifficulty.includes(2)}
                                onChange={(e) => handleFilterChange({ property: 'difficulty', value: parseInt(e.currentTarget.value)})} />
                            <span className="ml-2">Advanced</span>
                        </label>
                    </div>
                </div>
                {/* <div className="mb-6">
                    <div className="font-display font-bold uppercase tracking-widest mb-2">Muscle Group</div>
                    <div>
                        <label className="inline-flex items-center">
                            <input 
                                type="checkbox" 
                                className="form-checkbox" 
                                name='muscleGroup' 
                                value={0} 
                                checked={selectedMuscleCategory.includes(0)}
                                onChange={(e) => handleFilterChange({ property: 'muscleCategory', value: parseInt(e.currentTarget.value)})} />
                            <span className="ml-2">Abs</span>
                        </label>
                    </div>
                    <div>
                        <label className="inline-flex items-center">
                            <input type="checkbox" className="form-checkbox" />
                            <span className="ml-2">Arms</span>
                        </label>
                    </div>
                    <div>
                        <label className="inline-flex items-center">
                            <input type="checkbox" className="form-checkbox" />
                            <span className="ml-2">Upper Body</span>
                        </label>
                    </div>
                    <div>
                        <label className="inline-flex items-center">
                            <input type="checkbox" className="form-checkbox" />
                            <span className="ml-2">Lower Body</span>
                        </label>
                    </div>
                    <div>
                        <label className="inline-flex items-center">
                            <input type="checkbox" className="form-checkbox" />
                            <span className="ml-2">Glutes</span>
                        </label>
                    </div>
                </div>
                <div className="mb-6">
                    <div className="font-display font-bold uppercase tracking-widest mb-2">Type</div>
                    <div>
                        <label className="inline-flex items-center">
                            <input type="checkbox" className="form-checkbox" />
                            <span className="ml-2">Home</span>
                        </label>
                    </div>
                    <div>
                        <label className="inline-flex items-center">
                            <input type="checkbox" className="form-checkbox" />
                            <span className="ml-2">Gym</span>
                        </label>
                    </div>
                </div> */}
            </div>
        </>
    );
};