import { buildUrl, httpClient } from "./httpClient";
import { HttpClientResponse } from "./httpClient/httpClient";
import { ApiStandardResponse, mapHttpClientResponse, mapHttpListResponse } from "./httpClient/mapHttpClientResponse";
import { IApiCreateProgramLogDto, IApiGetProgramLogResponse, IApiPersonalBest, IApiProgramLog, IApiProgramLogPopulated } from "./models/ProgramLog";

export const createProgramLog = async (createProgramLogDto: IApiCreateProgramLogDto): Promise<HttpClientResponse<IApiProgramLog>> => {
    const url = buildUrl('/api/log');

    const response = await httpClient.postRequest<ApiStandardResponse<IApiProgramLog>>(url, createProgramLogDto);

    return mapHttpClientResponse(response, m => m);
};

export const getPersonalBestByProgramLogId = async (id: string): Promise<HttpClientResponse<IApiPersonalBest[]>> => {
    const url = buildUrl(`/api/personalbest/${id}`);

    const response = await httpClient.getRequest<ApiStandardResponse<IApiPersonalBest[]>>(url);

    return mapHttpListResponse(response, m => m);
};

export const getProgramLog = async (id: string): Promise<HttpClientResponse<IApiGetProgramLogResponse>> => {
    const url = buildUrl(`/api/log/item/${id}`);

    const response = await httpClient.getRequest<ApiStandardResponse<IApiGetProgramLogResponse>>(url);

    return mapHttpClientResponse(response, m => m);
};

export const getProgramLogForClient = async (clientId: string, logId: string): Promise<HttpClientResponse<IApiGetProgramLogResponse>> => {
    const url = buildUrl(`/api/coach/log/item/${clientId}/${logId}`);

    const response = await httpClient.getRequest<ApiStandardResponse<IApiGetProgramLogResponse>>(url);

    return mapHttpClientResponse(response, m => m);
};

export const getLogsByStartAndEndDate = async (start: string, end: string): Promise<HttpClientResponse<IApiProgramLogPopulated[]>> => {
    const url = buildUrl(`/api/log/items/${start}/${end}`);

    const response = await httpClient.getRequest<ApiStandardResponse<IApiProgramLogPopulated[]>>(url);

    return mapHttpListResponse(response, m => m);
};

export const getLogsByStartAndEndDateAndClientId = async (start: string, end: string, clientId: string): Promise<HttpClientResponse<IApiProgramLogPopulated[]>> => {
    const url = buildUrl(`/api/coach/log/items/${start}/${end}/${clientId}`);

    const response = await httpClient.getRequest<ApiStandardResponse<IApiProgramLogPopulated[]>>(url);

    return mapHttpListResponse(response, m => m);
};