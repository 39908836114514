import { createContext, useContext } from "react";
import { ICoreProgramLogItem, IProgramLogNote, MEASUREMENT_UNIT } from "../api/models/program";
import { IntervalObject } from "../components/IntervalTimerModal/IntervalTimerModal";
import { setLogKey, WorkoutNav } from "./WorkoutProvider";

export interface IWorkoutContext {
    initializeWorkout: (programDayId: string, steps: number, activeStep: number) => void;
    cancelWorkout: () => void;
    navigateWorkout: (direction: WorkoutNav) => void;
    logSet: (workoutSetId: string, key: setLogKey, val: number, exercise: string, units: MEASUREMENT_UNIT) => void;
    getSet: (workoutSetId: string) => ICoreProgramLogItem | undefined,
    saveProgramLog: (program: string) => void,
    saveNotes: (note: string) => void,
    initializeTimer: (duration: number) => void;
    initializeIntervalTimer: (intervalObj: IntervalObject) => void;
    workoutStepNotes: IProgramLogNote[],
    timer?: string;
}

const placeholderFunc = () => { throw new Error('Not implemented.'); };

export const WorkoutContext = createContext<IWorkoutContext>({
    initializeWorkout: placeholderFunc,
    cancelWorkout: placeholderFunc,
    navigateWorkout: placeholderFunc,
    logSet: placeholderFunc,
    getSet: placeholderFunc,
    saveProgramLog: placeholderFunc,
    saveNotes: placeholderFunc,
    initializeTimer: placeholderFunc,
    initializeIntervalTimer: placeholderFunc,
    workoutStepNotes: []
});

export const useWorkout: () => IWorkoutContext = () => useContext(WorkoutContext);