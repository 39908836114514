import { Link } from "react-router-dom";
import { useAuth } from "../../auth/AuthContext";
import { AppPathsGlobal, AppPathsLoggedIn, AppPathsPublic } from "../../routes/AppRoutes";

export const NavBarItemsLoggedIn: React.FC = () => {
  const { user, logOut } = useAuth();

    return (
        <ul className="list-none lg:flex lg:ml-auto">
          <li>
            <Link to={AppPathsLoggedIn.dashboard} className="block py-3 uppercase tracking-widest lg:px-4 lg:py-0 lg:inline-block">Dashboard</Link>
          </li>
          <li>
            <Link to={AppPathsLoggedIn.liveWorkouts} className="block py-3 uppercase tracking-widest lg:px-4 lg:py-0 lg:inline-block">Live</Link>
          </li>
          <li>
            <Link to={AppPathsLoggedIn.programs} className="block py-3 uppercase tracking-widest lg:px-4 lg:py-0 lg:inline-block">Programs</Link>
          </li>
          <li>
            <Link to={AppPathsLoggedIn.recipes} className="block py-3 uppercase tracking-widest lg:px-4 lg:py-0 lg:inline-block">Recipes</Link>
          </li>
          <li>
            <button onClick={logOut} className="block py-3 uppercase tracking-widest lg:px-4 lg:py-0 lg:inline-block">Log Out</button>
          </li>
        </ul>
    );
};