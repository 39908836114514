import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { Button } from "../../components/Button";
import { InputError } from "../../components/Inputs";
import { SmartInput } from "../../components/SmartForm";

export interface ForgottenPasswordConfirmationFormInputs {
    password: string;
    confirmPassword: string;
}

interface ForgottenPasswordConfirmationFormProps {
    error?: string,
    onSubmit: (values: ForgottenPasswordConfirmationFormInputs) => void
}

const schema = yup.object({
    password: yup.string().min(8, 'Password  must be at least 8 characters long').required('Please enter a password'),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
}).required();

export const ForgottenPasswordConfirmationForm: React.FC<ForgottenPasswordConfirmationFormProps> = ({
    error,
    onSubmit
}) => {
    const { register, handleSubmit, formState: { errors } } = useForm<ForgottenPasswordConfirmationFormInputs>({
        resolver: yupResolver(schema)
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col h-full justify-center items-center'>
            <div className="relative w-full max-w-lg">
                <SmartInput register={register('password')} type='password' placeholder='Password' label='Password' error={errors.password?.message} />
                <br />
                <SmartInput register={register('confirmPassword')} type='password' placeholder='Confirm password' label='Confirm password' error={errors.confirmPassword?.message} />
            </div>
            <div className='mt-4 w-full max-w-lg'>
                <Button type='submit'>Reset Password</Button>
                {error && <div className="mt-4">
                    <InputError error={error} />
                </div>}
            </div>
        </form>
    );
};