import { Disclosure, Transition } from "@headlessui/react";
import clsx from "clsx";
import { BiChevronDown, BiChevronUp, BiLock } from "react-icons/bi";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { assignProgramToClient, getWorkoutProgram } from "../../api/program";
import { useAuth } from "../../auth/AuthContext";
import { Button } from "../../components/Button";
import { PublicLayout } from "../../layouts/PublicLayout";
import { workoutDifficultyMap, workoutLocationMap } from "../../models/liveWorkout";
import { AppPathsAdmin, AppPathsLoggedIn, AppPathsPublic } from "../../routes/AppRoutes";
import { config } from "../../config";
import { Image } from "../../components/Image";
import { IApiGetProgram, IApiProgramDay } from "../../api/models/program";
import { useState } from "react";
import { Modal } from "../../components/Modal/Modal";
import { DayPicker } from "react-day-picker";
import { toast } from "react-toastify";

interface ProgramDetailPageParams {
    id: string;
}

const populateRestDays = (program: IApiGetProgram): IApiGetProgram => {
    const dayNos = [0, 1, 2, 3, 4, 5, 6];

    const createRestDayObj = (dayNo: number, programWeekId: string): IApiProgramDay => {
        return {
            programId: program.program._id,
            name: 'Rest day',
            dayNo,
            workoutSteps: [],
            programWeekId
        };
    }

    for(const programWeek of program.programWeeks) {
        for(const dayNo of dayNos) {
            const programDayExists = Boolean(program.programDays.filter(programDay => programDay.programWeekId === programWeek._id).find(programDay => programDay.dayNo === dayNo));

            if(!programDayExists) {
                const restDay = createRestDayObj(dayNo, programWeek._id);
                program.programDays.push(restDay);
            }
        }
    }
    program.programDays.sort((a, b) => (a.dayNo > b.dayNo) ? 1 : -1);

    return program;
};

export const ProgramDetailPage: React.FC = () => {
    const { id } = useParams<ProgramDetailPageParams>();
    const { user, isPremium, isAdmin } = useAuth();
    const history = useHistory();
    const { apiUri } = config;
    const [startProgramModal, setStartProgramModal] = useState(false);
    const [startDate, setStartDate] = useState<Date>();
    const [programStartError, setProgramStartError] = useState<string>();
    
    const { isLoading, error, data: program, refetch } = useQuery(`${id}-program`, async () => { 
        const result = await getWorkoutProgram(id);
        if(!result.isError) {
            return populateRestDays(result.content);
        }   
    });

    const onSubmit = async () => {
        if(startDate) {
            setProgramStartError(undefined);
            const result = await assignProgramToClient(id, startDate?.toISOString());
    
            if(result.isError) {
                // toastNotification({ variant: 'error', title: 'Error assigning workout program', message: result.message });
                // alert("Error starting this program. Please try again.");
                setProgramStartError("This start date clashes with an active or upcoming program in your schedule. Please select another date.");
            }
    
            if(!result.isError) {
                // toastNotification({ variant: 'info', title: 'Workout program assigned', message: `Workout program has been assigned` });
                toast.success("Workout program has been assigned");
                history.push(AppPathsLoggedIn.dashboard);
            }
        }
    };

    const handleCloseStartProgramModal = () => {
        setStartProgramModal(false); 
        setProgramStartError(undefined);
    };

    return (
        <>
        <PublicLayout>
            <div className="px-6 py-8">
                <div className="w-full max-w-screen-xl m-auto">
                    {program && !isLoading &&
                        <>
                        <div className="grid gap-4 sm:grid-cols-2 sm:gap-12 mb-16">
                            <div className="flex justify-center">
                                <div className="relative max-w-xs">
                                    <Image src={`${apiUri}/api/file/${program.program.image}`} className=" object-cover w-full h-full" />
                                    <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-20">
                                        {(!user || !isPremium) &&
                                            <BiLock className="w-12 h-12 text-white" />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="uppercase tracking-widest text-sm mb-2">Program</div>
                                <h1 className="font-black text-xl sm:text-3xl tracking-wide font-display uppercase mb-6">{program.program.name}</h1>
                                <div className="grid grid-cols-2 gap-4 max-w-xs relative wrap mb-8">
                                        <div className="flex-none uppercase tracking-widest font-display font-bold text-sm">Duration</div>
                                        <div className="uppercase tracking-widest text-sm ml-4">{program.programWeeks.length} Weeks</div>
                                        <div className="flex-none uppercase tracking-widest font-display font-bold text-sm">Difficulty</div>
                                        <div className="uppercase tracking-widest text-sm ml-4">{workoutDifficultyMap[program.program.difficulty]}</div>
                                        <div className="flex-none uppercase tracking-widest font-display font-bold text-sm">Location</div>
                                        <div className="uppercase tracking-widest text-sm ml-4">{workoutLocationMap[program.program.location]}</div>
                                </div>
                                <div className="lg:w-2/3">
                                    {!user &&
                                        <Button link={AppPathsPublic.signUp}>Start your free trial</Button>
                                    }
                                    {user && !isPremium &&
                                        <Button link={`${AppPathsLoggedIn.profile}#mySubscription`}>Upgrade to Premium</Button>
                                    }
                                    {user && isPremium &&
                                        <Button onClick={() => setStartProgramModal(true)}>Start Program</Button>
                                    }
                                    {isAdmin &&
                                        <div className="mt-4">
                                            <Button variant="gray" link={AppPathsAdmin.editWorkoutProgram(program.program._id)}>Edit</Button>
                                        </div>
                                    }
                                </div>
                                <hr className="my-8" />
                                <h3 className="font-bold uppercase tracking-wider mb-4">Description</h3>
                                <p>{program.program.description}</p>
                            </div>
                        </div>
                        <hr className="my-8" />
                        <div className="pt-6">
                            <h2 className="font-black text-lg sm:text-2xl tracking-wide font-display uppercase mb-6">Program Schedule</h2>
                            <div className="w-full mx-auto bg-white rounded-2xl">
                                {program.programWeeks.map((programWeek, i) =>
                                    <Disclosure>
                                    {({ open }) => (
                                        <div className={clsx("border rounded-md mb-4")}>
                                        <Disclosure.Button disabled={(!user || !isPremium)} className={clsx(
                                            "flex items-center justify-between w-full text-lg px-4 font-semibold text-left py-6 rounded-md transition-all hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75",
                                             open && "bg-gray-50",
                                             (!user || !isPremium) && "cursor-not-allowed")}>
                                            <span>Week {i + 1}</span>
                                            {(!user || !isPremium) &&
                                                <BiLock className="ml-auto" />
                                            }
                                            {user && isPremium &&
                                                <BiChevronDown
                                                    className={`${
                                                        open ? 'transform rotate-180' : ''
                                                    }`}
                                                    />
                                            }
                                        </Disclosure.Button>
                                        <Transition
                                            enter="transition duration-100 ease-out"
                                            enterFrom="transform scale-95 opacity-0"
                                            enterTo="transform scale-100 opacity-100"
                                            leave="transition duration-75 ease-out"
                                            leaveFrom="transform scale-100 opacity-100"
                                            leaveTo="transform scale-95 opacity-0"
                                        >
                                            {user && isPremium &&
                                                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                                    {program.programDays.filter(programDay => programDay.programWeekId === programWeek._id).map(day =>
                                                        <div className="flex items-center py-2 border-b border-gray-200">
                                                            <div className=" font-bold mr-1">Day {day.dayNo + 1}: </div>{day.name}
                                                            <div className="ml-auto">{day.workoutSteps.length > 0 ? `${day.workoutSteps.length} Exercises` : ''}</div>
                                                        </div>
                                                    )}
                                                </Disclosure.Panel>
                                            }
                                        </Transition>
                                        </div>
                                    )}
                                    </Disclosure>
                                )}
                            </div>
                        </div>
                        </>
                    }
                </div>
            </div>
        </PublicLayout>
        <Modal isOpen={startProgramModal} closeModal={handleCloseStartProgramModal} title="Select a start date">
                <DayPicker mode="single" onSelect={(day) => day ? setStartDate(day) : null} selected={startDate} />
                {programStartError && <div className="font-medium text-red-500">{programStartError}</div>}
                <Button disabled={!startDate} onClick={onSubmit}>Submit</Button>
            </Modal>
        </>
    );
};