import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { userDetailUpdate } from '../../api/authentication';
import { useAuth } from '../../auth/AuthContext';
import { Button } from '../../components/Button';
import { InputError } from '../../components/Inputs';
import { SmartInput } from '../../components/SmartForm';

export interface UpdateUserDetailFormInputs {
    firstname: string;
    lastname: string;
    email: string;
}

const schema = yup.object({
    firstname: yup.string().required('First name is required'),
    lastname: yup.string().required('Last name is required'),
    email: yup.string().required('Email address is required'),
}).required();

const UpdateUserDetailForm: React.FC = () => {
    const [error, setError] = useState<string>();
    const { user, setUser } = useAuth();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<UpdateUserDetailFormInputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            firstname: user?.firstname,
            lastname: user?.lastname,
            email: user?.email
        }
    });

    const updateRequest = useMutation(`${new Date()}-update`, async (updateDto: UpdateUserDetailFormInputs) => {
        const result = await userDetailUpdate(updateDto);
        if(!result.isError) {
            if(user) {
                sessionStorage.setItem('user', JSON.stringify({...user, ...updateDto}));
                setUser({...user, ...updateDto});
                console.log("here 4");
            }
            
            toast.success("Profile has been updated!");
        } 
    });

    const onSubmit = async (values: UpdateUserDetailFormInputs) => {
        // Block native form submission.
        setError(undefined);
    
        const updateDto = {
            firstname: values.firstname,
            lastname: values.lastname,
            email: values.email,
          };

          updateRequest.mutate(updateDto);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col h-full'>
            <div className="mb-4">
                <SmartInput register={register('firstname')} placeholder='First name' label='First name' error={errors.firstname?.message} />
            </div>
            <div className="mb-4">
                <SmartInput register={register('lastname')} placeholder='Last name' label='Last name' error={errors.lastname?.message} /> 
            </div>
            <div className="mb-4">
                <SmartInput register={register('email')} placeholder='Email address' label='Email address' error={errors.email?.message} />
            </div>
            <InputError error={error} />
            <div className="mt-4 w-full">
                <Button type="submit">Update</Button>
            </div>
        </form>
    );
};

export default UpdateUserDetailForm;