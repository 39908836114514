import { useMutation, useQuery } from "react-query";
import { cancelSubscription, getSubscriptionInfo } from "../../api/authentication";
import { useAuth } from "../../auth/AuthContext";
import { Button } from "../Button";
import { CgCrown } from "react-icons/cg";
import { Modal } from "../Modal/Modal";
import { useState } from "react";
import { USER_ROLES } from "../../models/user";
import UpgradeSubscriptionForm from "../../forms/UpgradeSubscriptionForm";
import { toast } from "react-toastify";

const MySubscriptionCard: React.FC = () => {
    const { isPremium, setUser, user } = useAuth();
    const [cancelModal, setCancelModal] = useState(false);

    const cancelSubscriptionRequest = useMutation(`cancel-subscription`, async () => {
        const result = await cancelSubscription();
        
        if(!result.isError) {
            if(user) {
                setUser({...user, role: USER_ROLES.FREE});
            }
            toast.success("Subscription has been cancelled.");
        }
    });

    const handleCancelSubscriptionConfirmation = () => {
        cancelSubscriptionRequest.mutate();
        setCancelModal(false);
    };

    return (
        <>
            {isPremium &&
                <div className="flex items-center gap-4 mb-4">
                    <CgCrown className=" text-yellow-400 w-8 h-8" />
                    <div className="font-bold">Premium Subscription</div>
                </div>
            }
            {!isPremium &&
                <>
                <div className="flex items-center gap-4 mb-4">
                    <div className="font-bold">Free Subscription (Limited Access)</div>
                </div>
                <div className="border-t border-gray-200 mt-4 pt-4">
                    <div className="flex items-center gap-4 font-bold mb-4">
                        <CgCrown className=" text-yellow-400 w-8 h-8" />
                        Upgrade to Premium
                    </div>
                    <UpgradeSubscriptionForm />
                </div>
                </>
            }
            {isPremium &&
                <Button variant="danger" onClick={() => setCancelModal(true)}>Cancel Subscription</Button>
            }
            <Modal title="Cancel Subscription" isOpen={cancelModal} closeModal={() => setCancelModal(false)}>
                <p className="mb-8">Are you sure you want to cancel your subscription?</p>
                <div className="grid grid-cols-2 gap-4">
                    <Button type="button" variant="transparent" onClick={() => setCancelModal(false)}>No</Button>
                    <Button onClick={handleCancelSubscriptionConfirmation}>Yes</Button>
                </div>
            </Modal>
        </>
    );
};

export default MySubscriptionCard;