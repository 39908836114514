export enum USER_ROLES {
    PREMIUM,
    FREE,
    ADMIN
}

export enum USER_STATUS {
    UNCONFIRMED = 0,
    FIRST_TIME = 1,
    ACTIVE = 2,
    BLOCKED = -1,
    PENDING_CANCEL = 3,
    CANCELLED = 4
}

export interface IUser {
    _id: string;
    role: USER_ROLES;
    status: USER_STATUS;
    firstname: string;
    lastname: string;
    email: string;
    lastActive: string;
    avatar: string;
    stripeid: string;
    subid: string;
    terms: boolean;
    marketing: boolean;
    trial: boolean;
}