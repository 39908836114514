import { FiAward, FiCheckCircle } from "react-icons/fi";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { getPersonalBestByProgramLogId } from "../../api/programLog";
import { Button } from "../../components/Button";
import { useExercise } from "../../exercise/ExerciseContext";
import { PublicLayout } from "../../layouts/PublicLayout";
import { AppPathsLoggedIn } from "../../routes/AppRoutes";

interface Params {
    id: string;
}

export const WorkoutCompletePage: React.FC = () => {
    const { id } = useParams<Params>();
    const { getExerciseById } = useExercise();

    const { isLoading, error, data: personalBests, refetch } = useQuery(`workout-complete-${id}`, async () => { 
        const result = await getPersonalBestByProgramLogId(id);
        if(!result.isError) {
            return result.content;
        }   
    });

    return (
        <PublicLayout>
            <div className="px-6 pt-8 pb-6 max-w-7xl mx-auto">
                {/* {isLoading && <FullScreenLoader />} */}
                {!isLoading && personalBests &&
                    <>
                    <div className="bg-white rounded-md flex flex-col items-center justify-center px-4 py-12 mb-4">
                        <FiCheckCircle className="w-20 h-20 text-green-500 mb-6" />
                        <h1 className="font-medium text-2xl">Workout complete!</h1>
                    </div>
                    {personalBests.length > 0 &&
                            <>
                                <h2 className="font-medium text-xl mt-6 mb-4 text-center">{personalBests.length} new personal best{personalBests.length > 1 ? 's' : ''}</h2>
                                {personalBests.map(personalBest => {
                                    const exercise = getExerciseById(personalBest.exercise);

                                    return (
                                        <div className="bg-white rounded-md flex items-center justify-center p-4 mb-4 border border-gray-200 w-full" key={personalBest._id}>
                                            <FiAward className="w-6 h-6 text-yellow-400" />
                                            <div className="font-medium ml-2">{exercise?.name} - {personalBest.reps} reps {personalBest.weight}kg</div>
                                            <div className="font-medium text-green-500 ml-2">+{personalBest.difference}kg</div>
                                        </div>
                                    );
                                })}
                            </>
                        }
                    <Button link={AppPathsLoggedIn.dashboard}>Return to dashboard</Button>
                    </>
                }
            </div>
        </PublicLayout>
    );
};