import { useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { CreateNutritionTargetDto } from "../../../api/models/nutrition";
import { calculateNutritionTarget, getNutritionTarget } from "../../../api/nutrition";
import { NutritionDiary } from "../../../components/NutritionDiary";
import { NutritionTargetCard } from "../../../components/NutritionTargetCard";
import { NutritionTargetForm } from "../../../forms/NutritionTargetForm/NutritionTargetForm";

export const DashboardNutrition: React.FC = () => {
    const [date, setDate] = useState(new Date());

    const { isLoading, error, data: nutritionTarget, refetch } = useQuery(`nutrition-target`, async () => {
        const result = await getNutritionTarget(date);

        if(!result.isError) {
            return result.content;
        }   
    });

    const handleCalculateNutritionTargetSubmit = async (data: CreateNutritionTargetDto) => {
        const result = await calculateNutritionTarget(data);

        if(!result.isError) {
            toast.success("Nutrition calculated");
            refetch();
        }
    };

    return (
        <>
        <div className="border border-gray-200 flex mb-6">
            {isLoading && `Loading...`}
            {!isLoading && !nutritionTarget?.nutrition &&
                <div className="w-full p-6">
                    <NutritionTargetForm onSubmit={handleCalculateNutritionTargetSubmit} />
                </div>
            }
            {!isLoading && nutritionTarget?.nutrition &&
                <div className="flex-1 p-6">
                    <div className="flex pb-2 mb-4 border-b border-gray-200">
                        <h2 className="text-xl uppercase font-black font-display mb-2 tracking-wide">My Nutrition</h2>
                        <div className="text-lg font-bold ml-auto">Today</div>
                    </div>
                    <NutritionTargetCard nutritionTarget={nutritionTarget} />
                    <hr className="my-4" />
                    <NutritionDiary date={date} entries={nutritionTarget.nutritionDiaryEntries} refetchDiary={refetch} />
                </div>
            }
        </div>
        </>
    );
};