export const DashboardPreviousProgram: React.FC = () => {
    return (
        <div className="">
            <h2 className="text-xl uppercase font-black font-display mb-4 tracking-wide">Previous</h2>
            <div className="border border-gray-200 p-6 flex items-center justify-center h-80 mb-6">
                <div className="flex flex-col items-center">
                    <div className="font-semibold">No previous programs found</div>
                </div>
            </div>
        </div>
    );
};