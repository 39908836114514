import { useState } from "react";
import { useQuery } from "react-query";
import { ProgramList } from ".";
import { getWorkoutPrograms } from "../../api/program";
import { PublicLayout } from "../../layouts/PublicLayout";

enum SORT_BY {
    LATEST,
    OLDEST
}

export const ProgramsPage: React.FC = () => {
    const [sortBy, setSortBy] = useState(SORT_BY.LATEST);

    const { isLoading, error, data: programs = [], refetch } = useQuery(`get-live-workouts`, async () => { 
        const result = await getWorkoutPrograms();
        if(!result.isError) {
            return result.content;
        }   
    });

    const sortedPrograms = programs.sort((a, b) => {
        if(sortBy === SORT_BY.LATEST) {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        }

        if(sortBy === SORT_BY.OLDEST) {
            return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        }

        return 1;
    });

    return (
        <PublicLayout>
            <div className="px-6 py-24 bg-gray-50">
                <div className="w-full max-w-screen-xl m-auto">
                    <h1 className="text-4xl uppercase font-black font-display mb-4 tracking-widest text-center">Programs</h1>
                    <p className="text-lg text-center max-w-3xl m-auto">Challenge yourself with a structured workout plan.</p>
                </div>
            </div>
            <div className="px-6 sm:px-12 py-8">
                <div className="w-full sm:flex">
                    <div className="flex-none w-40 border-r"></div>
                    <div className="flex-1 sm:px-8">
                        <div className="flex items-center">
                            <div className="font-display font-bold uppercase tracking-widest mb-2 opacity-60 text-xs sm:text-base">Showing {programs.length} results</div>
                            <div className="-mt-2 flex items-center ml-auto">
                                <span className="font-display uppercase text-xs opacity-60 sm:text-sm">Sort by</span>
                                <select 
                                    className="font-display uppercase text-xs opacity-90 sm:text-sm" 
                                    style={{marginTop: '-2px'}}
                                    value={sortBy}
                                    onChange={(e) => setSortBy(parseInt(e.currentTarget.value))}>
                                    <option value={SORT_BY.LATEST}>Latest</option>
                                    <option value={SORT_BY.OLDEST}>Oldest</option>
                                </select>
                            </div>
                        </div>
                        <ProgramList  programs={sortedPrograms} isLoading={isLoading} />
                    </div>
                </div>
            </div>
        </PublicLayout>
    );
};