import { DateTime } from "luxon";
import { ChangeEvent } from "react";
import { Link, NavLink, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useAuth } from "../../auth/AuthContext";
import { PublicLayout } from "../../layouts/PublicLayout";
import { AppPathsLoggedIn } from "../../routes/AppRoutes";
import { Logbook } from "./Logbook";
import { LogbookItem } from "./LogbookItem";
import { DashboardNutrition } from "./Nutrition/DashboardNutrition";
import { DashboardOverview } from "./Overview/DashboardOverview";
import { DashboardProgram } from "./Program/DashboardProgram";

export const dashboardPaths = (path: string) => ({
    overview: path,
    program: `${path}/program`,
    nutrition: `${path}/nutrition`,
    logbook: (month: string, year: string) => `${path}/logbook/all/${month}/${year}`,
    logbookItem: (id: string) => `${path}/logbook/item/${id}`
});

export const DashboardPage: React.FC = () => {
    const { user } = useAuth();
    const { path } = useRouteMatch();
    const DashboardPath = dashboardPaths(path);
    const history = useHistory();
    const location = useLocation();

    const dt = DateTime.now();
    const month = dt.toFormat("M");
    const year = dt.toFormat("yyyy");

    const handleSelectNavChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const page = e.currentTarget.value;

        if(page === "dashboard") {
            history.push(DashboardPath.overview);
        }

        if(page === "program") {
            history.push(DashboardPath.program);
        }

        if(page === "nutrition") {
            history.push(DashboardPath.nutrition);
        }

        if(page === "logbook") {
            history.push(DashboardPath.logbook(month, year));
        }
    };

    return (
        <PublicLayout>
            <div className="px-6 py-24 bg-gray-50">
                <div className="w-full max-w-screen-xl m-auto">
                    <h1 className="text-4xl uppercase font-black font-display mb-4 tracking-widest text-center">Dashboard</h1>
                    <p className="text-lg text-center max-w-3xl m-auto">{user?.firstname} {user?.lastname}</p>
                </div>
            </div>
            <div className="bg-white max-w-5xl mx-auto p-4">
                <select className="sm:hidden w-full bg-gray-800 text-white p-4 mb-4 text-center" onChange={handleSelectNavChange}>
                    <option value="dashboard">Dashboard</option>
                    <option value="program">My Program</option>
                    <option value="nutrition">Nutrition</option>
                    <option value="logbook">Logbook</option>
                </select>
                <ul className="hidden sm:flex gap-8 justify-center mt-2 mb-8">
                    <li className="">
                        <NavLink exact to={DashboardPath.overview} className="pb-1" activeClassName="border-b border-gray-400">Overview</NavLink>
                    </li>
                    <li className="">
                        <NavLink exact to={DashboardPath.program} className="pb-1" activeClassName="border-b border-gray-400">My Program</NavLink>
                    </li>
                    <li className="">
                        <NavLink exact to={DashboardPath.nutrition} className="pb-1" activeClassName="border-b border-gray-400">Nutrition</NavLink>
                    </li>
                    <li className="">
                        <NavLink exact to={DashboardPath.logbook(month, year)} className="pb-1" activeClassName="border-b border-gray-400">Logbook</NavLink>
                    </li>
                </ul>
                <Switch>
                    <Route path={DashboardPath.overview} exact>
                        <DashboardOverview />
                    </Route>
                    <Route path={DashboardPath.program} exact>
                        <DashboardProgram />
                    </Route>
                    <Route path={DashboardPath.nutrition} exact>
                        <DashboardNutrition />
                    </Route>
                    <Route path={DashboardPath.logbook(':month', ':year')} exact>
                        <Logbook />
                    </Route>
                    <Route path={DashboardPath.logbookItem(':id')} exact>
                        <LogbookItem />
                    </Route>
                </Switch>
            </div>
        </PublicLayout>
    );
};