import clsx from "clsx";
import { DateTime } from "luxon";
import { FiCheck } from "react-icons/fi";
import { Link } from "react-router-dom";
import { IApiClientProgram, IApiProgramDay, IApiProgramWeek } from "../../../api/models/program";
import { Button } from "../../../components/Button";
import { AppPathsLoggedIn } from "../../../routes/AppRoutes";

enum ItemType {
    REST_DAY,
    WORKOUT_DAY
}

interface DashboardActiveProgramItemContainerProps {
    type: ItemType;
    dayId: string;
    programLog?: string;
}

interface DashboardActiveProgramItemProps {
    day: IApiProgramDay;
    week: IApiProgramWeek;
    clientProgram: IApiClientProgram;
    programLog?: string;
}

const DashboardActiveProgramItemContainer: React.FC<DashboardActiveProgramItemContainerProps> = ({ type, dayId, programLog, children }) => {

    if(type === ItemType.REST_DAY) {
        return (
            <div className="bg-white cursor-not-allowed w-full flex items-center p-4 mb-2 transition-all bg-opacity-40 shadow-none border border-gray-200">
                {children}
            </div>
        );
    }

    return (
        <>
        {programLog ?
            <Link to={AppPathsLoggedIn.logbookItem(programLog)} className="bg-white w-full flex items-center p-4 mb-2 transition-all shadow-soft border border-gray-200">
                {children}
            </Link>
        :
            // <Link to={AppPathsClient.workout(dayId, '0')} className="bg-white w-full flex items-center p-4 mb-2 transition-all shadow-soft group border-2 border-transparent hover:border-gray-200">
            <Link to={AppPathsLoggedIn.workout(dayId, '0')} className="bg-white w-full flex items-center p-4 mb-2 transition-all shadow-soft group border border-gray-200 hover:bg-gray-50">
                {children}
            </Link>
        }
        </>
    );
};

export const DashboardActiveProgramItem: React.FC<DashboardActiveProgramItemProps> = ({
    day,
    week,
    clientProgram,
    programLog
}) => {
    const date = new Date(clientProgram.startDate);
    date.setDate(date.getDate() + ((week.order * 7) + day.dayNo));
    const dt = DateTime.fromJSDate(date);
    const isToday = dt.hasSame(DateTime.local(), "day");

    const type = day.workoutSteps.length === 0 ? ItemType.REST_DAY : ItemType.WORKOUT_DAY;
    
    return (
        <DashboardActiveProgramItemContainer type={type} dayId={day._id ?? '0'} programLog={programLog}>
            <div className={clsx("w-12 h-12 bg-gray-50 rounded-md flex flex-col items-center justify-center transition-all group-hover:bg-gray-500", isToday && "bg-gray-900")}>
                <span className={clsx("text-xxs font-bold uppercase -mb-1 group-hover:text-white", isToday ? "text-white": "text-gray-400")}>{dt.toFormat('MMM')}</span>
                <span className={clsx("text-xl font-medium group-hover:text-white", isToday ? "text-white" : "text-gray-500")}>{dt.toFormat('dd')}</span>
            </div>
            <div className="ml-4">
                <div className="font-black text-gray-400 transition-all">{day.name ?? `Workout ${day.dayNo + 1}`}</div>
                {day.workoutSteps.length > 0 &&<div className="font-medium text-gray-400 transition-all">{day.workoutSteps.length} exercises</div>}
            </div>
            <div className="ml-auto flex items-center">
                {programLog &&
                    <>
                        <div className="mr-4">
                            {/* <Button link={AppPathsClient.logbookItem(programLog)} variant="gray" size="small">View log</Button> */}
                            {/* <Button link="/" variant="gray" size="small">View log</Button> */}
                        </div>
                        <div className="w-10 h-10 rounded-full bg-green-400 flex items-center justify-center">
                            <FiCheck className="text-white w-6 h-6" />
                        </div>
                    </>
                }
            </div>
        </DashboardActiveProgramItemContainer>
    );
};