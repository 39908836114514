import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../auth/AuthContext";
import { SignInForm } from "../../forms/SignInForm";
import { PublicLayout } from "../../layouts/PublicLayout";

export const LoginPage: React.FC = () => {
    const { logIn } = useAuth();
    const history = useHistory();
    const [error, setError] = useState<string>();

    const handleLogin = async (email: string, password: string) => {
        setError(undefined);
        const authResult = await logIn(email, password);

        if(authResult.isError) {
            if(authResult.status === 401) {
                setError('Email address or password is incorrect.')
            } else {
                setError('An unknown error occurred. Please try again.');
            }
        }

        if(!authResult.isError) {
            history.push('/');
        }
    };

    return (
        <PublicLayout>
            <div className="px-6 py-12">
                <div className="w-full max-w-lg m-auto">
                <h2 className="text-4xl uppercase font-black font-display mb-4 tracking-widest text-center">Login</h2>
                <SignInForm 
                    error={error}
                    onSubmit={(authDetails) => handleLogin(authDetails.email, authDetails.password)} />
                </div>
            </div>
        </PublicLayout>
    );
};