import { FiFileText } from "react-icons/fi";
import { MdFitnessCenter } from "react-icons/md";
import { LogbookEntrySet } from ".";
import { IApiGetProgramLogResponse } from "../../api/models/ProgramLog";
import { useExercise } from "../../exercise/ExerciseContext";
import { WorkoutLoggerSet } from "../WorkoutLogger";

interface LogbookEntryProps {
    entry: IApiGetProgramLogResponse;
}

export const LogbookEntry: React.FC<LogbookEntryProps> = ({
    entry
}) => {
    const { getExerciseById } = useExercise();
    return (
        <>
        {entry.log.programDay.workoutSteps.map(step => {
            const exercise = getExerciseById(step.exercise);
            const note = entry.log.notes.find(note => note.step === step.order);

            return (
                <div className="p-4 rounded-md group bg-white mb-4">
                    <div className="flex items-center">
                        <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white rounded-md bg-blue-50 sm:h-12 sm:w-12 group-hover:bg-gray-100 transition-colors">
                            <MdFitnessCenter className="w-6 h-6 text-blue-500" />
                        </div>
                        <div className="ml-4 flex-1">
                            <p className="font-medium text-gray-900">{exercise?.name}</p>
                        </div>
                    </div>
                    {step.sets.map(workoutSet =>
                        <LogbookEntrySet 
                            workoutSet={workoutSet}
                            workoutStepId={step.workoutStepId}
                            exercise={step.exercise}
                            key={workoutSet.workoutSetId}
                            logInfo={entry.logItems.find(item => item.workoutSetId === workoutSet.workoutSetId)} />  
                    )}
                    {note && 
                        <div className="bg-gray-100 rounded-md p-2">
                            <div className="flex items-center text-sm font-medium mb-2">
                                <FiFileText className="mr-2" />
                                Notes
                            </div>
                            <div className="text-sm">
                                {note.notes}
                            </div>
                        </div>
                    }
                </div>
            );
        })}
        </>
    );
};