import { UpdateUserDetailFormInputs } from "../forms/UpdateUserDetailForm/UpdateUserDetailForm";
import { buildUrl, httpClient } from "./httpClient";
import { HttpClientResponse } from "./httpClient/httpClient";
import { ApiStandardResponse, mapHttpClientResponse, mapHttpListResponse } from "./httpClient/mapHttpClientResponse";
import { IApiAuthLoginResponse, IApiSubscriptionResponse, RegisterDto, UpgradeSubscriptionDto } from "./models/authentication";
import { UpdatePaymentMethodDto } from "./models/user";

export const userRegister = async (registerDto: RegisterDto): Promise<HttpClientResponse<IApiAuthLoginResponse>> => {
    const url = buildUrl('/api/user/register');

    const response = await httpClient.postRequest<ApiStandardResponse<IApiAuthLoginResponse>>(url, registerDto);

    return mapHttpClientResponse(response, m => m);
};

export const authLogin = async (email: string, password: string): Promise<HttpClientResponse<IApiAuthLoginResponse>> => {
    const url = buildUrl('/api/sessions');

    const response = await httpClient.postRequest<ApiStandardResponse<IApiAuthLoginResponse>>(url, { email, password });
    
    return mapHttpClientResponse(response, m => m);
};

export const authLogout = async (): Promise<HttpClientResponse<void>> => {
    const url = buildUrl('/api/sessions');

    const response = await httpClient.deleteRequest<ApiStandardResponse<void>>(url);

    return mapHttpClientResponse(response, m => m);
};

export const forgottenPassword = async (email: string): Promise<HttpClientResponse<void>> => {
    const url = buildUrl('/api/user/forgotten-password');

    const response = await httpClient.postRequest<ApiStandardResponse<void>>(url, { email });
    
    return mapHttpClientResponse(response, m => m);
};

export const resetForgottenPassword = async (resetCode: string, password: string): Promise<HttpClientResponse<void>> => {
    const url = buildUrl(`/api/user/forgotten-password/${resetCode}`);

    const response = await httpClient.postRequest<ApiStandardResponse<void>>(url, { password });

    return mapHttpClientResponse(response, m => m);
};

export const userDetailUpdate = async (registerDto: UpdateUserDetailFormInputs): Promise<HttpClientResponse<void>> => {
    const url = buildUrl('/api/user');

    const response = await httpClient.putRequest<ApiStandardResponse<void>>(url, registerDto);

    return mapHttpClientResponse(response, m => m);
};

export const paymentMethodUpdate = async (paymentMethodDto: UpdatePaymentMethodDto): Promise<HttpClientResponse<void>> => {
    const url = buildUrl('/api/user/paymentmethod');

    const response = await httpClient.postRequest<ApiStandardResponse<void>>(url, paymentMethodDto);

    return mapHttpClientResponse(response, m => m);
};

export const getSubscriptionInfo = async (): Promise<HttpClientResponse<IApiSubscriptionResponse>> => {
    const url = buildUrl('/api/user/subscription');

    const response = await httpClient.getRequest<ApiStandardResponse<IApiSubscriptionResponse>>(url);
    
    return mapHttpClientResponse(response, m => m);
};

export const cancelSubscription = async (): Promise<HttpClientResponse<void>> => {
    const url = buildUrl('/api/user/subscription/cancel');

    const response = await httpClient.getRequest<ApiStandardResponse<void>>(url);
    
    return mapHttpClientResponse(response, m => m);
};

export const upgradeSubscription = async (upgradeDto: UpgradeSubscriptionDto): Promise<HttpClientResponse<void>> => {
    const url = buildUrl('/api/user/subscription/upgrade');

    const response = await httpClient.postRequest<ApiStandardResponse<void>>(url, upgradeDto);

    return mapHttpClientResponse(response, m => m);
};