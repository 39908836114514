import clsx from "clsx";
import { useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { Range } from "react-range";
import { NutritionDiaryCategory } from "../../api/models/nutrition";
import { FilterChangeTarget } from "./Recipes";

interface RecipesFilterProps {
    handleFilterChange(target: FilterChangeTarget): void;
    selectedSelection: string;
    selectedCategory: NutritionDiaryCategory[];
    selectedCalorieRange: number[];
    selectedLocation: number[];
}

export const RecipesFilter: React.FC<RecipesFilterProps> = ({
    handleFilterChange,
    selectedSelection,
    selectedCategory,
    selectedCalorieRange,
    selectedLocation
}) => {
    const [range, setRange] = useState(selectedCalorieRange);
    const [filterOpen, setFilterOpen] = useState(false);

    return (
        <>
        <button className="relative flex items-center justify-center px-4 py-2 mb-4 w-full bg-gray-100 rounded-md sm:hidden" onClick={() => setFilterOpen(!filterOpen)}>
            <div className="font-bold uppercase">Filter</div>
            <div className="absolute top-0 right-0 h-full w-12 flex items-center justify-center">
                {filterOpen ? <BiChevronUp /> : <BiChevronDown />}
            </div>
        </button>
        <div className={clsx("flex-none w-full sm:border-r border-gray-100 sm:h-screen sm:pr-8 sm:sticky sm:inline-block sm:top-24 sm:w-64", filterOpen ? "block border-b-2 mb-4 border-black" : "hidden sm:inline-block")}>
                <div className="mb-6">
                    <select className="h-12 w-full border border-gray-200 px-2 rounded-md" value={selectedSelection} onChange={(e) => handleFilterChange({ property: "selection", value: e.currentTarget.value})}>
                        <option value="recipes">Recipes</option>
                        <option value="meals">My Meals</option>
                        {/* <option value="favourites">My Favourites</option> */}
                    </select>
                </div>
                <div className="mb-6">
                    <div className="font-display font-bold uppercase tracking-widest mb-2">Category</div>
                    <div>
                        <label className="inline-flex items-center">
                            <input 
                                type="checkbox" 
                                className="form-checkbox" 
                                name='difficulty' 
                                value={1} 
                                checked={selectedCategory.includes(1)}
                                onChange={(e) => handleFilterChange({ property: 'category', value: parseInt(e.currentTarget.value) as number})} />
                            <span className="ml-2">Breakfast</span>
                        </label>
                    </div>
                    <div>
                        <label className="inline-flex items-center">
                            <input 
                                type="checkbox" 
                                className="form-checkbox" 
                                name='category' 
                                value={2} 
                                checked={selectedCategory.includes(2)}
                                onChange={(e) => handleFilterChange({ property: 'category', value: parseInt(e.currentTarget.value)})} />
                            <span className="ml-2">Lunch</span>
                        </label>
                    </div>
                    <div>
                        <label className="inline-flex items-center">
                            <input 
                                type="checkbox" 
                                className="form-checkbox" 
                                name='category' 
                                value={3} 
                                checked={selectedCategory.includes(3)}
                                onChange={(e) => handleFilterChange({ property: 'category', value: parseInt(e.currentTarget.value)})} />
                            <span className="ml-2">Dinner</span>
                        </label>
                    </div>
                    <div>
                        <label className="inline-flex items-center">
                            <input 
                                type="checkbox" 
                                className="form-checkbox" 
                                name='category' 
                                value={4} 
                                checked={selectedCategory.includes(4)}
                                onChange={(e) => handleFilterChange({ property: 'category', value: parseInt(e.currentTarget.value)})} />
                            <span className="ml-2">Snacks</span>
                        </label>
                    </div>
                </div>
                <div className="mb-6">
                    <div className="font-display font-bold uppercase tracking-widest mb-2">Calories</div>
                    <div className="mt-4">
                        <Range
                            step={10}
                            min={0}
                            max={2500}
                            values={range}
                            onChange={setRange}
                            onFinalChange={(values) => handleFilterChange({ property: 'calorieRange', value: values })}
                            renderTrack={({ props, children }) => (
                                <div className="w-full h-1 bg-gray-300" {...props}>{children}</div>
                            )}
                            renderThumb={({ props }) => (
                                <div className="w-4 h-4 rounded-md bg-black" {...props}></div>
                            )}
                        />
                        <div className="w-full flex justify-between mt-4">
                                <div className="flex-none w-12 border-2 rounded-md text-center text-sm">{range[0]}</div>
                                <div className="flex-none w-12 border-2 rounded-md text-center text-sm">{range[1]}</div>
                        </div>
                    </div>
                </div>
                {/* <div className="mb-6">
                    <div className="font-display font-bold uppercase tracking-widest mb-2">Type</div>
                    <div>
                        <label className="inline-flex items-center">
                            <input type="checkbox" className="form-checkbox" />
                            <span className="ml-2">Home</span>
                        </label>
                    </div>
                    <div>
                        <label className="inline-flex items-center">
                            <input type="checkbox" className="form-checkbox" />
                            <span className="ml-2">Gym</span>
                        </label>
                    </div>
                </div> */}
            </div>
        </>
    );
};