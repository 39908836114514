import { IApiNutritionDiaryEntry } from "../../api/models/nutrition";
import { Image } from "../Image";
import { config } from "../../config";
import { useState } from "react";
import { Modal } from "../Modal/Modal";
import { Button } from "../Button";
import { FiTrash2, FiEye } from "react-icons/fi";
import { Link } from "react-router-dom";
import { AppPathsLoggedIn } from "../../routes/AppRoutes";

interface NutritionDiaryItemProps {
    entry: IApiNutritionDiaryEntry;
    handleRemoveNutritionDiaryEntry(id: string): void;
}

export const NutritionDiaryItem: React.FC<NutritionDiaryItemProps> = ({
    entry,
    handleRemoveNutritionDiaryEntry
}) => {
    const { apiUri } = config;
    const [confirmRemoval, setConfirmRemoval] = useState(false);

    const handleRemovalConfirmation = () => {
        handleRemoveNutritionDiaryEntry(entry._id);
        setConfirmRemoval(false);
    };

    return (
        <>
        <div className="border border-gray-200 p-4 mb-4 flex flex-col sm:flex-row gap-4">
            <div className="hidden w-16 h-16 sm:block">
                <Image src={`${apiUri}/api/file/${entry.recipe?.image}`} className="w-full h-full object-center object-cover" />
            </div>
            <div className="flex-1">
                <h3 className="text-base font-medium font-display mb-4">{entry.recipe?.name} <span className="text-sm font-normal">({entry.servings} serving{entry.servings > 1 ? 's' : ''})</span></h3>
                <div className="flex gap-4">
                    <div className="flex items-center gap-2">
                        <div className="w-4 h-4 rounded-full bg-yellow-500"></div>
                        <div className=" text-sm">{(entry.recipe?.kcals ?? 0) * entry.servings} kcal</div>
                    </div>
                    <div className="flex items-center gap-2">
                        <div className="w-4 h-4 rounded-full bg-pink-500"></div>
                        <div className=" text-sm">{(entry.recipe?.protein ?? 0) * entry.servings}g</div>
                    </div>
                    <div className="flex items-center gap-2">
                        <div className="w-4 h-4 rounded-full bg-blue-500"></div>
                        <div className=" text-sm">{(entry.recipe?.carbohydrates ?? 0) * entry.servings}g</div>
                    </div>
                    <div className="flex items-center gap-2">
                        <div className="w-4 h-4 rounded-full bg-green-500"></div>
                        <div className=" text-sm">{(entry.recipe?.fats ?? 0) * entry.servings}g</div>
                    </div>
                </div>
            </div>
            <div className="flex-none flex items-center justify-center gap-4">
                <Button variant="gray" size="small" link={AppPathsLoggedIn.recipeDetail(entry.recipe?._id ?? '')}>
                    View
                </Button>
                <div className="flex-none">
                    <Button variant="transparent" onClick={() => setConfirmRemoval(true)}>
                        <FiTrash2 />
                    </Button>
                </div>
            </div>
        </div>
        <Modal isOpen={confirmRemoval} closeModal={() => setConfirmRemoval(false)} title="Remove nutrition entry">
            <p className="mb-8">Are you sure you want to remove this nutrition entry?</p>
            <div className="grid grid-cols-2 gap-4">
                <Button type="button" variant="transparent" onClick={() => setConfirmRemoval(false)}>No</Button>
                <Button onClick={handleRemovalConfirmation}>Yes</Button>
            </div>
        </Modal>
        </>
    );
};