import { useAuth } from "../../auth/AuthContext";
import MySubscriptionCard from "../../components/MySubscriptionCard";
import UpdatePaymentMethodForm from "../../forms/UpdatePaymentMethodForm";
import UpdateUserDetailForm from "../../forms/UpdateUserDetailForm";
import { PublicLayout } from "../../layouts/PublicLayout";

const ProfilePage: React.FC = () => {
    const { isPremium } = useAuth();

    return (
        <PublicLayout>
            <div className="px-6 py-12 sm:py-24 bg-gray-50">
                <div className="w-full max-w-screen-xl m-auto">
                    <h1 className="text-2xl sm:text-4xl uppercase font-black font-display mb-4 tracking-widest text-center">Profile</h1>
                    <p className="sm:text-lg text-center max-w-3xl m-auto">Update your profile and subscription</p>
                </div>
            </div>
            <div className="max-w-5xl mx-auto p-4">
                <div className="py-8">
                    <div className="w-full">
                        <div className="p-6 border border-gray-200 w-full mb-4">
                            <div className="flex justify-between mb-4">
                                <div className="font-display font-black uppercase tracking-widest">My Details</div>
                            </div>
                            <UpdateUserDetailForm />
                        </div>
                        <div className="p-6 border border-gray-200 w-full mb-4" id="mySubscription">
                            <div className="flex justify-between mb-4">
                                <div className="font-display font-black uppercase tracking-widest">My Subscription</div>
                            </div>
                            <MySubscriptionCard />
                        </div>
                        {isPremium &&
                            <div className="p-6 border border-gray-200 w-full mb-4">
                                <div className="flex justify-between mb-4">
                                    <div className="font-display font-black uppercase tracking-widest">Payment Method</div>
                                </div>
                                <UpdatePaymentMethodForm />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </PublicLayout>
    );
};

export default ProfilePage;