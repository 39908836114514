import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment } from 'react';

interface ModalProps {
    isOpen: boolean,
    closeModal: () => void,
    title?: string,
    caption?: string
}

export const Modal: React.FC<ModalProps> = ({
    isOpen,
    closeModal,
    title,
    caption,
    children
}) => {
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModal}
            >
            <div className="min-h-screen px-4 text-center">
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <Dialog.Overlay className="fixed inset-0 bg-gray-800 bg-opacity-80" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
                >
                &#8203;
                </span>
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
                >
                <div className="inline-block p-6 my-8 max-h-screen-minus-nav overflow-hidden overflow-y-auto text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                    {title &&
                        <Dialog.Title
                            as="h3"
                            className="text-xl font-medium leading-6 text-gray-900"
                        >
                            {title}
                        </Dialog.Title>
                    }
                    {caption &&
                        <div className="mt-2 border-b pb-4">
                            <p className="text-sm text-gray-500">
                                {caption}
                            </p>
                        </div>
                    }

                    <div className={clsx((title || caption) && "mt-4")}>
                        {children}
                    </div>
                </div>
                </Transition.Child>
            </div>
            </Dialog>
        </Transition>
    );
}