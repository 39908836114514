import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import ReactPlayer from 'react-player/lazy';
import * as yup from 'yup';
import { string } from "yup";
import { array } from "yup";
import { addExercise, editExercise } from "../../../api/exercise";
import { Category, IApiExercise, Level, Muscle } from "../../../api/models/Exercise";
import { Button } from "../../../components/Button";
import { SmartInput, SmartSelect } from "../../../components/SmartForm";
import { useExercise } from "../../../exercise/ExerciseContext";

interface AddExerciseFormInputs {
    name: string;
    // aliases?: string[];
    primaryMuscles: Muscle;
    secondaryMuscles: Muscle[];
    // force?: Force;
    level: Level;
    // mechanic?: Mechanic;
    // equipment?: Equipment;
    category: Category;
    instructions?: string[];
    // description?: string;
    // tips?: string[];
    // vendor?: string;
    video?: string;
}

interface AddExerciseFormProps {
    initialValues?: IApiExercise;
    isVendorExercise?: boolean;
    onClose(): void;
}

const schema = yup.object({
    name: yup.string().required('Exercise name is required'),
    aliases: yup.string().notRequired(),
    primaryMuscles: string().required('Please select a primary muscle'),
    secondaryMuscles: array().of(yup.string()).notRequired(),
    force: string().notRequired(),
    level: string().required('Level is required'),
    mechanic: string().notRequired(),
    equipment: string().notRequired(),
    category: string().required('Category is required'),
    instructions: array().of(yup.string()).notRequired(),
    description: string().notRequired(),
    tips: array().of(yup.string()).notRequired(),
    video: string().notRequired()
});

const primaryMuscleOptions = [
    {
        value: "abdominals",
        label: "Abdominals"
    },
    {
        value: "hamstrings",
        label: "Hamstrings"
    },
    {
        value: "calves",
        label: "Calves"
    },
    {
        value: "shoulders",
        label: "Shoulders"
    },
    {
        value: "adductors",
        label: "Adductors"
    },
    {
        value: "glutes",
        label: "Glutes"
    },
    {
        value: "quadriceps",
        label: "Quadriceps"
    },
    {
        value: "biceps",
        label: "Biceps"
    },
    {
        value: "forearms",
        label: "Forearms"
    },
    {
        value: "abductors",
        label: "Abductors"
    },
    {
        value: "triceps",
        label: "Triceps"
    },
    {
        value: "chest",
        label: "Chest"
    },
    {
        value: "lower back",
        label: "Lower Back"
    },
    {
        value: "traps",
        label: "Traps"
    },
    {
        value: "middle back",
        label: "Middle Back"
    },
    {
        value: "lats",
        label: "Lats"
    },
    {
        value: "neck",
        label: "Neck"
    },
];

const levelOptions = [
    {
        value: "beginner",
        label: "Beginner"
    },
    {
        value: "intermediate",
        label: "Intermediate"
    },
    {
        value: "expert",
        label: "Expert"
    },
];

const categoryOptions = [
    {
        value: "strength",
        label: "strength"
    },
    {
        value: "stretching",
        label: "stretching"
    },
    {
        value: "plyometrics",
        label: "plyometrics"
    },
    {
        value: "strongman",
        label: "strongman"
    },
    {
        value: "powerlifting",
        label: "powerlifting"
    },
    {
        value: "cardio",
        label: "cardio"
    },
    {
        value: "olympic weightlifting",
        label: "olympic weightlifting"
    },
    {
        value: "crossfit",
        label: "crossfit"
    },
    {
        value: "weighted bodyweight",
        label: "weighted bodyweight"
    },
    {
        value: "assisted bodyweight",
        label: "assisted bodyweight"
    },
];

export const AddExerciseForm: React.FC<AddExerciseFormProps> = ({
    initialValues,
    isVendorExercise,
    onClose
}) => {
    const [videoToDisplay, setVideoToDisplay] = useState<string>();
    const [videoLoading, setVideoLoading] = useState(true);
    const { refetch, getExerciseVideoById } = useExercise();
    const video = initialValues && getExerciseVideoById(initialValues._id);
    const defaultValues: AddExerciseFormInputs | undefined = initialValues ? {
        name: initialValues.name,
        primaryMuscles: initialValues.primaryMuscles[0],
        secondaryMuscles: initialValues.secondaryMuscles,
        level: initialValues.level,
        category: initialValues.category,
        video: video ? video.url : undefined
    }
    :
    undefined;

    const { register, handleSubmit, formState: { errors }, reset, control, setError } = useForm<AddExerciseFormInputs>({
        resolver: yupResolver(schema),
        defaultValues
    });

    const onSubmit = async (values: AddExerciseFormInputs) => {
        const result = 
            initialValues ?
            await editExercise(initialValues._id, { ...values, _id: initialValues._id, primaryMuscles: [values.primaryMuscles], instructions: [""], video: {
                vendor: video?.vendor ?? undefined,
                url: values.video ?? "",
                exercise: initialValues._id,
                _id: video?._id
            } })
            :
            await addExercise({...values, primaryMuscles: [values.primaryMuscles], instructions: [""] });

        if(!result.isError) {
            const title = initialValues ? 'Exercise updated' : 'Exercise added';
            const message = initialValues ? `${values.name} has been updated` : `${values.name} has been added to your library`;
            refetch();
            // toastNotification({ variant: 'success', title, message });
            reset();
            onClose();
        }
    };

    const readOnly = !!(!isVendorExercise && initialValues);
    const videoURL = useWatch({ control, name: 'video' });

    useEffect(() => {
        const canPlay = ReactPlayer.canPlay(videoURL ?? "");

        if(canPlay) {
            setVideoToDisplay(videoURL);
            setVideoLoading(true);
        }

        if(!canPlay) {
            setVideoToDisplay(undefined);
            setVideoLoading(true);

            if(videoURL !== "" || videoURL !== undefined) {
                setError('video', { types: {
                    required: 'Please enter a valid video URL'
                }});
            }
        }
    }, [videoURL]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
                <SmartInput register={register('name')} readOnly={readOnly} autoComplete="false" placeholder="Exercise name..." label="Exercise name" error={errors.name?.message} />
            </div>
            <div className="mb-4">
                <SmartSelect 
                        register={register('primaryMuscles')} 
                        disabled={readOnly}
                        error={errors.primaryMuscles?.message}
                        label='Select a primary muscle'
                        defaultOption='Select a primary muscle'
                        options={primaryMuscleOptions} />
            </div>
            <div className="mb-4">
                <SmartSelect 
                        register={register('level')} 
                        disabled={readOnly}
                        error={errors.level?.message}
                        label='Select a level'
                        defaultOption='Select a level'
                        options={levelOptions} />
            </div>
            <div className="mb-4">
                <SmartSelect 
                        register={register('category')} 
                        disabled={readOnly}
                        error={errors.category?.message}
                        label='Select a category'
                        defaultOption='Select a category'
                        options={categoryOptions} />
            </div>
            <div className="mb-4">
                {!!videoToDisplay &&
                    <div className="relative w-full h-72 mb-2">
                        <ReactPlayer style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} width="100%" height="100%" url={videoToDisplay} controls onReady={() => setVideoLoading(false)} />
                        {/* {videoLoading &&
                            <div className="absolute top-0 left-0 w-full h-full bg-gray-200 flex items-center justify-center pointer-events-none">
                                <Loader />
                            </div>
                        } */}
                    </div>
                }
                <SmartInput register={register('video')} autoComplete="false" placeholder="Video URL..." label="Video" error={errors.video?.message} />
            </div>
            <div className="grid grid-cols-2 gap-4">
                <Button variant="gray" type='button' onClick={onClose}>Cancel</Button>
                <Button variant='primary' type='submit'>{initialValues ? "Update" : "Add"}</Button>
            </div>
        </form>
    );
};