import { CreateLiveWorkoutFormInputs } from "../../forms/admin/CreateLiveWorkoutForm/CreateLiveWorkoutForm";
import { buildUrl, httpClient } from "../httpClient";
import { HttpClientResponse } from "../httpClient/httpClient";
import { ApiStandardResponse, mapHttpClientResponse } from "../httpClient/mapHttpClientResponse";

export const createLiveWorkout = async (values: CreateLiveWorkoutFormInputs): Promise<HttpClientResponse<void>> => {
    const url = buildUrl('/api/admin/liveworkout');

    const response = await httpClient.postRequest<ApiStandardResponse<void>>(url, values);

    return mapHttpClientResponse(response, m => m);
};

export const editLiveWorkout = async (values: CreateLiveWorkoutFormInputs, id: string): Promise<HttpClientResponse<void>> => {
    const url = buildUrl(`/api/admin/liveworkout/${id}`);

    const response = await httpClient.putRequest<ApiStandardResponse<void>>(url, values);

    return mapHttpClientResponse(response, m => m);
};

export const removeLiveWorkout = async (id: string): Promise<HttpClientResponse<void>> => {
    const url = buildUrl(`/api/admin/liveworkout/${id}`);

    const response = await httpClient.deleteRequest<ApiStandardResponse<void>>(url);

    return mapHttpClientResponse(response, m => m);
};