import { Link } from "react-router-dom";
import { IApiRecipeResponse } from "../../api/models/recipe";
import { Image } from "../../components/Image";
import { config } from "../../config";
import { AppPathsPublic } from "../../routes/AppRoutes";
import { GiForkKnifeSpoon } from "react-icons/gi";

interface RecipesListProps {
    recipes: IApiRecipeResponse[];
    isLoading: boolean;
}

export const RecipesList: React.FC<RecipesListProps> = ({
    recipes,
    isLoading
}) => {
    const { apiUri } = config;

    return (
        <div className="grid grid-cols-2 gap-6 2xl:gap-12 mt-6 mb-12 xl:grid-cols-3 sm:mt-12">
            {isLoading &&
                <>
                    <div className="h-56 bg-gray-200"></div>
                    <div className="h-56 bg-gray-200"></div>
                    <div className="h-56 bg-gray-200"></div>
                    <div className="h-56 bg-gray-200"></div>
                </>
            }
            {!isLoading && recipes.length === 0 &&
                <div className="col-span-full flex h-64 items-center justify-center font-bold text-lg">
                    No recipes found matching this filter criteria.
                </div>
            }
            {!isLoading && recipes.map(recipe =>
                <Link to={AppPathsPublic.recipeDetail(recipe._id ?? '')} className="relative transition-opacity hover:opacity-80" key={recipe._id}>
                    {!recipe.customRecipe &&
                        <div className="bg-gray-200 w-full h-40 md:h-48 relative transition-opacity hover:opacity-80">
                            <Image src={`${apiUri}/api/file/${recipe.image}`} className="w-full h-full object-fit object-cover" />
                        </div>
                    }
                    {recipe.customRecipe &&
                        <div className="bg-gray-200 w-full flex items-center justify-center h-40 md:h-48 relative transition-opacity hover:opacity-80">
                            <GiForkKnifeSpoon className="w-8 h-8" />
                        </div>
                    }
                    <div className="mt-4">
                        <div className="font-bold text-sm uppercase tracking-wide mb-2 sm:text-base">{recipe.name}</div>
                        <div className="flex gap-4">
                            <div className="flex items-center gap-2">
                                <div className="w-4 h-4 rounded-full bg-yellow-500"></div>
                                <div className=" text-sm">{recipe.kcals} kcal</div>
                            </div>
                            <div className="hidden sm:flex items-center gap-2">
                                <div className="w-4 h-4 rounded-full bg-pink-500"></div>
                                <div className=" text-sm">{recipe.protein}g</div>
                            </div>
                            <div className="hidden sm:flex items-center gap-2">
                                <div className="w-4 h-4 rounded-full bg-blue-500"></div>
                                <div className=" text-sm">{recipe.carbohydrates}g</div>
                            </div>
                            <div className="hidden sm:flex items-center gap-2">
                                <div className="w-4 h-4 rounded-full bg-green-500"></div>
                                <div className=" text-sm">{recipe.fats}g</div>
                            </div>
                        </div>
                    </div>
                </Link>
            )}
        </div>
    );
};