import { FiCalendar } from "react-icons/fi";
import { Button } from "../../../components/Button";
import { AppPathsLoggedIn } from "../../../routes/AppRoutes";

export const DashboardProgramEmptyState: React.FC = () => {
    return (
        <div className="border border-gray-200 p-6 flex items-center justify-center h-80 mb-6">
            <div className="flex flex-col items-center text-center">
                <div className="mb-8 font-semibold">You currently do not have an active program</div>
                <Button link={AppPathsLoggedIn.programs}>Explore Programs</Button>
            </div>
        </div>
    );
};