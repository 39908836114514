import { DateTime } from "luxon";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { LogbookEmptyState, LogbookItem } from ".";
import { getLogsByStartAndEndDate } from "../../../api/programLog";
import { AppPathsLoggedIn } from "../../../routes/AppRoutes";
import { LogbookSkeleton } from "./LogbookSkeleton";

interface Params {
    month: string;
    year: string;
}

export const Logbook: React.FC = () => {
    const history = useHistory();
    const { month, year } = useParams<Params>();

    const start = DateTime.fromObject({ year: parseInt(year), month: parseInt(month), day: 1}).startOf('month').toJSDate().toISOString();
    const end = DateTime.fromObject({ year: parseInt(year), month: parseInt(month), day: 1}).endOf('month').toJSDate().toISOString();

    const { isLoading, error, data: logbookItems, refetch } = useQuery(`logbook/${start}/${end}`, async () => { 
        const result = await getLogsByStartAndEndDate(start, end);
        if(!result.isError) {
            return result.content;
        }   
    });

    const launchYear = 2021;
    const yearsPastLaunch = parseInt(DateTime.now().toFormat("yyyy")) - (launchYear - 1);

    const handleMonthChange = (selectedMonth: string) => {
        history.push(AppPathsLoggedIn.logbook(selectedMonth, year));
    };

    const handleYearChange = (selectedYear: string) => {
        history.push(AppPathsLoggedIn.logbook(month, selectedYear));
    };

    return (
        <div className="border border-gray-200 flex mb-6">
            <div className="flex w-full">
                <div className="flex-1">
                        <div className="h-12 pr-2 border-b border-gray-200 flex items-center">
                            <div className="font-display font-bold uppercase tracking-widest pl-4 opacity-60 text-xs sm:text-base">Showing {logbookItems?.length} items</div>
                            <div className="ml-auto border-l border-r border-gray-200 pr-2 h-full">
                                <select value={month} onChange={(e) => handleMonthChange(e.currentTarget.value)} className="h-full px-2 bg-transparent border-none">
                                    <option value="1">January</option>
                                    <option value="2">February</option>
                                    <option value="3">March</option>
                                    <option value="4">April</option>
                                    <option value="5">May</option>
                                    <option value="6">June</option>
                                    <option value="7">July</option>
                                    <option value="8">August</option>
                                    <option value="9">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </select>
                            </div>
                            <select value={year} onChange={(e) => handleYearChange(e.currentTarget.value)} className="h-full px-2 bg-transparent border-none">
                                {Array(yearsPastLaunch).fill(null).map((_, i) =>
                                    <option value={launchYear + i}>{launchYear + i}</option>
                                )}
                            </select>
                        </div>
                    {isLoading && 
                        <LogbookSkeleton />
                    }
                    <div className="px-6 py-4">
                        {!isLoading && logbookItems && logbookItems.length > 0 && logbookItems.map(item => 
                            <LogbookItem logbookItem={item} key={item._id} />
                        )}
                        {!isLoading && logbookItems?.length === 0 &&
                            <LogbookEmptyState />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};