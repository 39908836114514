import { FiAward } from "react-icons/fi";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getProgramLog } from "../../../api/programLog";
import { LogbookEntry, LogbookEntrySkeleton } from "../../../components/LogbookEntry";
import { useExercise } from "../../../exercise/ExerciseContext";

interface Params {
    id: string;
}

export const LogbookItem: React.FC = () => {
    const { id } = useParams<Params>();
    const { getExerciseById } = useExercise();

    const { isLoading, error, data: logbookItem, refetch } = useQuery(`logbook/item/${id}`, async () => { 
        const result = await getProgramLog(id);
        if(!result.isError) {
            return result.content;
        }   
    });

    return (
        <div className="border border-gray-200">
            {!isLoading && logbookItem &&
                <div className="">
                    <div className="lg:flex flex-col w-full">
                        <div className="flex-1 p-6">
                            <LogbookEntry entry={logbookItem} />
                        </div>
                            {logbookItem.personalBests.length > 0 &&
                                <div className="flex-none w-full p-6">
                                    <h2 className="font-medium text-xl pt-1">{logbookItem.personalBests.length} personal best{logbookItem.personalBests.length > 1 ? 's' : ''} (this session)</h2>
                                    <hr className="my-4" />
                                    {logbookItem.personalBests.map(personalBest => {
                                        const exercise = getExerciseById(personalBest.exercise);

                                        return (
                                            <div className="bg-white rounded-md flex items-center justify-center p-4 mb-4 border border-gray-200 w-full" key={personalBest._id}>
                                                <FiAward className="w-6 h-6 text-yellow-400" />
                                                <div className="font-medium ml-2">{exercise?.name} - {personalBest.reps} reps {personalBest.weight}kg</div>
                                                <div className="font-medium text-green-500 ml-2">+{personalBest.difference}kg</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            }
                    </div>
                </div>
            }
            {isLoading &&
                <LogbookEntrySkeleton />
            }
        </div>
    );
};