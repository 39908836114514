import { useState } from 'react';
import { useParams } from 'react-router';
import { resetForgottenPassword } from "../../api/authentication";
import { Button } from '../../components/Button';
import { ForgottenPasswordConfirmationForm } from "../../forms/ForgottenPasswordConfirmationForm";
import { ForgottenPasswordConfirmationFormInputs } from "../../forms/ForgottenPasswordConfirmationForm/ForgottenPasswordConfirmationForm";
import { PublicLayout } from "../../layouts/PublicLayout";
import { AppPathsPublic } from '../../routes/AppRoutes';

interface Params {
    confirmationCode?: string;
}

export const ForgottenPasswordConfirmationPage: React.FC = () => {
    const { confirmationCode } = useParams<Params>();
    const [confirmation, setConfirmation] = useState(false);

    if(!confirmationCode) return null;
    
    const handleSubmit = async (values: ForgottenPasswordConfirmationFormInputs) => {
        const result = await resetForgottenPassword(confirmationCode, values.password);

        if(!result.isError) {
            setConfirmation(true);
        }
    };

    return (
        <PublicLayout>
            <div className="px-6 py-12">
                <h2 className="text-4xl uppercase font-black font-display mb-4 tracking-widest text-center">Reset Password</h2>
                {!confirmation && 
                    <>
                        <p className="text-lg text-center m-auto mb-8">Create a new password below</p>
                        <ForgottenPasswordConfirmationForm onSubmit={handleSubmit} />
                    </>
                }
                {confirmation &&
                    <div className="w-full max-w-md m-auto">
                        <p className="text-center mb-4">Password has been successfully reset!</p>
                        <Button link={AppPathsPublic.signIn}>Sign In</Button>
                    </div>
                }
            </div>
        </PublicLayout>
    );
};