import { DateTime } from "luxon";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import { getClientProgramSchedule } from "../../api/program";
import { config } from "../../config";
import { populateRestDays } from "../../pages/Dashboard/Program/DashboardProgram";
import { AppPathsLoggedIn } from "../../routes/AppRoutes";
import { Button } from "../Button";

const TodaysScheduleCard: React.FC = () => {

    const [selectedWeek, setSelectedWeek] = useState<string>();
    const { apiUri } = config;

    const { isLoading, error, data: scheduleData, refetch } = useQuery(`schedule`, async () => { 
        const result = await getClientProgramSchedule();
        if(!result.isError) {
            return populateRestDays(result.content);
        }   
    });
    
    if(!scheduleData) {
        return null;
    }

    const clientProgram = scheduleData.clientPrograms[0];

    if(!clientProgram) {
        return (
            <div className="flex flex-col p-6 border border-gray-200 w-full h-80">
                <div className="flex-1 flex flex-col justify-center items-center text-center">
                    <div className="mb-8 font-semibold">You currently do not have an active program</div>
                    <Button link={AppPathsLoggedIn.programs}>Explore Programs</Button>
                </div>
            </div>
        );
    }

    const programData = scheduleData.programs.find(p => p._id === clientProgram.program);

    const weeks = scheduleData.programWeeks.filter(week => week.programId === programData?._id);

    const days = scheduleData.programDays.filter(day => weeks.map(week => week._id).includes(day.programWeekId));

    const start = DateTime.fromJSDate(new Date(clientProgram.startDate));
    const today = DateTime.fromJSDate(new Date());
    const weekDiff = Math.ceil(today.diff(start, "weeks").weeks) - 1;
    const dayDiff = Math.ceil(today.diff(start, "days").days) - 1;
    const currentWeekDays = days.filter(day => day.programWeekId === weeks[weekDiff]._id);
    const day = currentWeekDays[dayDiff - (weekDiff * 7)];

    return (
        <>
        <div className="flex flex-col justify-between p-6 border border-gray-200 w-full h-80">
            <div className="flex items-center">
                <div className="font-display font-black uppercase tracking-widest">Today</div>
                <Link to="/dashboard/program" className="font-semibold text-sm p-0 flex items-center ml-auto">
                    View my schedule
                    <AiOutlineArrowRight className="ml-2" />    
                </Link>
            </div>
            <div className="">

            </div>
            <div className="">
                <div className="font-bold text-gray-300 text-lg">{programData?.name}</div>
                <div className="font-display font-black uppercase text-2xl mb-2">{day.name}</div>
                {day.name !== "Rest day" &&
                    <Button size="small" link={AppPathsLoggedIn.workout(day._id!, '0')}>Go to workout</Button>
                }
            </div>
        </div>
        </>
    );
};

export default TodaysScheduleCard;