import { yupResolver } from '@hookform/resolvers/yup';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { upgradeSubscription } from '../../api/authentication';
import { UpgradeSubscriptionDto } from '../../api/models/authentication';
import { useAuth } from '../../auth/AuthContext';
import { Button } from '../../components/Button';
import { InputError } from '../../components/Inputs';
import { SmartRadioGroup } from '../../components/SmartForm/SmartRadioGroup';
import { USER_ROLES } from '../../models/user';
import { SUBSCRIPTION_PLAN } from '../RegisterForm/RegisterForm';

export interface UpgradeSubscriptionFormInputs {
    subscriptionPlan: SUBSCRIPTION_PLAN;
}

const schema = yup.object({
    subscriptionPlan: yup.number().required('Subscription plan is required')
}).required();

const UpgradeSubscriptionForm: React.FC = () => {
    const stripe = useStripe();
    const elements = useElements();
    const { user, setUser } = useAuth();

    const [error, setError] = useState<string>();
    const [subscriptionPlan, setSubscriptionPlan] = useState("0");

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<UpgradeSubscriptionFormInputs>({
        resolver: yupResolver(schema)
    });

    const handleSubscriptionPlanChange = (val: SUBSCRIPTION_PLAN) => {
        setSubscriptionPlan(val.toString());
        setValue('subscriptionPlan', val);
    };

    const upgradeRequest = useMutation(`${new Date()}-upgrade`, async (upgradeDto: UpgradeSubscriptionDto) => {
        const result = await upgradeSubscription(upgradeDto);
        if(!result.isError) {
            if(user) {
                setUser({...user, role: USER_ROLES.PREMIUM});
                toast.success("Subscription has been upgraded");
            }
        } 
    });
    
    const onSubmit = async (values: UpgradeSubscriptionFormInputs) => {
        // Block native form submission.
        setError(undefined);
    
        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          return;
        }
    
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);
    
        if(!cardElement) {
            return;
        }

        // Use your card Element with other Stripe.js APIs
        const {error, paymentMethod} = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });
    
        if (error) {
            console.log(error);
          setError(error.message);

        } else if(paymentMethod) {

          const upgradeDto: UpgradeSubscriptionDto = {
            stripePaymentIntentId: paymentMethod.id,
            subscriptionPlan: parseInt(subscriptionPlan),
          };

            upgradeRequest.mutate(upgradeDto);
        }
    };

    useEffect(() => {
        setValue('subscriptionPlan', SUBSCRIPTION_PLAN.MONTHLY);
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col h-full'>
            <div className="w-full">
                <SmartRadioGroup 
                    onChange={(val: any) => handleSubscriptionPlanChange(val as SUBSCRIPTION_PLAN)}
                    value={subscriptionPlan}
                    options={[
                        {
                            value: "0",
                            label: "Monthly (£14.99)",
                            description: "Monthly"
                        },
                        {
                            value: "1",
                            label: "Yearly (£119.99)",
                            description: "Yearly"
                        }
                    ]} />
            </div>
            <label className="block text-sm font-medium text-black mb-1">Payment details</label>
            <div className="border border-gray-200 p-4 rounded-md mb-4">
                <CardElement options={{ hidePostalCode: true }} />
            </div>
            <InputError error={error} />
            <div className="mt-4 w-full">
                <Button type="submit">Upgrade to Premium</Button>
            </div>
        </form>
    );
};

export default UpgradeSubscriptionForm;