import { LiveWorkout, WORKOUT_DIFFICULTY, WORKOUT_LOCATION } from "../models/liveWorkout";
import { buildUrl, httpClient } from "./httpClient";
import { HttpClientResponse } from "./httpClient/httpClient";
import { ApiListResponse, ApiStandardResponse, mapHttpClientResponse, mapHttpListResponse } from "./httpClient/mapHttpClientResponse";
import { WorkoutLog, WorkoutStats } from "./models/workoutLog";

export const createLiveWorkoutLog = async (liveWorkout: string, duration: number): Promise<HttpClientResponse<WorkoutLog>> => {
    const url = buildUrl(`/api/log/liveworkout`);

    const response = await httpClient.postRequest<ApiStandardResponse<WorkoutLog>>(url, { liveWorkout, duration });

    return mapHttpClientResponse(response, m => m);
};

export const getWorkoutStatsBetweenDates = async (start: string, end: string): Promise<HttpClientResponse<WorkoutStats>> => {
    const url = buildUrl(`/api/log/stats?start=${start}&end=${end}`);

    const response = await httpClient.getRequest<ApiStandardResponse<WorkoutStats>>(url);

    return mapHttpClientResponse(response, m => m);
};