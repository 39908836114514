import { useMutation, useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { getLiveWorkout, getLiveWorkouts } from "../../api/liveWorkout";
import { useAuth } from "../../auth/AuthContext";
import { PublicLayout } from "../../layouts/PublicLayout";
import { BiLock, BiPlay } from "react-icons/bi";
import { Button } from "../../components/Button";
import { AppPathsAdmin, AppPathsLoggedIn, AppPathsPublic } from "../../routes/AppRoutes";
import { FreeTrialBanner } from "../../components/Banners";
import { Modal } from "../../components/Modal/Modal";
import { useState } from "react";
import { createLiveWorkoutLog } from "../../api/workoutLog";
import { LiveWorkoutDetailSkeleton } from "./LiveWorkoutDetailSkeleton";
import { Image } from "../../components/Image";
import { config } from "../../config";
import { difficultyMap, muscleCategoryOptions } from "../../api/models/Exercise";
import { toast } from "react-toastify";

interface LiveWorkoutDetailPageParams {
    id: string;
}

export const LiveWorkoutDetailPage: React.FC = () => {
    const { id } = useParams<LiveWorkoutDetailPageParams>();
    const { user, isPremium, isAdmin } = useAuth();
    const { apiUri } = config;

    const [videoModal, setVideoModal] = useState(false);

    const { isLoading, error, data: liveWorkout, refetch } = useQuery(`${id}-live-workout`, async () => { 
        const result = await getLiveWorkout(id);
        if(!result.isError) {
            return result.content;
        }   
    });

    const { isLoading: relatedIsLoading, error: relatedError, data: relatedLiveWorkouts = [], refetch: refetchRelated } = useQuery(`${liveWorkout?._id}-live-workouts`, async () => { 
        const result = await getLiveWorkouts(
            liveWorkout?.difficulty ? [liveWorkout.difficulty] : undefined,
            liveWorkout?.muscleCategory ? [liveWorkout.muscleCategory] : undefined,
            liveWorkout?.location ? [liveWorkout.location] : undefined);
        if(!result.isError) {
            return result.content;
        }   
    });

    const createLiveWorkoutLogRequest = useMutation(`${new Date()}-create-live-workout-log`, async () => {
        const result = await createLiveWorkoutLog(liveWorkout?._id ?? '', liveWorkout?.duration ?? 0);
        
        if(!result.isError) {
            setVideoModal(false);
            toast.success("Live workout complete - It has been added to your log book.");
        }
    });

    const filteredRelatedLiveWorkouts = relatedLiveWorkouts.filter(liveWorkout => liveWorkout._id !== id).slice(0, 4);

    return (
        <PublicLayout>
            <div className="px-6 py-8">
        <div className="w-full max-w-screen-xl m-auto">
            {isLoading && 
                <LiveWorkoutDetailSkeleton />
            }
            {liveWorkout && !isLoading &&
                <div className="grid gap-4 sm:grid-cols-2 sm:gap-12 mb-16">
                    <div>
                        <div className="relative">
                            <Image src={`${apiUri}/api/file/${liveWorkout.image}`} className="" />
                            <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-20">
                                {(!user || !isPremium) &&
                                    <BiLock className="w-12 h-12 text-white" />
                                }
                                {user && isPremium &&
                                    <button className="w-full h-full flex items-center justify-center cursor-pointer group" onClick={() => setVideoModal(true)}>
                                        <BiPlay className="w-12 h-12 text-white group-hover:opacity-60 transition-all" />
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="uppercase tracking-widest text-sm mb-2">Live Workout</div>
                        <h1 className="font-black text-xl sm:text-3xl tracking-wide font-display uppercase mb-6">{liveWorkout.name}</h1>
                        <div className="grid grid-cols-2 gap-4 max-w-xs relative wrap mb-8">
                                <div className="flex-none uppercase tracking-widest font-display font-bold text-sm">Duration</div>
                                <div className="uppercase tracking-widest text-sm ml-4">{liveWorkout.duration} Minutes</div>
                                <div className="flex-none uppercase tracking-widest font-display font-bold text-sm">Difficulty</div>
                                <div className="uppercase tracking-widest text-sm ml-4">{difficultyMap[liveWorkout.difficulty]}</div>
                                <div className="flex-none uppercase tracking-widest font-display font-bold text-sm">Muscle Group</div>
                                <div className="uppercase tracking-widest text-sm ml-4">{muscleCategoryOptions[liveWorkout.muscleCategory].label}</div>
                                <div className="flex-none uppercase tracking-widest font-display font-bold text-sm">Equipment</div>
                                <div className="uppercase tracking-widest text-sm ml-4">None</div>
                        </div>
                        <div className="lg:w-2/3">
                            {!user &&
                                <Button link={AppPathsPublic.signUp}>Start your free trial</Button>
                            }
                            {user && !isPremium &&
                                <Button link={`${AppPathsLoggedIn.profile}#mySubscription`}>Upgrade to Premium</Button>
                            }
                            {user && isPremium &&
                                <Button onClick={() => setVideoModal(true)}>Start Workout</Button>
                            }
                        </div>
                        {isAdmin &&
                            <div className="lg:w-2/3 mt-4">
                                <Button variant="gray" link={AppPathsAdmin.editLiveWorkout(liveWorkout._id!)}>Edit</Button>
                            </div>
                        }
                        <hr className="my-8" />
                        <h3 className="font-bold uppercase tracking-wider mb-4">Description</h3>
                        <p>{liveWorkout.description}</p>
                    </div>
                </div>
            }
            <hr className="my-8" />
            {!relatedIsLoading && filteredRelatedLiveWorkouts.length > 0 &&
                <>
                    <h3 className="font-display font-black uppercase tracking-widest text-lg sm:text-xl mb-4">Related Workouts</h3>
                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-6 2xl:gap-12 mt-4 mb-8">
                        {filteredRelatedLiveWorkouts.map(liveWorkout =>
                            <Link to={AppPathsPublic.liveWorkoutDetail(liveWorkout._id ?? '')} className="relative transition-opacity hover:opacity-80">
                                <Image src={`${apiUri}/api/file/${liveWorkout.image}`} className="" />
                                <div className="mt-4">
                                    <div className="font-bold uppercase tracking-wide mb-2 text-sm sm:text-base">{liveWorkout.name}</div>
                                    <p className="text-sm sm:text-base">{liveWorkout.description}</p>
                                </div>
                            </Link>
                        )}
                    </div>
                </>
            }
        </div>
      </div>
      <FreeTrialBanner />
      <Modal isOpen={videoModal} closeModal={() => setVideoModal(false)}>
        <div className="w-full max-w-6xl h-full max-h-screen bg-gray-100">
            <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
            <iframe src={`https://player.vimeo.com/video/${liveWorkout?.video}?h=c5b19f0af2`} style={{ position:"absolute",top:0,left:0,width:"100%",height:"100%" }} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
            </div>      
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
        <div className="flex justify-between mt-4 gap-4">
            <Button variant="gray" onClick={() => setVideoModal(false)}>Cancel</Button>
            <Button onClick={() => createLiveWorkoutLogRequest.mutate()}>Complete</Button>
        </div>
      </Modal>
    </PublicLayout>
    );
};