import clsx from "clsx";
import { FiTrash2 } from 'react-icons/fi';
import { Category, EXERCISE_TYPE } from "../../api/models/Exercise";
import { IApiProgramLogItem, MEASUREMENT_TYPE, MEASUREMENT_UNIT, WorkoutSet } from "../../api/models/program";
import { useExercise } from "../../exercise/ExerciseContext";
import { useWorkout } from "../../workout/WorkoutContext";
import { useProgramEditor } from "../ProgramEditor/ProgramEditorContext/ProgramEditorContext";

interface Props {
    workoutSet: WorkoutSet;
    workoutStepId: string;
    exercise: string;
    logInfo?: IApiProgramLogItem;
}

export const LogbookEntrySet: React.FC<Props> = ({
    workoutSet,
    workoutStepId,
    exercise,
    logInfo
}) => {
    const { getExerciseById } = useExercise();

    const exerciseDetails = getExerciseById(exercise);
    
    return (
        // <div className="sm:flex items-center flex-none w-full mt-2 pt-4 pb-4 border-t border-gray-200">
        //     <div className="flex items-center">
        //         <div className="font-medium text-white w-10 h-10 mt-4 flex items-center justify-center mr-4 sm:h-12 sm:w-12 rounded-md bg-gray-700">{workoutSet.order + 1}.</div>
        //         <div className="flex flex-col justify-center">
        //             <div className="font-medium text-gray-500 text-xs">Target:</div>
        //             <div className="flex items-center">
        //                 <div className="h-10 sm:h-12 w-20 rounded-md bg-gray-100 flex items-center p-2 text-gray-600 cursor-not-allowed">{workoutSet.reps}</div>
        //                 <div className="text-sm font-medium text-gray-500 ml-2">Reps</div>
        //                 <div className="h-10 sm:h-12 w-20 rounded-md bg-gray-100 flex items-center p-2 text-gray-600 ml-4 cursor-not-allowed">{workoutSet.weight?.value}</div>
        //                 <div className="text-sm font-medium text-gray-500 ml-2">{workoutSet.weight?.units === MEASUREMENT_UNIT.METRIC ? "KG" : "Lbs"}</div>
        //             </div>
        //         </div>
        //     </div>
        //     <div className="mt-4 ml-14 sm:mt-0">
        //         <div className="font-medium text-green-500 text-xs">Achieved:</div>
        //         <div className="flex items-center">
        //             <div className="flex items-center">
        //                 <div className="h-10 sm:h-12 w-20 rounded-md bg-green-100 flex items-center p-2 text-green-600 cursor-not-allowed">{logInfo?.reps ?? "--"}</div>
        //                 <div className="text-sm font-medium text-gray-500 ml-2">Reps</div>
        //             </div>
        //             <div className="flex items-center ml-4">
        //                 <div className="h-10 sm:h-12 w-20 rounded-md bg-green-100 flex items-center p-2 text-green-600 cursor-not-allowed">{logInfo?.weight ?? "--"}</div>
        //                 <div className="text-sm font-medium text-gray-500 ml-2">KG</div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <div className="sm:flex items-center flex-none w-full mt-2 pt-4 pb-4 border-t border-gray-200">
            <div className="flex items-center">
                <div className="font-medium text-white w-10 h-10 mt-4 flex items-center justify-center mr-4 sm:h-12 sm:w-12 rounded-md bg-gray-700">{workoutSet.order + 1}.</div>
                <div className="flex flex-col justify-center">
                    <div className="font-medium text-gray-500 text-xs">Target:</div>
                    {workoutSet.measurementType === MEASUREMENT_TYPE.WEIGHT &&
                        <div className="flex items-center">
                            <div className="h-10 sm:h-12 w-20 rounded-md bg-gray-100 flex items-center p-2 text-gray-600 cursor-not-allowed">{workoutSet.reps}</div>
                            <div className="text-sm font-medium text-gray-500 ml-2">Reps</div>
                            {workoutSet.weight?.units !== MEASUREMENT_UNIT.BODYWEIGHT &&
                                <>
                                    <div className="h-10 sm:h-12 w-20 rounded-md bg-gray-100 flex items-center p-2 text-gray-600 ml-4 cursor-not-allowed">{workoutSet.weight?.value}</div>
                                    <div className="text-sm font-medium text-gray-500 ml-2">{workoutSet.weight?.units === MEASUREMENT_UNIT.METRIC ? "KG" : "LBS"}</div>
                                </>
                            }
                            {workoutSet.weight?.units === MEASUREMENT_UNIT.BODYWEIGHT &&
                                <div className="h-10 sm:h-12 rounded-md bg-gray-100 flex items-center p-2 text-gray-600 ml-4 cursor-not-allowed">Bodyweight</div>
                            }
                        </div>
                    }
                    {workoutSet.measurementType === MEASUREMENT_TYPE.RPE &&
                        <div className="flex items-center">
                            <div className="h-10 sm:h-12 w-20 rounded-md bg-gray-100 flex items-center p-2 text-gray-600 cursor-not-allowed">{workoutSet.reps}</div>
                            <div className="text-sm font-medium text-gray-500 ml-2">Reps</div>
                            <div className="h-10 sm:h-12 w-20 rounded-md bg-gray-100 flex items-center p-2 text-gray-600 ml-4 cursor-not-allowed">{workoutSet.rpe}</div>
                            <div className="text-sm font-medium text-gray-500 ml-2">RPE</div>
                        </div>
                    }

                    {workoutSet.measurementType === MEASUREMENT_TYPE.PRECENTAGE &&
                        <div className="flex items-center">
                            <div className="h-10 sm:h-12 w-20 rounded-md bg-gray-100 flex items-center p-2 text-gray-600 cursor-not-allowed">{workoutSet.reps}</div>
                            <div className="text-sm font-medium text-gray-500 ml-2">Reps</div>
                            <div className="h-10 sm:h-12 w-20 rounded-md bg-gray-100 flex items-center p-2 text-gray-600 ml-4 cursor-not-allowed">{workoutSet.percentage}</div>
                            <div className="text-sm font-medium text-gray-500 ml-2">%1RM</div>
                        </div>
                    }

                    {workoutSet.measurementType === MEASUREMENT_TYPE.TIME &&
                        <div className="flex items-center">
                            <div className="h-10 sm:h-12 w-20 rounded-md bg-gray-100 flex items-center p-2 text-gray-600 cursor-not-allowed">{workoutSet.time}</div>
                            <div className="text-sm font-medium text-gray-500 ml-2">Seconds</div>
                        </div>
                    }
                    
                    {workoutSet.measurementType === MEASUREMENT_TYPE.INTERVAL &&
                        <div className="flex items-center">
                            <div className="h-10 sm:h-12 w-20 rounded-md bg-gray-100 flex items-center p-2 text-gray-600 cursor-not-allowed">{workoutSet.interval?.intOn}</div>
                            <div className="text-sm font-medium text-gray-500 ml-2">ON</div>
                            <div className="h-10 sm:h-12 w-20 rounded-md bg-gray-100 flex items-center p-2 text-gray-600 ml-4 cursor-not-allowed">{workoutSet.interval?.intOff}</div>
                            <div className="text-sm font-medium text-gray-500 ml-2">OFF</div>
                            <div className="h-10 sm:h-12 w-20 rounded-md bg-gray-100 flex items-center p-2 text-gray-600 ml-4 cursor-not-allowed">{workoutSet.interval?.rounds}</div>
                            <div className="text-sm font-medium text-gray-500 ml-2">ROUNDS</div>
                        </div>
                    }

                    {workoutSet.measurementType === MEASUREMENT_TYPE.CARDIO_TIME &&
                        <div className="flex items-center">
                            <div className="h-10 sm:h-12 w-20 rounded-md bg-gray-100 flex items-center p-2 text-gray-600 cursor-not-allowed">{workoutSet.speed}</div>
                            <div className="text-sm font-medium text-gray-500 ml-2">KM/H</div>
                            <div className="h-10 sm:h-12 w-20 rounded-md bg-gray-100 flex items-center p-2 text-gray-600 ml-4 cursor-not-allowed">{workoutSet.time}</div>
                            <div className="text-sm font-medium text-gray-500 ml-2">Seconds</div>
                        </div>
                    }

                    {workoutSet.measurementType === MEASUREMENT_TYPE.CARDIO_DISTANCE &&
                        <div className="flex items-center">
                            <div className="h-10 sm:h-12 w-20 rounded-md bg-gray-100 flex items-center p-2 text-gray-600 cursor-not-allowed">{workoutSet.speed}</div>
                            <div className="text-sm font-medium text-gray-500 ml-2">KM/H</div>
                            <div className="h-10 sm:h-12 w-20 rounded-md bg-gray-100 flex items-center p-2 text-gray-600 ml-4 cursor-not-allowed">{workoutSet.distance}</div>
                            <div className="text-sm font-medium text-gray-500 ml-2">Distance (KM)</div>
                        </div>
                    }

                    {workoutSet.measurementType === MEASUREMENT_TYPE.CARDIO_INTERVAL &&
                        <div className="flex items-center">
                            <div className="h-10 sm:h-12 w-20 rounded-md bg-gray-100 flex items-center p-2 text-gray-600 cursor-not-allowed">{workoutSet.speed}</div>
                            <div className="text-sm font-medium text-gray-500 ml-2">KM/H</div>
                            <div className="h-10 sm:h-12 w-20 rounded-md bg-gray-100 flex items-center p-2 text-gray-600 ml-4 cursor-not-allowed">{workoutSet.interval?.intOn}</div>
                            <div className="text-sm font-medium text-gray-500 ml-2">ON</div>
                            <div className="h-10 sm:h-12 w-20 rounded-md bg-gray-100 flex items-center p-2 text-gray-600 ml-4 cursor-not-allowed">{workoutSet.interval?.intOff}</div>
                            <div className="text-sm font-medium text-gray-500 ml-2">OFF</div>
                            <div className="h-10 sm:h-12 w-20 rounded-md bg-gray-100 flex items-center p-2 text-gray-600 ml-4 cursor-not-allowed">{workoutSet.interval?.rounds}</div>
                            <div className="text-sm font-medium text-gray-500 ml-2">ROUNDS</div>
                        </div>
                    }
                </div>
            </div>
            {exerciseDetails?.category !== Category.cardio &&
                <div className="mt-4 ml-14 sm:mt-0">
                    <div className="font-medium text-green-500 text-xs">Achieved:</div>
                    <div className="flex items-center">
                        <div className="flex items-center">
                            <div className="h-10 sm:h-12 w-20 rounded-md bg-green-100 flex items-center p-2 text-green-600 cursor-not-allowed">{logInfo?.reps ?? "--"}</div>
                            <div className="text-sm font-medium text-gray-500 ml-2">Reps</div>
                        </div>
                        {workoutSet.weight?.units === MEASUREMENT_UNIT.BODYWEIGHT &&
                            <div className="flex items-center ml-4">
                                <div className="h-10 sm:h-12 rounded-md bg-green-100 flex items-center p-2 text-green-600 cursor-not-allowed">Bodyweight</div>
                            </div>
                        }
                        {workoutSet.weight?.units === MEASUREMENT_UNIT.METRIC &&
                            <div className="flex items-center ml-4">
                                <div className="h-10 sm:h-12 w-20 rounded-md bg-green-100 flex items-center p-2 text-green-600 cursor-not-allowed">{logInfo?.weight ?? "--"}</div>
                                <div className="text-sm font-medium text-gray-500 ml-2">KG</div>
                            </div>
                        }
                        {workoutSet.weight?.units === MEASUREMENT_UNIT.IMPERIAL &&
                            <div className="flex items-center ml-4">
                                <div className="h-10 sm:h-12 w-20 rounded-md bg-green-100 flex items-center p-2 text-green-600 cursor-not-allowed">{logInfo?.weight ?? "--"}</div>
                                <div className="text-sm font-medium text-gray-500 ml-2">LBS</div>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    );
};