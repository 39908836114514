import { Link } from "react-router-dom";
import { useAuth } from "../../auth/AuthContext";
import { FreeTrialBanner } from "../../components/Banners";
import { PublicLayout } from "../../layouts/PublicLayout";
import { AppPathsPublic } from "../../routes/AppRoutes";

export const HomePage: React.FC = () => {
  const { user } = useAuth();

    return (
        <PublicLayout>
      <div className="relative flex items-center w-full bg-gray-100 py-20 px-4" style={{height: 660}}>
        <div className="absolute top-0 left-0 w-full h-full">
          {/* <img className="w-full h-full object-cover object-center" src="https://images.pexels.com/photos/3621168/pexels-photo-3621168.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" alt="desc" /> */}
          <img className="w-full h-full object-cover object-center" src="/assets/images/hero-image.jpg" alt="desc" />
        </div>
        <div className="absolute top-0 left-0 w-full h-full flex items-center bg-black bg-opacity-30">
          <div className="w-full max-w-screen-xl m-auto text-white text-center">
            <h1 className="font-display font-black text-5xl uppercase mb-4 tracking-widest">Become the strongest version of yourself</h1>
            <p className="text-xl mb-12">Let's move together and guide you towards your health and fitness goals.</p>
            {!user &&
              <Link to={AppPathsPublic.signUp} className="uppercase font-medium bg-white rounded-md px-6 py-4 text-black text-lg transition-all hover:opacity-80">Start your free trial</Link>
            }
          </div>
        </div>
      </div>
      <div className="px-6 py-12">
        <div className="w-full max-w-screen-xl m-auto">
          <h2 className="text-4xl uppercase font-black font-display mb-4 tracking-widest text-center">Made for everyone</h2>
          <p className="text-lg text-center max-w-3xl m-auto">Whether you’re a complete beginner or want to step up your routine, get all the workouts you need here at home or at the gym.</p>
          <div className="grid sm:grid-cols-3 gap-6 2xl:gap-12 mt-12">
            <Link to={AppPathsPublic.liveWorkouts} className="relative transition-opacity hover:opacity-80">
              {/* <img src="https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/categories/Mobile_Category_Fitness.jpg" alt="Desc" /> */}
              <img src="/assets/images/beginner.jpg" alt="Beginner" />
              <div className="mt-4">
                <div className="font-bold uppercase tracking-wide mb-2">Beginner</div>
                <p>New to the gym? Start here!</p>
              </div>
            </Link>
            <Link to={AppPathsPublic.liveWorkouts} className="relative transition-opacity hover:opacity-80">
              {/* <img src="https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/categories/Mobile_Category_Fitness.jpg" alt="Desc" /> */}
              <img src="/assets/images/intermediate.jpg" alt="Beginner" />
              <div className="mt-4">
                <div className="font-bold uppercase tracking-wide mb-2">Intermediate</div>
                <p>Looking to step up your gym routine? Let’s go!</p>
              </div>
            </Link>
            <Link to={AppPathsPublic.liveWorkouts} className="relative transition-opacity hover:opacity-80">
              {/* <img src="https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/categories/Mobile_Category_Fitness.jpg" alt="Desc" />
               */}
              <img src="/assets/images/advanced.jpg" alt="Beginner" />
              <div className="mt-4">
                <div className="font-bold uppercase tracking-wide mb-2">Advanced</div>
                <p>Made gains but want a new challenge? Click here!</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="px-6 py-16">
        <div className="w-full max-w-screen-xl m-auto">
          <h2 className="text-4xl uppercase font-black font-display mb-4 tracking-widest text-center">We've got it covered</h2>
          <p className="text-lg text-center max-w-3xl m-auto">Looking to workout from home? We’ve got you covered. Our home guide and follow along workouts can all be done in the comfort of your own home, no need for a gym membership!</p>
        
          <div className="mx-auto flex flex-wrap mt-20">
            {/* <img alt="ecommerce" className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded" src="/assets/images/workout.jpg" /> */}
            <img alt="ecommerce" className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded" src="/assets/images/1-workout.jpg" />
            <div className="lg:w-1/2 w-full lg:pl-16 lg:py-6 mt-6 lg:mt-0 flex items-center">
              <div className="relative">
                <div className="text-3xl xl:text-9xl font-black text-gray-100 tracking-widest xl:absolute xl:-top-2/3">01</div>
                <h3 className="text-gray-900 text-4xl font-display font-black uppercase tracking-widest mb-8">Workout</h3>
                <p className="leading-relaxed">Not only have our workouts been tried and tested by us personally, each workout has been carefully planned and tried on real clients in the gym. Whether your goal is to build your glutes or cut down and see some abs, our wide range of exercises will help you target every part of your body for optimal results. With a wide range of gym guides, home guides and follow along workouts, we will find the perfect fit for you.</p>
              </div>
            </div>
          </div>

          <div className="mx-auto flex flex-wrap mt-20 lg:flex-row-reverse">
            {/* <img alt="ecommerce" className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded" src="/assets/images/nutrition.jpg" /> */}
            <img alt="ecommerce" className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded" src="/assets/images/2-nutrition.jpg" />
            <div className="lg:w-1/2 w-full lg:pr-16 lg:py-6 mt-6 lg:mt-0 flex items-center">
              <div className="relative">
                <div className="text-3xl xl:text-9xl font-black text-gray-100 tracking-widest xl:absolute xl:-top-2/3">02</div>
                <h3 className="text-gray-900 text-4xl font-display font-black uppercase tracking-widest mb-8">Nutrition</h3>
                <p className="leading-relaxed">Always struggled to see results from your workouts? Well look no further. Our calorie and macro calculator will help you work out how many calories you will need to achieve your goals - whether that be weight loss, maintenance or weight gain. Our calculator will ask you some quick questions and then send your calories and macros to your own personal nutrition page. Here, you will be able to track your calories daily and keep on track with your macros. With a wide range of healthy recipes from breakfasts to dessert, our recipe page will also have each calorie and macro added for extra support to make your cooking experience super easy. You can also add your own recipes and save them to the app to use again, so get creative!</p>
              </div>
            </div>
          </div>

          <div className="mx-auto flex flex-wrap mt-20 mb-16">
            <img alt="ecommerce" className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded" src="/assets/images/tracking.jpg" />
            <div className="lg:w-1/2 w-full lg:pl-16 lg:py-6 mt-6 lg:mt-0 flex items-center">
              <div className="relative">
                <div className="text-3xl xl:text-9xl font-black text-gray-100 tracking-widest xl:absolute xl:-top-2/3">03</div>
                <h3 className="text-gray-900 text-4xl font-display font-black uppercase tracking-widest mb-8">Tracking</h3>
                <p className="leading-relaxed">Congratulations! This week you’ve hit 3 new personal bests (PBs). Tracking your progress has never been easier with our PB tracker, calorie tracker, macro tracker and time tracker. Each workout you complete will track your weight progress and let you know how many personal bests you have hit this week, along with the duration of your workout so you can keep track of your time. Our app remembers and reminds you what weights you lifted the week before so you don’t have to - making your gym experience even easier.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-6 py-16 bg-gray-50">
        <div className="w-full max-w-screen-xl m-auto">
          <h2 className="text-4xl uppercase font-black font-display mb-4 tracking-widest text-center">What our members think</h2>
          <p className="text-lg text-center max-w-3xl m-auto">Whether you’re a complete beginner or you want to step up your routine, get the full studio experience at home with thousands of classes for body, mind, and spirit.</p>
          <div className="grid sm:grid-cols-3 gap-6 2xl:gap-12 mt-12">
            <div className="relative transition-opacity hover:opacity-80">
              {/* <img src="https://images.pexels.com/photos/3680316/pexels-photo-3680316.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="Desc" /> */}
              <div className="relative w-full h-full bg-gray-400 text-white px-8 py-12 flex flex-col justify-end">
                <div className="font-bold uppercase tracking-wide mb-2">"They have kept me motivated</div>
                <p>The workouts are amazing and really easy to follow. They have kept me motivated during lockdown...</p>
                <div className="mt-2 font-bold">Emma Lonsdale</div>
              </div>
            </div>
            <div className="relative transition-opacity hover:opacity-80">
              {/* <img src="https://images.pexels.com/photos/4750165/pexels-photo-4750165.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="Desc" /> */}
              <div className="relative w-full h-full bg-gray-400 text-white px-8 py-12 flex flex-col justify-end">
                <div className="font-bold uppercase tracking-wide mb-2">"The recipes are amazing</div>
                <p>I absolutely love this app! I used to really struggle with meal ideas but the recipes on this are amazing...</p>
                <div className="mt-2 font-bold">Amanda T</div>
              </div>
            </div>
            <div className="relative transition-opacity hover:opacity-80">
              {/* <img src="https://images.pexels.com/photos/5046546/pexels-photo-5046546.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="Desc" /> */}
              <div className="relative w-full h-full bg-gray-400 text-white px-8 py-12 flex flex-col justify-end">
                <div className="font-bold uppercase tracking-wide mb-2">"I keeps me on track</div>
                <p>The app is amazing! It lets me track my calories and keeps me on track! The live workouts are fun too...</p>
                <div className="mt-2 font-bold">Emma</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FreeTrialBanner />
        </PublicLayout>
    );
};