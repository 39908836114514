import { useState } from "react";
import { IApiNutritionDiaryEntry, NutritionDiaryCategory } from "../../api/models/nutrition";
import { createNutritionDiaryEntry, removeNutritionDiaryEntry } from "../../api/nutrition";
import RecipeSelectModal from "../RecipeSelectModal";
import { NutritionDiaryItem } from "./NutritionDiaryItem";

interface NutritionDiaryProps {
    date: Date;
    entries: IApiNutritionDiaryEntry[];
    refetchDiary(): void;
}

export const NutritionDiary: React.FC<NutritionDiaryProps> = ({
    date,
    entries,
    refetchDiary
}) => {
    const [selectedCategory, setSelectedCategory] = useState<NutritionDiaryCategory>();

    const addToNutritionDiary = async (recipe: string, servings: number) => {
        const result = await createNutritionDiaryEntry({
            recipe,
            servings,
            date,
            category: selectedCategory ?? 0
        });

        if(!result.isError) {
            refetchDiary();
            setSelectedCategory(undefined);
        }
    };

    const handleRemoveNutritionDiaryEntry = async (id: string) => {
        const result = await removeNutritionDiaryEntry(id);

        if(!result.isError) {
            refetchDiary();
        }
    };

    return (
        <>
        <div className="w-full">
            <div className="w-full mb-4">
                <h3 className="text-lg uppercase font-black font-display mb-2 tracking-wide">Breakfast</h3>
                {entries.filter(entry => entry.category === NutritionDiaryCategory.BREAKFAST).map(entry =>
                    <NutritionDiaryItem entry={entry} handleRemoveNutritionDiaryEntry={handleRemoveNutritionDiaryEntry} />   
                )}
                <button 
                    className="w-full border-dashed border-2 border-gray-200 rounded-md p-4 flex items-center justify-center font-semibold bg-transparent transition-all hover:bg-gray-50"
                    onClick={() => setSelectedCategory(NutritionDiaryCategory.BREAKFAST)}    
                >
                    Add +
                </button>
            </div>
            <div className="w-full mb-4">
                <h3 className="text-lg uppercase font-black font-display mb-2 tracking-wide">Lunch</h3>
                {entries.filter(entry => entry.category === NutritionDiaryCategory.LUNCH).map(entry =>
                    <NutritionDiaryItem entry={entry} handleRemoveNutritionDiaryEntry={handleRemoveNutritionDiaryEntry} />   
                )}
                <button 
                    className="w-full border-dashed border-2 border-gray-200 rounded-md p-4 flex items-center justify-center font-semibold bg-transparent transition-all hover:bg-gray-50"
                    onClick={() => setSelectedCategory(NutritionDiaryCategory.LUNCH)}    
                >
                    Add +
                </button>
            </div>
            <div className="w-full mb-4">
                <h3 className="text-lg uppercase font-black font-display mb-2 tracking-wide">Dinner</h3>
                {entries.filter(entry => entry.category === NutritionDiaryCategory.DINNER).map(entry =>
                    <NutritionDiaryItem entry={entry} handleRemoveNutritionDiaryEntry={handleRemoveNutritionDiaryEntry} />   
                )}
                <button 
                    className="w-full border-dashed border-2 border-gray-200 rounded-md p-4 flex items-center justify-center font-semibold bg-transparent transition-all hover:bg-gray-50"
                    onClick={() => setSelectedCategory(NutritionDiaryCategory.DINNER)}    
                >
                    Add +
                </button>
            </div>
            <div className="w-full mb-4">
                <h3 className="text-lg uppercase font-black font-display mb-2 tracking-wide">Snacks</h3>
                {entries.filter(entry => entry.category === NutritionDiaryCategory.SNACKS).map(entry =>
                    <NutritionDiaryItem entry={entry} handleRemoveNutritionDiaryEntry={handleRemoveNutritionDiaryEntry} />   
                )}
                <button 
                    className="w-full border-dashed border-2 border-gray-200 rounded-md p-4 flex items-center justify-center font-semibold bg-transparent transition-all hover:bg-gray-50"
                    onClick={() => setSelectedCategory(NutritionDiaryCategory.SNACKS)}    
                >
                    Add +
                </button>
            </div>
        </div>
        <RecipeSelectModal open={!!selectedCategory} onRecipeSelect={addToNutritionDiary} closeModal={() => setSelectedCategory(undefined)} />
        </>
    );
};