import { LiveWorkout, WORKOUT_DIFFICULTY, WORKOUT_LOCATION } from "../models/liveWorkout";
import { buildUrl, httpClient } from "./httpClient";
import { HttpClientResponse } from "./httpClient/httpClient";
import { ApiListResponse, ApiStandardResponse, mapHttpClientResponse, mapHttpListResponse } from "./httpClient/mapHttpClientResponse";

export const getLiveWorkouts = async (difficulty?: WORKOUT_DIFFICULTY[], muscleCategory?: number[], location?: WORKOUT_LOCATION[]): Promise<HttpClientResponse<LiveWorkout[]>> => {
    const query = [
        difficulty && `difficulty=${difficulty}`,
        muscleCategory && `muscleCategory=${muscleCategory}`,
        location && `location=${location}`
    ];
    const url = buildUrl(`/api/liveworkout?${query.join("&")}`);

    const response = await httpClient.getRequest<ApiListResponse<LiveWorkout>>(url);

    return mapHttpListResponse(response, m => m);
};

export const getLiveWorkout = async (id: string): Promise<HttpClientResponse<LiveWorkout>> => {
    const url = buildUrl(`/api/liveworkout/${id}`);

    const response = await httpClient.getRequest<ApiStandardResponse<LiveWorkout>>(url);

    return mapHttpClientResponse(response, m => m);
};