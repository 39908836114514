import { IApiRecipe, IApiRecipeResponse } from "./recipe";

export enum NutritionGoal {
    DEFICIT = 1,
    MAINTAIN = 2,
    SURPLUS = 3
}

export enum ActivityLevel {
    SEDENTARY = 1,
    LIGHT = 2,
    MODERATE = 3,
    EXTREME = 4
}

export interface CreateNutritionTargetDto {
    gender: string;
    dob: string;
    weight: number;
    height: number;
    activity: ActivityLevel;
    goal: NutritionGoal;
}

export interface IApiNutrition {
    nutrition: {
        user: string;
        kcal: number;
        protein: number;
        carbohydrates: number;
        fats: number;
        goal: NutritionGoal;
    },
    nutritionDiaryEntries: IApiNutritionDiaryEntry[]
}

export enum NutritionDiaryCategory {
    BREAKFAST = 1,
    LUNCH = 2,
    DINNER = 3,
    SNACKS = 4
}

export const mapNutritionDiaryCategoryToString = [
    "",
    "Breakfast",
    "Lunch",
    "Dinner",
    "Snacks",
];

export interface IApiNutritionDiaryEntry {
    _id: string;
    user: string;
    recipe?: IApiRecipeResponse;
    customRecipe?: string;
    date: Date;
    category: NutritionDiaryCategory;
    servings: number;
}

export interface IApiAddNutritionDiaryEntryDto {
    recipe?: string;
    customRecipe?: string;
    date: Date;
    category: NutritionDiaryCategory;
    servings: number;
}