import { WorkoutStats } from "../../api/models/workoutLog";

interface WorkoutStatsCardProps {
    stats: WorkoutStats
}

const WorkoutStatsCard: React.FC<WorkoutStatsCardProps> = ({
    stats: {
        totalWorkouts,
        avgVolumeLifted,
        avgWorkoutTime,
        totalPersonalBests
    }
}) => {
    return (
        <div className="p-6 border border-gray-200 w-full">
            <div className="flex justify-between mb-4">
                <div className="font-display font-black uppercase tracking-widest">This Week</div>
            </div>
            <div className="grid gap-4 grid-cols-2 md:grid-cols-4">
                <div className="flex flex-col justify-center items-center text-center bg-gray-50 py-6 px-2">
                    <div className="text-2xl md:text-4xl font-black">{totalWorkouts}</div>
                    <div className="font-black uppercase opacity-75">Workouts</div>
                </div>
                <div className="flex flex-col justify-center items-center text-center bg-gray-50 py-6 px-2">
                    <div className="text-2xl md:text-4xl font-black">{avgWorkoutTime ? avgWorkoutTime.toFixed(0) : 0}<span className="text-xl"> min</span></div>
                    <div className="font-black uppercase opacity-75">Avg. Duration</div>
                </div>
                <div className="flex flex-col justify-center items-center text-center bg-gray-50 py-6 px-2">
                    <div className="text-2xl md:text-4xl font-black">{avgVolumeLifted ? avgVolumeLifted.toFixed(1) : 0}<span className="text-xl">kg</span></div>
                    <div className="font-black uppercase opacity-75">Avg. Volume</div>
                </div>
                <div className="flex flex-col justify-center items-center text-center bg-gray-50 py-6 px-2">
                    <div className="text-2xl md:text-4xl font-black">{totalPersonalBests}</div>
                    <div className="font-black uppercase opacity-75">Personal Bests</div>
                </div>
            </div>
        </div>
    );
};

export default WorkoutStatsCard;