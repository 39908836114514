import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { getCustomRecipesPaginated, getRecipesPaginated } from "../../api/recipe";
import Pagination from "../../components/Pagination";
import { PublicLayout } from "../../layouts/PublicLayout";
import { RecipesFilter } from "./RecipesFilter";
import { RecipesList } from "./RecipesList";
import { mapNutritionDiaryCategoryToString } from "../../api/models/nutrition";
import { Button } from "../../components/Button";
import { Modal } from "../../components/Modal/Modal";
import { AddCustomRecipeForm } from "../../forms/AddCustomRecipeForm/AddCustomRecipeForm";

export interface FilterChangeTarget {
    property: 'category' | 'calorieRange' | 'location' | 'selection';
    value: number | number[] | string;
}

export const RecipesPage: React.FC = () => {
    const history = useHistory();
    const count = 15;
    const { search } = useLocation();
    const { page, category, min, max, selection: listSelection } = qs.parse(search);
    const [selection, setSelection] = useState<string>(typeof(listSelection) === "string" ? listSelection : "recipes");
    const [cat, setCat] = useState(Array.isArray(category) ? category.map(c => +c!) : typeof(category) === "string" ? [+category] : []);
    const [p, setP] = useState(page ? +page : 1);
    const [calorieRange, setCalorieRange] = useState([min ? +min : 0, max ? +max : 2500]);
    const [customMealModal, setCustomMealModal] = useState(false);

    const pushFilterParams = (page: number, category: number[], min: number, max: number, selection: string) => {
        setP(page);
        setCat(category);
        setCalorieRange([min, max]);
        setSelection(selection);
        const queryString = `?page=${page}&min=${min}&max=${max}&selection=${selection}&${category.map(c => `category=${c}&`)}`.replace(",", "").slice(0, -1);
        history.push(queryString);
    };

    const { isLoading, error, data: recipes = { recipes: [], totalPages: 0, totalResults: 0 }, refetch } = useQuery(`recipes/paginates?page=${p}&min=${calorieRange[0]}&max=${calorieRange[1]}&count=${count}&category=${cat}&selection=${selection}`, async () => { 
        let result;

        if(selection === "recipes") {
            result = await getRecipesPaginated(p - 1, count, cat.map(c => mapNutritionDiaryCategoryToString[c]), calorieRange[0], calorieRange[1]);
            
            if(!result.isError) {
                if(result.content.totalPages !== 0 && result.content.totalPages < p) {
                    pushFilterParams(result.content.totalPages, cat, calorieRange[0], calorieRange[1], selection);
                }
    
                if(result.content.totalPages === 0) {
                    pushFilterParams(1, cat, calorieRange[0], calorieRange[1], selection);
                }
                return result.content;
            }
        }

        if(selection === "meals") {
            result = await getCustomRecipesPaginated(p - 1, count, cat.map(c => mapNutritionDiaryCategoryToString[c]), calorieRange[0], calorieRange[1]);
            
            if(!result.isError) {
                if(result.content.totalPages !== 0 && result.content.totalPages < p) {
                    pushFilterParams(result.content.totalPages, cat, calorieRange[0], calorieRange[1], selection);
                }
    
                if(result.content.totalPages === 0) {
                    pushFilterParams(1, cat, calorieRange[0], calorieRange[1], selection);
                }
                return result.content;
            }
        }
   
    });

    const handleNextPage = () => {
        const newPage = p + 1;
        pushFilterParams(newPage, cat, calorieRange[0], calorieRange[1], selection);
    };

    const handlePrevPage = () => {
        const newPage = p - 1;
        pushFilterParams(newPage, cat, calorieRange[0], calorieRange[1], selection);
    };

    const handleFilterChange = (target: FilterChangeTarget) => {
        if(target.property === 'category' && typeof(target.value) === "number") {
            cat.includes(target.value) ? pushFilterParams(p, cat.filter(c => c !== target.value), calorieRange[0], calorieRange[1], selection) : pushFilterParams(p, [...cat, target.value], calorieRange[0], calorieRange[1], selection);
        }

        if(target.property === 'calorieRange' && Array.isArray(target.value)) {
            pushFilterParams(p, cat, target.value[0], target.value[1], selection);
        }

        if(target.property === 'selection' && typeof(target.value) === "string" && (target.value === "recipes" || target.value === "meals" || target.value === "favourites")) {
            console.log(target);
            pushFilterParams(p, cat, calorieRange[0], calorieRange[1], target.value);
        }
    };

    const handleCustomMealAddition = () => {
        setCustomMealModal(false);
        setSelection("meals");
    };

    return (
        <PublicLayout>
            <div className="px-6 py-24 bg-gray-50">
                <div className="w-full max-w-screen-xl m-auto">
                    <h1 className="text-4xl uppercase font-black font-display mb-4 tracking-widest text-center">Recipes</h1>
                    <p className="text-lg text-center max-w-3xl m-auto">Browse our range of healthy, protein packed recipes.</p>
                </div>
            </div>
            <div className="px-6 sm:px-12 py-8">
                <div className="w-full sm:flex">
                    <RecipesFilter 
                        handleFilterChange={handleFilterChange} 
                        selectedSelection={selection}
                        selectedCategory={cat} 
                        selectedLocation={[1]} 
                        selectedCalorieRange={calorieRange} />
                    <div className="flex-1 sm:px-8">
                        <div className="flex items-center">
                            <div className="font-display font-bold uppercase tracking-widest mb-2 opacity-60 text-xs sm:text-base">Showing {recipes.totalResults } results</div>
                            <div className="flex-none ml-auto">
                                <Button size="small" variant="gray" onClick={() => setCustomMealModal(true)}><span className="md:hidden">+</span><span className="hidden md:inline-block">Add Custom Meal</span></Button>
                            </div>
                            {/* <div className="-mt-2 flex items-center ml-auto">
                                <span className="font-display uppercase text-xs opacity-60 sm:text-sm">Sort by</span>
                                <select 
                                    className="font-display uppercase text-xs opacity-90 sm:text-sm" 
                                    style={{marginTop: '-2px'}}
                                    value={sortBy}
                                    onChange={(e) => setSortBy(parseInt(e.currentTarget.value))}>
                                    <option value={SORT_BY.LATEST}>Latest</option>
                                    <option value={SORT_BY.OLDEST}>Oldest</option>
                                </select>
                            </div> */}
                        </div>
                        <div className="flex-1">
                            <RecipesList recipes={recipes.recipes} isLoading={isLoading} />
                            <Pagination page={p} totalPages={recipes.totalPages} hasPrev={p > 1} hasNext={p < recipes.totalPages} onNext={handleNextPage} onPrev={handlePrevPage} />
                        </div>
                    </div>
                </div>
            </div>
            <Modal title="Add a custom meal" isOpen={customMealModal} closeModal={() => setCustomMealModal(false)}>
                <AddCustomRecipeForm callback={handleCustomMealAddition} />
            </Modal>
        </PublicLayout>
    );
};