export const workoutDifficultyMap = ["Beginner", "Intermediate", "Advanced"];
export const workoutLocationMap = ["Gym", "Home"];

export enum WORKOUT_DIFFICULTY {
    BEGINNER,
    INTERMEDIATE,
    ADVANCED
}

export enum WORKOUT_LOCATION {
    GYM,
    HOME
}

export interface LiveWorkout {
    _id?: string;
    name: string;
    description: string;
    duration: number;
    muscleCategory: number;
    difficulty: WORKOUT_DIFFICULTY;
    image: string;
    video: string;
    previewVideo?: string;
    requiresPremium: boolean;
    location: WORKOUT_LOCATION;
    createdAt: string;
}