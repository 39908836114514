import { useParams } from "react-router-dom";
import ProgramEditor from "../../../components/ProgramEditor";
import { PublicLayout } from "../../../layouts/PublicLayout";

interface Params {
    id: string;
}

export const EditWorkoutProgramPage: React.FC = () => {
    const { id } = useParams<Params>(); 
    
    return (
        <PublicLayout>
            <div className="px-6 py-24 bg-gray-50">
                <div className="w-full max-w-screen-xl m-auto">
                    <h1 className="text-4xl uppercase font-black font-display mb-4 tracking-widest text-center">Build Program</h1>
                    <p className="text-lg text-center max-w-3xl m-auto">Build workout program</p>
                </div>
            </div>
            <div className="py-8">
                {id && 
                    <ProgramEditor 
                        programId={id} />
                }
            </div>
        </PublicLayout>
    );
};