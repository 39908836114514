import { FiWind } from "react-icons/fi";
import { Button } from "../../../components/Button";
import { AppPathsLoggedIn } from "../../../routes/AppRoutes";

export const LogbookEmptyState: React.FC = () => {
    return (
        <div className="p-6 flex flex-col items-center justify-center text-center">
            <FiWind className="w-12 h-12 mb-4 text-gray-600" />
            <h1 className="text-2xl font-medium text-gray-700 mb-2">No logs found</h1>
            <p className="mb-6">You do not have any logs for the selected date period</p>
        </div>
    );
}