import React, { useEffect, useState } from "react";
import { Input } from "./Input";

interface ListInputProps {
    value?: string[];
    onChange: (val: string[]) => void;
}

export const ListInput: React.FC<ListInputProps> = ({
    value = [],
    onChange
}) => {
    const [items, setItems] = useState(value);
    const [itemInputValue, setItemInputValue] = useState("");

    const handleAddItem = () => {
        if(itemInputValue.trim() !== "") {
            const alteredList = [...items, itemInputValue];

            setItems(alteredList);
            setItemInputValue("");
            onChange(alteredList);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === "Enter") {
            e.preventDefault();
            handleAddItem();
        }
    };

    const handleRemoveItem = (index: number) => {
        const alteredList = items.filter((_, i) => i !== index);
        setItems(alteredList);
        onChange(alteredList);
    };

    return (
        <div className="w-full">
            <div className="relative mb-1">
                <Input type='text' value={itemInputValue} onChange={(e) => setItemInputValue(e.currentTarget.value)} onKeyDown={handleKeyPress} />
                <button className="absolute top-0 right-0 w-6 h-full flex items-center font-bold" onClick={handleAddItem} type="button">+</button>
            </div>
            <ul>
                {items.map((item, i) => 
                    <li className="p-2 border border-gray-200 mb-1 relative pr-6" key={i}>
                        {item}
                        <button className="absolute right-0 top-0 h-full w-6 flex items-center justify-center" type="button" onClick={() => handleRemoveItem(i)}>X</button>
                    </li>
                )}
            </ul>
        </div>
    );
};