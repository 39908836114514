import { useState } from "react";
import { BiMenu } from "react-icons/bi";
import { Link } from "react-router-dom";
import { NavBarItemsLoggedIn, NavBarItemsLoggedOut, NavBarMobileMenu } from ".";
import { useAuth } from "../../auth/AuthContext";

export const NavBar: React.FC = () => {
  const { isLoggedIn } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);

    return (
      <>
      <div className="fixed top-0 left-0 bg-white z-50 w-full flex h-20 items-center px-4 shadow-lg sm:px-8">
        <Link to="/" className="font-display font-black text-2xl uppercase sm:text-3xl">Stronger <span className="font-normal">You</span></Link>
        <div className="hidden lg:flex lg:ml-auto">
          {!isLoggedIn && <NavBarItemsLoggedOut />}
          {isLoggedIn && <NavBarItemsLoggedIn />}
        </div>
        <button className="w-12 h-12 rounded-m bg-gray-50 flex items-center justify-center ml-auto lg:hidden" onClick={() => setMenuOpen(true)}>
          <BiMenu />
        </button>
      </div>
      <NavBarMobileMenu open={menuOpen} closeMenu={() => setMenuOpen(false)} />
      </>
    );
};