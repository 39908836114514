import clsx from "clsx";
import { FiPlus } from "react-icons/fi";
import { useExercise } from "../../exercise/ExerciseContext";
import { useProgramEditor } from "./ProgramEditorContext/ProgramEditorContext";

interface Props {
    dayOfWeekNo: number;
    programWeekId: string;
}

export const ProgramWeekDay: React.FC<Props> = ({
    dayOfWeekNo,
    programWeekId
}) => {

    const { getExerciseById } = useExercise();
    const { getProgramDay, editProgramDay } = useProgramEditor();

    const programDay = getProgramDay(dayOfWeekNo, programWeekId);

    const handleDayEdit = () => {
        editProgramDay(dayOfWeekNo, programWeekId);
    };

    return (
        <div className={clsx(
            "relative group w-full h-20 md:w-1/7 md:h-80 p-4 overflow-hidden cursor-pointer hover:bg-white hover:h-full md:hover:h-80 md:hover:w-72 hover:text-gray-800 text-gray-400 border-b md:border-b-0 md:border-r transition-all",
            dayOfWeekNo === 6 && "border-none")}
            onClick={handleDayEdit}>
            <div className="font-medium text-sm">Day {dayOfWeekNo + 1}</div>
            <div className="text-sm opacity-70">{programDay?.name}</div>
            <div className="w-72 h-full mt-2">
                {programDay?.workoutSteps.map(workoutStep => 
                    <div className="mb-2 text-sm" key={`brief-view-${workoutStep.workoutStepId}`}>
                        <div className="font-medium">{getExerciseById(workoutStep.exercise)?.name}</div>
                        <div className="-mt-1">{workoutStep.sets.length} sets</div>
                    </div>  
                )}
                <div className="absolute bottom-4 right-4 md:right-auto md:left-4 w-10 h-10 bg-gray-100 flex items-center justify-center rounded-md transition-all group-hover:bg-blue-500 group-hover:text-white">
                    <FiPlus />
                </div>
            </div>
            {dayOfWeekNo !== 6 &&
                <div className="absolute bottom-0 md:bottom-auto md:top-0 right-0 h-8 w-full md:w-8 md:h-full bg-gradient-to-t md:bg-gradient-to-l from-gray-50 group-hover:opacity-80"></div>            
            }
        </div>
    );
};