import { ForgottenPasswordForm } from "../../forms/ForgottenPasswordForm";
import { PublicLayout } from "../../layouts/PublicLayout";

export const ForgottenPasswordPage: React.FC = () => {
    return (
        <PublicLayout>
            <div className="px-6 py-12">
                <div className="w-full max-w-lg m-auto">
                <h2 className="text-4xl uppercase font-black font-display mb-4 tracking-widest text-center">Forgotten Password</h2>
                <p className="text-lg text-center m-auto mb-8">Enter your email address below to reset your password.</p>
                <ForgottenPasswordForm />
                </div>
            </div>
        </PublicLayout>
    );
};