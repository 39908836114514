export const LiveWorkoutDetailSkeleton: React.FC = () => {
    return (
        <div className="grid gap-4 sm:grid-cols-2 sm:gap-12 mb-16">
            <div className="h-80 skeleton-box"></div>
            <div className="w-full relative flex flex-col">
                <div className="w-56 h-6 skeleton-box mb-2"></div>
                <div className="w-full h-12 skeleton-box mb-2"></div>
                <div className="w-56 h-6 skeleton-box mb-2"></div>
                <div className="w-56 h-6 skeleton-box mb-2"></div>
                <div className="w-56 h-6 skeleton-box mb-2"></div>
                <div className="w-56 h-6 skeleton-box mb-8"></div>
                <div className=" w-80 h-12 skeleton-box mb-16"></div>
                <div className="w-56 h-6 skeleton-box mb-2"></div>
                <div className="w-full h-6 skeleton-box mb-2"></div>
                <div className="w-full h-6 skeleton-box mb-2"></div>
                <div className="w-full h-6 skeleton-box mb-2"></div>
                <div className="w-32 h-6 skeleton-box mb-2"></div>
            </div>
        </div>
    );
};