import { buildUrl, httpClient } from "./httpClient";
import { HttpClientResponse } from "./httpClient/httpClient";
import { ApiListResponse, ApiStandardResponse, mapHttpClientResponse, mapHttpListResponse } from "./httpClient/mapHttpClientResponse";
import { NutritionDiaryCategory } from "./models/nutrition";
import { IApiPaginatedRecipeResponse, IApiRecipe, IApiRecipeResponse } from "./models/recipe";


export const getRecipes = async (): Promise<HttpClientResponse<IApiRecipeResponse[]>> => {
    const url = buildUrl('/api/recipe/all');

    const response = await httpClient.getRequest<ApiListResponse<IApiRecipeResponse>>(url);
    
    return mapHttpListResponse(response, m => m);
};

export const getRecipesPaginated = async (page: number, count: number, category: string[], min: number, max: number): Promise<HttpClientResponse<IApiPaginatedRecipeResponse>> => {
    const urlQuery = `/api/recipe/paginated?page=${page}&min=${min}&max=${max}&count=${count}&${category.map(c => `category=${c}&`)}`.replaceAll(",", "").slice(0, -1);
    const url = buildUrl(urlQuery);

    const response = await httpClient.getRequest<ApiStandardResponse<IApiPaginatedRecipeResponse>>(url);
    
    return mapHttpClientResponse(response, m => m);
};

export const getCustomRecipesPaginated = async (page: number, count: number, category: string[], min: number, max: number): Promise<HttpClientResponse<IApiPaginatedRecipeResponse>> => {
    const urlQuery = `/api/recipe/custom/paginated?page=${page}&min=${min}&max=${max}&count=${count}&${category.map(c => `category=${c}&`)}`.replaceAll(",", "").slice(0, -1);
    const url = buildUrl(urlQuery);

    const response = await httpClient.getRequest<ApiStandardResponse<IApiPaginatedRecipeResponse>>(url);
    
    return mapHttpClientResponse(response, m => m);
};

export const getRecipeDetail = async (id: string): Promise<HttpClientResponse<IApiRecipeResponse>> => {
    const url = buildUrl(`/api/recipe/detail/${id}`);

    const response = await httpClient.getRequest<ApiStandardResponse<IApiRecipeResponse>>(url);
    
    return mapHttpClientResponse(response, m => m);
};