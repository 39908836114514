import clsx from 'clsx';
import { useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import SwipeableViews from 'react-swipeable-views';
import { useExercise } from "../../exercise/ExerciseContext";
import { Modal } from '../Modal/Modal';

interface WorkoutLoggerExerciseInformationProps {
    open: boolean;
    closeModal(): void;
    exercises: string[];
}

interface ExerciseInformationContentProps {
    exercise: string;
}

const ExerciseInformationContent: React.FC<ExerciseInformationContentProps> = ({
    exercise
}) => {
    const { getExerciseById, getExerciseVideoById } = useExercise();

    const exerciseDetails = getExerciseById(exercise);
    const exerciseVideo = getExerciseVideoById(exercise);

    return (
        <div className='h-full'>
            <h2 className='font-medium text-center mb-4'>{exerciseDetails?.name}</h2>
            {exerciseVideo &&
                <div className="relative w-full h-full mb-2">
                    <ReactPlayer style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} width="100%" height="100%" url={exerciseVideo.url} controls light />
                </div>
            }
            <ul className='text-sm list-outside ml-4'>
                {exerciseDetails?.instructions.map((instruction, i) =>
                    instruction !== "" && <li className='list-disc' key={`instruction-${i}`}>{instruction}</li>
                )}
            </ul>
        </div>
    );
};

export const WorkoutLoggerExerciseInformation: React.FC<WorkoutLoggerExerciseInformationProps> = ({
    open,
    closeModal,
    exercises
}) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    return (
        <Modal isOpen={open} closeModal={closeModal}>
            {exercises.length > 1 &&
                <div className="flex items-center justify-center mb-4">
                    {exercises.map((e, i) =>
                        <div className={clsx('w-2 h-2 mx-1 rounded-full bg-blue-200', currentIndex === i && "bg-blue-500")}></div>
                    )}
                </div>
            }
            <SwipeableViews enableMouseEvents onChangeIndex={setCurrentIndex}>
                {exercises.map((exercise, i) => <ExerciseInformationContent exercise={exercise} key={`${exercise}-${i}`} />)}
            </SwipeableViews>
        </Modal>
    );
};