import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

interface PaginationProps {
    page: number;
    hasNext: boolean;
    hasPrev: boolean;
    totalPages: number;
    onNext(): void;
    onPrev(): void;
}

export const Pagination: React.FC<PaginationProps> = ({
    page,
    hasNext,
    hasPrev,
    totalPages,
    onNext,
    onPrev
}) => {
    return (
        <div className="flex justify-center">
            <button
                className="bg-black p-4 rounded-md text-white" 
                disabled={!hasPrev} onClick={onPrev}>
                    <FiChevronLeft />
            </button>
            <span className="flex items-center font-bold px-8">{page} / {totalPages}</span>
            <button
                className="bg-black p-4 rounded-md text-white" 
                disabled={!hasNext} onClick={onNext}>
                    <FiChevronRight />
            </button>
        </div>
    );
};