import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { cancelClientProgram, getUpcomingClientPrograms, updateClientProgram } from "../../../api/program";
import { AppPathsPublic } from "../../../routes/AppRoutes";
import { Image } from "../../../components/Image";
import { config } from "../../../config";
import { Button } from "../../../components/Button";
import { DateTime } from "luxon";
import { Menu, Transition } from "@headlessui/react";
import { FiCalendar, FiMoreVertical, FiTrash2 } from "react-icons/fi";
import { Fragment, useState } from "react";
import { Modal } from "../../../components/Modal/Modal";
import { DayPicker } from "react-day-picker";
import { toast } from "react-toastify";

interface DashboardUpcomingProgramProps {
    refetchActiveProgram(): void;
}

export const DashboardUpcomingProgram: React.FC<DashboardUpcomingProgramProps> = ({
    refetchActiveProgram
}) => {
    const { apiUri } = config;
    const [rescheduleProgramId, setRescheduleId] = useState<string>();
    const [cancelProgramId, setCancelProgramId] = useState<string>();
    const [rescheduleStartDate, setRescheduleStartDate] = useState<Date>();
    
    const { isLoading, error, data: scheduleData, refetch } = useQuery(`schedule-upcoming`, async () => { 
        const result = await getUpcomingClientPrograms();
        if(!result.isError) {
            return result.content;
        }   
    });

    const handleCloseModal = () => {
        setRescheduleId(undefined);
        setRescheduleStartDate(undefined);
    };

    const handleRescheduleProgram = async () => {
        if(rescheduleProgramId) {
            const result = await updateClientProgram(rescheduleProgramId, {
                startDate: rescheduleStartDate?.toISOString()
            });

            if(!result.isError) {
                refetch();
                refetchActiveProgram();
                handleCloseModal();
            }
        }
    };

    const handleCancelProgram = async () => {
        if(cancelProgramId) {
            const result = await cancelClientProgram(cancelProgramId);

            if(!result.isError) {
                setCancelProgramId(undefined);
                refetch();
                toast.info("program has been cancelled.");
            }
        }
    };

    return (
        <>
        <div className="">
            <h2 className="text-xl uppercase font-black font-display mb-4 tracking-wide">Upcoming</h2>
            {isLoading || scheduleData?.clientPrograms.length === 0 &&
                <div className="border border-gray-200 p-6 flex items-center justify-center h-80 mb-6">
                    <div className="flex flex-col items-center w-full">
                        {isLoading &&
                            <div>Loading...</div>
                        }
                        {!isLoading && scheduleData?.clientPrograms.length === 0 &&
                            <div className="font-semibold">No upcoming programs scheduled</div>
                        }
                    </div>
                </div>
            }
            {!isLoading && scheduleData?.clientPrograms && scheduleData?.clientPrograms.length > 0  &&
                <div className="border border-gray-200 p-6 flex flex-col h-80 mb-6">
                    {scheduleData?.clientPrograms.map(clientProgram => {
                            const program = scheduleData.programs.find(p => p._id === clientProgram.program);
                            
                            if(program) {
                                return (
                                    <div key={clientProgram._id} className="w-full relative">
                                        <div className="w-full flex">
                                            <div className="bg-gray-200 portrait relative transition-opacity w-20 flex-none hover:opacity-80">
                                                <Image className="object-cover w-full h-full" src={`${apiUri}/api/file/${program.image}`} />
                                            </div>
                                            <div className="ml-4 flex flex-col">
                                                <div className="font-bold text-sm uppercase tracking-wide mb-2 sm:text-base">{program.name}</div>
                                                <p className="text-sm sm:text-base mb-4"><span className="font-semibold">Start date: </span>{DateTime.fromISO(clientProgram.startDate).toFormat("dd MMMM yyyy")}</p>
                                                <Button link={AppPathsPublic.programDetail(program._id)} size="small">Details</Button>
                                            </div>
                                        </div>
                                        <Menu as="div" className="absolute top-0 right-0 inline-block text-left">
                                            <div>
                                            <Menu.Button className="w-12 h-12 rounded-full flex items-center justify-center transition-all hover:bg-gray-50">
                                                <FiMoreVertical
                                                    className="w-5 h-5"
                                                    aria-hidden="true"
                                                    />
                                                <span className="sr-only">Options</span>
                                            </Menu.Button>
                                            </div>
                                            <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                            >
                                            <Menu.Items className="absolute z-20 right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="px-1 py-1 ">
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                        <button className="flex rounded-md items-center w-full px-2 py-2 text-sm cursor-pointer" onClick={() => setRescheduleId(clientProgram._id)}>
                                                            <FiCalendar 
                                                                className="w-5 h-5 mr-2"
                                                                aria-hidden="true" 
                                                            />
                                                            <label className='flex items-center cursor-pointer w-full'>
                                                                Reschedule
                                                            </label>
                                                        </button>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                        <button className="flex rounded-md items-center w-full px-2 py-2 text-sm cursor-pointer" onClick={() => setCancelProgramId(clientProgram._id)}>
                                                            <FiTrash2 
                                                                className="w-5 h-5 mr-2"
                                                                aria-hidden="true" 
                                                            />
                                                            <label className='flex items-center cursor-pointer w-full'>
                                                                Cancel program
                                                            </label>
                                                        </button>
                                                        )}
                                                    </Menu.Item>
                                                </div>
                                            </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div> 
                                );
                            }  
                        })}
                </div>
            }
        </div>
        <Modal isOpen={!!rescheduleProgramId} closeModal={handleCloseModal} title="Select a new start date">
            <DayPicker mode="single" onDayClick={(day: Date) => setRescheduleStartDate(day)} selected={rescheduleStartDate} />
            <Button disabled={!rescheduleStartDate} onClick={handleRescheduleProgram}>Submit</Button>
        </Modal>
        <Modal isOpen={!!cancelProgramId} closeModal={() => setCancelProgramId(undefined)} title="Cancel Program">
            <p className="mb-8">Are you sure you want to cancel this upcoming program?</p>
            <div className="grid grid-cols-2 gap-4">
                <Button type="button" variant="transparent" onClick={() => setCancelProgramId(undefined)}>No</Button>
                <Button onClick={handleCancelProgram}>Yes</Button>
            </div>
        </Modal>
        </>
    );
};