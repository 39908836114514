import { Link } from "react-router-dom";
import { Image } from "../../components/Image";
import { config } from "../../config";
import { LiveWorkout } from "../../models/liveWorkout";
import { AppPathsPublic } from "../../routes/AppRoutes";

interface LiveWorkoutsListProps {
    liveWorkouts: LiveWorkout[];
    isLoading: boolean;
}

export const LiveWorkoutsList: React.FC<LiveWorkoutsListProps> = ({
    liveWorkouts,
    isLoading
}) => {
    const { apiUri } = config;

    return (
        <div className="grid grid-cols-2 gap-6 2xl:gap-12 mt-6 mb-12 sm:grid-cols-3 sm:mt-12">
            {isLoading &&
                <>
                    <div className="h-56 bg-gray-200"></div>
                    <div className="h-56 bg-gray-200"></div>
                    <div className="h-56 bg-gray-200"></div>
                    <div className="h-56 bg-gray-200"></div>
                </>
            }
            {!isLoading && liveWorkouts.length === 0 &&
                <div className="col-span-full flex h-64 items-center justify-center font-bold text-lg">
                    No live workouts found matching this filter criteria.
                </div>
            }
            {!isLoading && liveWorkouts.map(liveWorkout =>
                <Link to={AppPathsPublic.liveWorkoutDetail(liveWorkout._id ?? '')} className="relative transition-opacity hover:opacity-80">
                    <Image src={`${apiUri}/api/file/${liveWorkout.image}`} className="" />
                    <div className="mt-4">
                        <div className="font-bold text-sm uppercase tracking-wide mb-2 sm:text-base">{liveWorkout.name}</div>
                        {/* <p className="text-sm sm:text-base">{liveWorkout.description}</p> */}
                    </div>
                </Link>
            )}
        </div>
    );
};