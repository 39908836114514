import { useEffect, useState } from "react";
import { IUser, USER_ROLES } from "../models/user";
import { AuthContext } from "./AuthContext";
import authClient from "./authClient";
import { useHistory } from "react-router-dom";

export const AuthProvider: React.FC = ({
    children
}) => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState<IUser>();

    useEffect(() => {
        const session = authClient.loadSession();

        console.log(session.isLoggedIn);

        if(session.isLoggedIn) {
            console.log("here some how");
            setIsLoggedIn(true);
            setUser(session.user);
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        if(user) {
            authClient.updateUser(user);
        }
    }, [user]);

    const handleLogIn = async (email: string, password: string) => {
        const authResult = await authClient.logIn(email, password);

        if(!authResult.isError) {
            setUser(authResult.user);
            setIsLoggedIn(true);
        }

        return authResult;
    };

    const handleAutoLogin = async (accessToken: string, refreshToken: string, user: IUser) => {
        sessionStorage.setItem('accessToken', accessToken);
        sessionStorage.setItem('refreshToken', refreshToken);
        sessionStorage.setItem('user', JSON.stringify(user));
        console.log("here 3");

        setIsLoggedIn(true);
        setUser(user);
    };

    const handleLogOut = async () => {
        const logoutResult = await authClient.logOut();

        if(!logoutResult.isError) {
            setUser(undefined);
            setIsLoggedIn(false);
            history.push("/");
        }
    };

    return (
        <AuthContext.Provider value={{
            logIn: handleLogIn,
            logOut: handleLogOut,
            setUser,
            handleAutoLogin,
            isLoggedIn,
            isLoading,
            isAdmin: user?.role === USER_ROLES.ADMIN,
            isPremium: user?.role !== USER_ROLES.FREE,
            user: user
        }}>
            {/* {isLoading ? <FullScreenLoader /> : children} */}
            {children}
        </AuthContext.Provider>
    );
};