import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, boolean, number, array, date } from 'yup';
import { WORKOUT_DIFFICULTY, WORKOUT_LOCATION } from "../../api/models/program";
import { SmartInput, SmartListInput, SmartSelect, SmartTextArea } from '../../components/SmartForm';
import { Button } from '../../components/Button';
import { useMutation } from 'react-query';
import { createWorkoutProgram } from '../../api/program';
import { useHistory } from 'react-router-dom';
import { AppPathsAdmin } from '../../routes/AppRoutes';
import { SmartImageInput } from '../../components/SmartForm/SmartImageInput';
import { ChangeEvent, useEffect, useState } from 'react';
import { NutritionDiaryCategory } from '../../api/models/nutrition';
import { createCustomRecipe, createRecipe } from '../../api/nutrition';
import { ListInput } from '../../components/Inputs/ListInput';
import { toast } from 'react-toastify';

export interface AddRecipeFormInputs {
    name: string;
    ingredients: string[];
    directions: string[];
    kcals: number;
    protein: number;
    carbohydrates: number;
    fats: number;
    time: number;
    prepTime: number;
    servings: number;
    image: string;
    category: NutritionDiaryCategory;
    tips?: string[];
    premium: boolean;
}

interface AddRecipeFormProps {
    defaultValues?: AddRecipeFormInputs;
    callback?: () => void;
}

const schema = object({
        name: string().required('Recipe name is required'),
        ingredients: array().of(string()).notRequired(),
        directions: array().of(string()).notRequired(),
        kcals: number().required('Recipe kcals are required'),
        protein: number().required('Recipe protein is required'),
        carbohydrates: number().required('Recipe carbohydrates are required'),
        fats: number().required('Recipe fats are required'),
        time: number().notRequired(),
        prepTime: number().notRequired(),
        servings: number().required('Recipe servings is required'),
        category: string().required('Recipe categories are required'),
        tip: string().notRequired(),
        premium: boolean().notRequired()
}).required();

const recipeCategoryOptions = [
    {
        value: "Breakfast",
        label: 'Breakfast'
    },
    {
        value: "Lunch",
        label: 'Lunch'
    },
    {
        value: "Dinner",
        label: 'Dinner'
    },
    {
        value: "Snacks",
        label: 'Snack'
    },
];

export const AddCustomRecipeForm: React.FC<AddRecipeFormProps> = ({
    defaultValues,
    callback
}) => {
    const history = useHistory();
    const [image, setImage] = useState<File>();

    const { register, watch, handleSubmit, setValue, formState: { errors }, getValues } = useForm<AddRecipeFormInputs>({
        resolver: yupResolver(schema),
        defaultValues
    });

    const ingredients = watch("ingredients", []);
    const directions = watch("directions", []);

    const addRecipeRequest = useMutation(`${new Date()}-create-recipe`, async (values: AddRecipeFormInputs) => {
        const result = defaultValues ? await createCustomRecipe(values) : await createCustomRecipe(values);
        
        if(!result.isError) {
            toast.success(defaultValues ? "Recipe has been updated!" : "Recipe has been created!");
            // history.push(AppPathsAdmin.editWorkoutProgram(result.content._id));
            callback && callback();

        }
    });

    const onSubmit = (values: AddRecipeFormInputs) => {
        console.log(values);

        addRecipeRequest.mutate(values);
    };

    const handleProgramImageSelect = (e: ChangeEvent<HTMLInputElement>) => {
        if(e.currentTarget.files && e.currentTarget.files[0]) {
            setImage(e.currentTarget.files[0]);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col h-full justify-center items-center'>
            <div className="relative w-full max-w-lg">
                <div className="w-full mb-4">
                    <SmartInput register={register('name')} placeholder='Recipe name' label='Recipe name' error={errors.name?.message} />
                </div>
                <div className="w-full mb-4">
                    <SmartInput register={register('kcals')} type='number' step={0.01} placeholder='Kcals' label='Kcals' error={errors.kcals?.message} />
                </div>
                <div className="w-full mb-4">
                    <SmartInput register={register('protein')} type='number' step={0.01} placeholder='Protein' label='Protein' error={errors.protein?.message} />
                </div>
                <div className="w-full mb-4">
                    <SmartInput register={register('carbohydrates')} type='number' step={0.01} placeholder='Carbohydrates' label='Carbohydrates' error={errors.carbohydrates?.message} />
                </div>
                <div className="w-full mb-4">
                    <SmartInput register={register('fats')} type='number' step={0.01} placeholder='Fats' label='Fats' error={errors.fats?.message} />
                </div>
                <div className="w-full mb-4">
                    <SmartInput register={register('servings')} type='number' step={0.01} placeholder='Servings' label='Servings' error={errors.servings?.message} />
                </div>
                <div className="w-full mb-4">
                    <SmartSelect 
                        register={register('category')} 
                        error={errors.category?.message}
                        label='Select a category'
                        defaultOption='Select a category'
                        options={recipeCategoryOptions} />
                </div>
            </div>
            <div className='mt-4 w-full max-w-lg'>
                <Button type='submit'>Add Custom Meal</Button>
            </div>
        </form>
    );
};