import { useState } from "react";
import { useQuery } from "react-query";
import { mapNutritionDiaryCategoryToString, NutritionDiaryCategory } from "../../api/models/nutrition";
import { IApiRecipeResponse } from "../../api/models/recipe";
import { getRecipes, getRecipesPaginated } from "../../api/recipe";
import config from "../../config/config";
import { FilterChangeTarget } from "../../pages/Recipes/Recipes";
import { RecipesFilter } from "../../pages/Recipes/RecipesFilter";
import { Button } from "../Button";
import { Image } from "../Image";
import { Input } from "../Inputs/Input";
import { Modal } from "../Modal/Modal";
import Pagination from "../Pagination";
import { RecipeSelectFilter } from "./RecipeSelectFilter";

interface RecipeSelectModalProps {
    open: boolean;
    onRecipeSelect(recipeId: string, servings: number): void;
    closeModal(): void;
}

export const RecipeSelectModal: React.FC<RecipeSelectModalProps> = ({
    open,
    onRecipeSelect,
    closeModal
}) => {
    const { apiUri } = config;
    const count = 15;
    const [searchQuery, setSearchQuery] = useState("");
    const [category, setCategory] = useState<NutritionDiaryCategory[]>([]);
    const [selectedRecipe, setSelectedRecipe] = useState<IApiRecipeResponse>();
    const [servings, setServings] = useState(1);
    const [p, setP] = useState(1);
    const [calorieRange, setCalorieRange] = useState([0, 2500]);

    // const { isLoading, error, data: recipes, refetch } = useQuery(`recipes/all`, async () => { 
    //     const result = await getRecipes();

    //     if(!result.isError) {
    //         return result.content;
    //     }   
    // });
    const { isLoading, error, data: recipes = { recipes: [], totalPages: 0, totalResults: 0 }, refetch } = useQuery(`recipes/paginates?page=${p}&min=${calorieRange[0]}&max=${calorieRange[1]}&count=${count}&category=${category}`, async () => { 
        
        const result = await getRecipesPaginated(p - 1, count, category.map(c => mapNutritionDiaryCategoryToString[c]), calorieRange[0], calorieRange[1]);

        if(!result.isError) {
            if(result.content.totalPages !== 0 && result.content.totalPages < p) {
                setP(result.content.totalPages);
            }

            if(result.content.totalPages === 0) {
                setP(1);
            }
            return result.content;
        }   
    });

    const handleRecipeSelection = () => {
        if(selectedRecipe) {
            const recipeId = selectedRecipe._id;
            const servingMultiplier = servings;
    
            setSelectedRecipe(undefined);
            setServings(1);
            onRecipeSelect(recipeId, servingMultiplier);
        }
    };

    const handleFilterChange = (target: FilterChangeTarget) => {
        if(target.property === 'category' && typeof(target.value) === "number") {
            setP(1);
            category.includes(target.value) ? setCategory(category.filter(c => c !== target.value)) : setCategory([...category, target.value]);
        }

        if(target.property === 'calorieRange' && Array.isArray(target.value)) {
            setP(1);
            setCalorieRange([target.value[0], target.value[1]]);
        }
    };

    const handleNextPage = () => {
        const newPage = p + 1;
        setP(newPage);
    };

    const handlePrevPage = () => {
        const newPage = p - 1;
        setP(newPage);
    };

    // const filteredRecipes = recipes.recipes?.filter(r => r.name.toLowerCase().includes(searchQuery.toLowerCase()));

    return (
            <Modal isOpen={open} closeModal={closeModal}>
                <div className="flex items-center justify-between border-b border-gray-200 pb-2 mb-4">
                    <h2 className="font-display font-bold uppercase tracking-widest mb-2 text-xs sm:text-base">Recipe selection</h2>
                    <div className="font-display font-bold uppercase tracking-widest mb-2 opacity-60 text-xs sm:text-base">Showing {recipes.totalResults } results</div>
                </div>
                <div className="relative h-full w-full sm:flex">
                    <RecipeSelectFilter handleFilterChange={handleFilterChange} selectedCategory={category} selectedLocation={[1]} selectedCalorieRange={calorieRange} />
                    <div className="flex-1 sm:px-8 h-full max-h-modal-overflow overflow-auto">
                        <div className="grid grid-cols-2 gap-4 mt-4 md:grid-cols-3">
                            {isLoading &&
                                <>
                                    <div className="h-56 bg-gray-200"></div>
                                    <div className="h-56 bg-gray-200"></div>
                                    <div className="h-56 bg-gray-200"></div>
                                    <div className="h-56 bg-gray-200"></div>
                                </>
                            }
                            {!isLoading && recipes.recipes?.map(recipe =>
                                <button onClick={() => setSelectedRecipe(recipe)} className="relative flex flex-col transition-opacity hover:opacity-80" key={recipe._id}>
                                    <div className="bg-gray-200 w-full h-40 md:h-48 relative transition-opacity hover:opacity-80">
                                        <Image src={`${apiUri}/api/file/${recipe.image}`} className="w-full h-full object-fit object-cover" />
                                    </div>
                                    <div className="mt-4">
                                        <div className="font-bold text-sm uppercase text-left tracking-wide mb-2 sm:text-base">{recipe.name}</div>
                                        <div className="flex gap-4">
                                            <div className="flex items-center gap-2">
                                                <div className="w-4 h-4 rounded-full bg-yellow-500"></div>
                                                <div className=" text-sm">{recipe.kcals} kcal</div>
                                            </div>
                                            <div className="hidden sm:flex items-center gap-2">
                                                <div className="w-4 h-4 rounded-full bg-pink-500"></div>
                                                <div className=" text-sm">{recipe.protein}g</div>
                                            </div>
                                            <div className="hidden sm:flex items-center gap-2">
                                                <div className="w-4 h-4 rounded-full bg-blue-500"></div>
                                                <div className=" text-sm">{recipe.carbohydrates}g</div>
                                            </div>
                                            <div className="hidden sm:flex items-center gap-2">
                                                <div className="w-4 h-4 rounded-full bg-green-500"></div>
                                                <div className=" text-sm">{recipe.fats}g</div>
                                            </div>
                                        </div>
                                    </div>
                                </button>    
                            )}
                        </div>
                        <div className="mb-8 mt-4">
                            <Pagination totalPages={recipes.recipes.length} page={p} hasPrev={p > 1} hasNext={p < recipes.totalPages} onNext={handleNextPage} onPrev={handlePrevPage} />
                        </div>
                    </div>
                </div>
                <Modal isOpen={!!selectedRecipe} closeModal={() => setSelectedRecipe(undefined)} title={selectedRecipe?.name}>
                    {selectedRecipe &&
                    <>
                        <div className="flex gap-4 mb-4">
                            <div className="flex items-center gap-2">
                                <div className="w-4 h-4 rounded-full bg-yellow-500"></div>
                                <div className=" text-sm">{selectedRecipe.kcals * servings} kcal</div>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="w-4 h-4 rounded-full bg-pink-500"></div>
                                <div className=" text-sm">{selectedRecipe.protein * servings}g</div>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="w-4 h-4 rounded-full bg-blue-500"></div>
                                <div className=" text-sm">{selectedRecipe.carbohydrates * servings}g</div>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="w-4 h-4 rounded-full bg-green-500"></div>
                                <div className=" text-sm">{selectedRecipe.fats * servings}g</div>
                            </div>
                        </div>
                        <label>Servings:</label>
                        <div className="mb-4">
                            <Input type="number" step={0.00} value={servings} onChange={(e) => setServings(+e.currentTarget.value)} />
                        </div>
                        <Button onClick={handleRecipeSelection}>Add</Button>
                    </>
                    }
                </Modal>
            </Modal>
    );
};