import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, boolean, number, array, date } from 'yup';
import { WORKOUT_DIFFICULTY, WORKOUT_LOCATION } from "../../../api/models/program";
import { SmartInput, SmartListInput, SmartSelect, SmartTextArea } from '../../../components/SmartForm';
import { Button } from '../../../components/Button';
import { useMutation } from 'react-query';
import { createWorkoutProgram } from '../../../api/program';
import { useHistory } from 'react-router-dom';
import { AppPathsAdmin } from '../../../routes/AppRoutes';
import { SmartImageInput } from '../../../components/SmartForm/SmartImageInput';
import { ChangeEvent, useEffect, useState } from 'react';
import { NutritionDiaryCategory } from '../../../api/models/nutrition';
import { createRecipe } from '../../../api/nutrition';
import { ListInput } from '../../../components/Inputs/ListInput';
import { toast } from 'react-toastify';

export interface AddRecipeFormInputs {
    name: string;
    ingredients: string[];
    directions: string[];
    kcals: number;
    protein: number;
    carbohydrates: number;
    fats: number;
    time: number;
    prepTime: number;
    servings: number;
    image: string;
    category: NutritionDiaryCategory;
    tips?: string[];
    premium: boolean;
}

interface AddRecipeFormProps {
    defaultValues?: AddRecipeFormInputs;
}

const schema = object({
        name: string().required('Recipe name is required'),
        ingredients: array().of(string()).required('Recipe ingredients are required'),
        directions: array().of(string()).required('Recipe directions are required'),
        kcals: number().required('Recipe kcals are required'),
        protein: number().required('Recipe protein is required'),
        carbohydrates: number().required('Recipe carbohydrates are required'),
        fats: number().required('Recipe fats are required'),
        time: number().required('Recipe time is required'),
        prepTime: number().required('Recipe prep time is required'),
        servings: number().required('Recipe servings is required'),
        category: number().required('Recipe categories are required'),
        tip: string().notRequired(),
        premium: boolean().required('Recipe premium status is required')
}).required();

const recipeCategoryOptions = [
    {
        value: 1,
        label: 'Breakfast'
    },
    {
        value: 2,
        label: 'Lunch'
    },
    {
        value: 3,
        label: 'Dinner'
    },
    {
        value: 4,
        label: 'Snack'
    },
];

const workoutLocationOptions = [
    {
        value: 0,
        label: 'Gym'
    },
    {
        value: 1,
        label: 'Home'
    },
];

export const AddRecipeForm: React.FC<AddRecipeFormProps> = ({
    defaultValues
}) => {
    const history = useHistory();
    const [image, setImage] = useState<File>();

    const { register, watch, handleSubmit, setValue, formState: { errors }, getValues } = useForm<AddRecipeFormInputs>({
        resolver: yupResolver(schema),
        defaultValues
    });

    const ingredients = watch("ingredients", []);
    const directions = watch("directions", []);

    const addRecipeRequest = useMutation(`${new Date()}-create-recipe`, async (values: AddRecipeFormInputs) => {
        const result = defaultValues ? await createRecipe(values) : await createRecipe(values);
        
        if(!result.isError) {
            toast.success(defaultValues ? "Recipe has been updated!" : "Recipe has been created!");
            // history.push(AppPathsAdmin.editWorkoutProgram(result.content._id));


        }
    });

    const onSubmit = (values: AddRecipeFormInputs) => {
        const formData = new FormData();

        image && formData.append('image', image);
        formData.append('name', values.name);
        formData.append('ingredients', values.ingredients.toString());
        formData.append('directions', values.directions.toString());
        formData.append('kcals', values.kcals.toString());
        formData.append('protein', values.protein.toString());
        formData.append('carbohydrates', values.carbohydrates.toString());
        formData.append('fats', values.fats.toString());
        formData.append('time', values.time.toString());
        formData.append('prepTime', values.prepTime.toString());
        formData.append('servings', values.servings.toString());
        formData.append('category', values.category.toString());
        values.premium && formData.append('premium', values.premium.toString());

        addRecipeRequest.mutate(formData as unknown as AddRecipeFormInputs);
    };

    const handleProgramImageSelect = (e: ChangeEvent<HTMLInputElement>) => {
        if(e.currentTarget.files && e.currentTarget.files[0]) {
            setImage(e.currentTarget.files[0]);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col h-full justify-center items-center'>
            <div className="relative w-full max-w-lg">
                <div className="w-full mb-4">
                    <SmartInput register={register('name')} placeholder='Recipe name' label='Recipe name' error={errors.name?.message} />
                </div>
                <div className="w-full mb-4">
                    <SmartListInput value={ingredients} label='Ingredients' placeholder='Add an ingredient' onChange={(val) => setValue('ingredients', val as string[])} />
                </div>
                <div className="w-full mb-4">
                    <SmartListInput value={directions} label='directions' placeholder='Add a direction' onChange={(val) => setValue('directions', val as string[])} />
                </div>
                <div className="w-full mb-4">
                    <SmartInput register={register('kcals')} type='number' step={0.01} placeholder='Kcals' label='Kcals' error={errors.kcals?.message} />
                </div>
                <div className="w-full mb-4">
                    <SmartInput register={register('protein')} type='number' step={0.01} placeholder='Protein' label='Protein' error={errors.protein?.message} />
                </div>
                <div className="w-full mb-4">
                    <SmartInput register={register('carbohydrates')} type='number' step={0.01} placeholder='Carbohydrates' label='Carbohydrates' error={errors.carbohydrates?.message} />
                </div>
                <div className="w-full mb-4">
                    <SmartInput register={register('fats')} type='number' step={0.01} placeholder='Fats' label='Fats' error={errors.fats?.message} />
                </div>
                <div className="w-full mb-4">
                    <SmartInput register={register('time')} type='number' step={0.01} placeholder='Time' label='Time' error={errors.time?.message} />
                </div>
                <div className="w-full mb-4">
                    <SmartInput register={register('prepTime')} type='number' step={0.01} placeholder='Prep time' label='Prep time' error={errors.prepTime?.message} />
                </div>
                <div className="w-full mb-4">
                    <SmartInput register={register('servings')} type='number' step={0.01} placeholder='Servings' label='Servings' error={errors.servings?.message} />
                </div>
                <div className="w-full mb-4">
                    {/* <SmartImageInput register={register('image')} placeholder='Image' label='Image' error={errors.image?.message} /> */}
                    
                    <label>Image</label>
                    <input className="block" type="file" typeof="image" accept="image/gif, image/jpeg, image/png" onChange={handleProgramImageSelect} />
                </div>
                <div className="w-full mb-4">
                    <SmartSelect 
                        register={register('category')} 
                        error={errors.category?.message}
                        label='Select a category'
                        defaultOption='Select a category'
                        options={recipeCategoryOptions} />
                </div>
                <div className="w-full mb-4">
                    <label>
                        <input type='checkbox' {...register('premium')} className="mr-2" />
                        Premium?
                    </label>
                </div>
            </div>
            <div className='mt-4 w-full max-w-lg'>
                <Button type='submit'>Save</Button>
            </div>
        </form>
    );
};