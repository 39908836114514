import { useState } from "react";
import { DayPicker } from "react-day-picker";
import { UseFormRegisterReturn } from "react-hook-form";
import { InputError } from "../Inputs";

interface SmartDateInputProps {
    register: UseFormRegisterReturn;
    label?: string;
    error?: string;
    value?: string;
}

export const SmartDateInput: React.FC<SmartDateInputProps> = ({
    register,
    label,
    error,
    value
}) => {
    const [selectedDate, setSelectedDate] = useState(value ? new Date(value) : undefined);

    const handleChange = (date?: Date) => {
        if(date) {
            setSelectedDate(date);

            const eventObj = {
                target: {
                    value: date.toISOString(),
                    name: register.name
                }
            }
    
            register.onChange(eventObj);
        }
    };

    return (
        <>
            {label &&
                <label className="block text-sm font-medium text-black mb-1">{label}</label>
            }
            <DayPicker {...register} fromYear={1900} toYear={new Date().getFullYear()} captionLayout="dropdown" mode="single" onSelect={handleChange} selected={selectedDate} />
            {error && <InputError error={error} />}
        </>
    );
};