export const LogbookEntrySkeleton: React.FC = () => {
    return (
        <div className="p-6">
            <div className="p-4 rounded-md group mb-4 h-12 w-full skeleton-box">

            </div>
            <div className="p-4 rounded-md group mb-4 h-72 w-full skeleton-box">

            </div>
            <div className="p-4 rounded-md group mb-4 h-72 w-full skeleton-box">

            </div>
            <div className="p-4 rounded-md group mb-4 h-72 w-full skeleton-box">

            </div>
        </div>
    );
};