import { authLogin, authLogout } from "../api/authentication";
import { IUser } from "../models/user";

interface AuthFailureResult {
    isError: true,
    error: string,
    status?: number
}

interface AuthSuccessResult {
    isError: false,
    user: IUser
}

export type AuthResult = AuthFailureResult | AuthSuccessResult;

const createAuthClient = () => {
    const logIn = async (email: string, password: string): Promise<AuthResult> => {
        const authResult = await authLogin(email, password);

        if(authResult.isError) {
            return {
                isError: true,
                error: authResult.message,
                status: authResult.statusCode
            }
        }

        sessionStorage.setItem('accessToken', authResult.content.accessToken);
        sessionStorage.setItem('refreshToken', authResult.content.refreshToken);
        sessionStorage.setItem('user', JSON.stringify(authResult.content.user));
        console.log("here 1");

        return {
            isError: false,
            user: authResult.content.user
        };
    };

    const logOut = async () => {
        await authLogout();
        sessionStorage.clear();

        return {
            isError: false
        };
    };

    const getTokens = () => {
        const accessToken = sessionStorage.getItem('accessToken');
        const refreshToken = sessionStorage.getItem('refreshToken');

        return {
            accessToken,
            refreshToken
        };
    };

    const getUser = () => {
        const user = sessionStorage.getItem('user');
        console.log(user);

        return user ? JSON.parse(user) : undefined;
    };

    const updateUser = (user?: IUser) => {
        sessionStorage.setItem('user', JSON.stringify(user));
        console.log("here 2", user);
    }

    const loadSession = () => {
        const user = getUser();

        console.log(user, !!user);

        return {
            isLoggedIn: !!user,
            user
        };
    };

    return {
        logIn,
        logOut,
        getTokens,
        getUser,
        loadSession,
        updateUser,
    };
};

const authClient = createAuthClient();

export default authClient;