import { DateTime } from "luxon";
import { buildUrl, httpClient } from "./httpClient";
import { HttpClientResponse } from "./httpClient/httpClient";
import { ApiListResponse, ApiStandardResponse, mapHttpClientResponse, mapHttpListResponse } from "./httpClient/mapHttpClientResponse";
import { IApiClientProgramUpdate, IApiGetClientProgramSchedule, IApiGetProgram, IApiGetUpcomingClientPrograms, IApiProgram, IApiProgramDay, ICoreProgram } from "./models/program";

export const createWorkoutProgram = async (program: ICoreProgram): Promise<HttpClientResponse<IApiProgram>> => {
    const url = buildUrl('/api/program');

    const response = await httpClient.postRequest<ApiStandardResponse<IApiProgram>>(url, program);

    return mapHttpClientResponse(response, m => m);
};

export const updateWorkoutProgramCoreData = async (program: ICoreProgram, id: string): Promise<HttpClientResponse<IApiProgram>> => {
    const url = buildUrl(`/api/program/core/${id}`);

    const response = await httpClient.putRequest<ApiStandardResponse<IApiProgram>>(url, program);

    return mapHttpClientResponse(response, m => m);

};

export const getWorkoutPrograms = async (): Promise<HttpClientResponse<IApiProgram[]>> => {
    const url = buildUrl(`/api/program`);

    const response = await httpClient.getRequest<ApiListResponse<IApiProgram>>(url);

    return mapHttpListResponse(response, m => m);
};

export const getWorkoutProgram = async (programId: string): Promise<HttpClientResponse<IApiGetProgram>> => {
    const url = buildUrl(`/api/program/${programId}`);

    const response = await httpClient.getRequest<ApiStandardResponse<IApiGetProgram>>(url);

    return mapHttpClientResponse(response, m => m);
};

export const updateWorkoutProgram = async (programId: string, updatedProgram: IApiGetProgram): Promise<HttpClientResponse<void>> => {
    const url = buildUrl(`/api/program/${programId}`);

    const response = await httpClient.putRequest<ApiStandardResponse<void>>(url, updatedProgram);

    return mapHttpClientResponse(response, m => m);
};

export const assignProgramToClient = async (program: string, startDate: string): Promise<HttpClientResponse<void>> => {
    const url = buildUrl(`/api/program/assign`);
    const dto = {
        program, startDate
    };

    const response = await httpClient.postRequest<ApiStandardResponse<void>>(url, dto);

    return mapHttpClientResponse(response, m => m);
};

export const getClientProgramSchedule = async (): Promise<HttpClientResponse<IApiGetClientProgramSchedule>> => {
    const currentDate = DateTime.fromJSDate(new Date()).toString();
    const url = buildUrl(`/api/program/schedule/me/${currentDate}`);

    const response = await httpClient.getRequest<ApiStandardResponse<IApiGetClientProgramSchedule>>(url);

    return mapHttpClientResponse(response, m => m);
};

export const getClientProgramScheduleByClientId = async (id: string): Promise<HttpClientResponse<IApiGetClientProgramSchedule>> => {
    const url = buildUrl(`/api/program/schedule/client/${id}`);

    const response = await httpClient.getRequest<ApiStandardResponse<IApiGetClientProgramSchedule>>(url);

    return mapHttpClientResponse(response, m => m);
};

export const getUpcomingClientProgramsByClientId = async (id: string): Promise<HttpClientResponse<IApiGetUpcomingClientPrograms>> => {
    const url = buildUrl(`/api/program/upcoming/${id}`);

    const response = await httpClient.getRequest<ApiStandardResponse<IApiGetUpcomingClientPrograms>>(url);

    return mapHttpClientResponse(response, m => m);
};

export const getUpcomingClientPrograms = async (): Promise<HttpClientResponse<IApiGetUpcomingClientPrograms>> => {
    const url = buildUrl('/api/program/me/upcoming');

    const response = await httpClient.getRequest<ApiStandardResponse<IApiGetUpcomingClientPrograms>>(url);

    return mapHttpClientResponse(response, m => m);
};

export const getClientProgramDay = async (id: string): Promise<HttpClientResponse<IApiProgramDay>> => {
    const url = buildUrl(`/api/program/day/${id}`);

    const response = await httpClient.getRequest<ApiStandardResponse<IApiProgramDay>>(url);

    return mapHttpClientResponse(response, m => m);
};

export const updateClientProgram = async (id: string, update: IApiClientProgramUpdate): Promise<HttpClientResponse<void>> => {
    const url = buildUrl(`/api/program/client/update/${id}`);

    const response = await httpClient.putRequest<ApiStandardResponse<void>>(url, update);

    return mapHttpClientResponse(response, m => m);
};

export const cancelClientProgram = async (id: string): Promise<HttpClientResponse<void>> => {
    const url = buildUrl(`/api/program/client/cancel/${id}`);

    const response = await httpClient.putRequest<ApiStandardResponse<void>>(url);

    return mapHttpClientResponse(response, m => m);
};