import { useExercise } from "../../exercise/ExerciseContext";
import { AddExerciseForm } from "../../forms/admin/AddExerciseForm";
import { AddExerciseVideoForm } from "../../forms/admin/AddExerciseVideoForm";
import { Modal } from "../Modal/Modal";

interface EditExerciseModalProps {
    open: boolean;
    exercise: string;
    onClose(): void;
}

export const EditExerciseModal: React.FC<EditExerciseModalProps> = ({
    open,
    exercise,
    onClose
}) => {
    const { getExerciseById } = useExercise();

    const exerciseDetails = getExerciseById(exercise);
    const isNewExercise = exercise === "";
    const isVendorExercise = exerciseDetails && exerciseDetails.vendor !== undefined;

    return (
        <>
            <Modal isOpen={open} closeModal={onClose} title={isNewExercise ? "Add exercise" : "Edit exercise"}>
                <AddExerciseForm initialValues={exerciseDetails ?? undefined} isVendorExercise={!!isVendorExercise} onClose={onClose} />
            </Modal>
        </>
    );
};