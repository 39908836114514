import { buildUrl, httpClient } from "./httpClient";
import { HttpClientResponse } from "./httpClient/httpClient";
import { ApiListResponse, ApiStandardResponse, mapHttpClientResponse, mapHttpListResponse } from "./httpClient/mapHttpClientResponse";
import { CreateNutritionTargetDto, IApiAddNutritionDiaryEntryDto, IApiNutrition, IApiNutritionDiaryEntry } from "./models/nutrition";
import { IApiRecipe } from "./models/recipe";

export const calculateNutritionTarget = async (data: CreateNutritionTargetDto): Promise<HttpClientResponse<IApiNutrition>> => {
    const url = buildUrl('/api/nutrition/calculate');

    const response = await httpClient.postRequest<ApiStandardResponse<IApiNutrition>>(url, data);
    
    return mapHttpClientResponse(response, m => m);
};

export const getNutritionTarget = async (date: Date): Promise<HttpClientResponse<IApiNutrition>> => {
    const url = buildUrl(`/api/nutrition/current/${date}`);

    const response = await httpClient.getRequest<ApiStandardResponse<IApiNutrition>>(url);
    
    return mapHttpClientResponse(response, m => m);
};

export const createRecipe = async (data: IApiRecipe): Promise<HttpClientResponse<IApiRecipe>> => {
    const url = buildUrl('/api/admin/recipe');

    const response = await httpClient.postRequest<ApiStandardResponse<IApiRecipe>>(url, data);
    
    return mapHttpClientResponse(response, m => m);
};

export const createCustomRecipe = async (data: IApiRecipe): Promise<HttpClientResponse<IApiRecipe>> => {
    const url = buildUrl('/api/recipe/custom');

    const response = await httpClient.postRequest<ApiStandardResponse<IApiRecipe>>(url, data);
    
    return mapHttpClientResponse(response, m => m);
};

export const createNutritionDiaryEntry = async (data: IApiAddNutritionDiaryEntryDto): Promise<HttpClientResponse<IApiNutritionDiaryEntry>> => {
    const url = buildUrl('/api/nutrition/diary/add');

    const response = await httpClient.postRequest<ApiStandardResponse<IApiNutritionDiaryEntry>>(url, data);
    
    return mapHttpClientResponse(response, m => m);
};

export const removeNutritionDiaryEntry = async (nutritionDiaryEntryId: string): Promise<HttpClientResponse<void>> => {
    const url = buildUrl(`/api/nutrition/diary/remove/${nutritionDiaryEntryId}`);

    const response = await httpClient.deleteRequest<ApiStandardResponse<void>>(url);
    
    return mapHttpClientResponse(response, m => m);
};