import { FiPlusSquare, FiCalendar } from "react-icons/fi";
import { ProgramWeek } from ".";
import { WorkoutEditor } from "../WorkoutEditor";
import { useProgramEditor } from "./ProgramEditorContext/ProgramEditorContext";
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import clsx from "clsx";
import { DateTime } from "luxon";
import { CreateProgramForm } from "../../forms/admin/CreateProgramForm";
import { Disclosure, Transition } from "@headlessui/react";
import { BiChevronDown } from "react-icons/bi";
export const ProgramEditor: React.FC= () => {
    const { program, programWeeks, clientProgram, notFound, addWeek, handleDragAndDrop } = useProgramEditor();

    return (
        <>
        <div className="p-4">
        <Disclosure>
            {({ open }) => (
                <div className={clsx("border rounded-md mb-4")}>
                <Disclosure.Button className={clsx(
                    "flex items-center justify-between w-full text-lg px-4 font-semibold text-left py-6 rounded-md transition-all hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75",
                     open && "bg-gray-50")}>
                     Edit Program Details
                        <BiChevronDown
                            className={`${
                                open ? 'transform rotate-180' : ''
                            }`}
                            />
                </Disclosure.Button>
                <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                >
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                            <CreateProgramForm defaultValues={program} />
                        </Disclosure.Panel>
                </Transition>
                </div>
            )}
        </Disclosure>
        </div>
        <DragDropContext onDragEnd={(result) => handleDragAndDrop(result)}>
            {notFound &&
                <h1 className="text-3xl font-medium">Ooops... Workout program not found!</h1>
            }
            <div className="px-6 pt-4 pb-6">
                <Droppable droppableId='week-drop-zone' type='WEEK'>
                    {(provided, snapshot) => (
                        <div 
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={clsx("w-full")}>
                                {programWeeks && programWeeks.length > 0 && programWeeks.map(programWeek =>
                                    <ProgramWeek key={programWeek._id} programWeek={programWeek} />
                                )}
                                {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                {!notFound &&
                    <button 
                        className="group flex flex-col items-center justify-center border-dashed border-2 border-gray-200 mt-8 w-full h-44 sm:h-56 rounded-md transition-all hover:border-blue-400 hover:bg-blue-100"
                        onClick={() => addWeek()}>
                        <FiPlusSquare className="w-12 h-12 transition-all text-gray-300 group-hover:text-blue-400 " />
                        <span className="sr-only">Add Week</span>
                    </button>
                }
            </div>
            <WorkoutEditor />
        </DragDropContext>
        </>
    );
};