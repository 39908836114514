import { MdFitnessCenter } from "react-icons/md";
import { WorkoutEditorSet } from "..";
import { Draggable } from 'react-beautiful-dnd';
import { WorkoutStep } from "../../../api/models/program";
import { useExercise } from "../../../exercise/ExerciseContext";
import clsx from "clsx";
import { WorkoutEditorStepMenu } from "./WorkoutEditorStepMenu";
import { WorkoutEditorSupersetStep } from "../WorkoutEditorSupersetStep";
import { useProgramEditor } from "../../ProgramEditor/ProgramEditorContext/ProgramEditorContext";
import { Button } from "../../Button";

interface Props {
    workoutStep: WorkoutStep;
    supersetSteps?: WorkoutStep[];
    onAddSuperset: (workoutStepId: string) => void;
}

export const WorkoutEditorStep: React.FC<Props> = ({
    workoutStep,
    supersetSteps,
    onAddSuperset
}) => {
    const { getExerciseById } = useExercise();
    const { addSet } = useProgramEditor();

    const exercise = getExerciseById(workoutStep.exercise);
    
    return (
        <Draggable draggableId={workoutStep.workoutStepId} index={workoutStep.order}>
            {(provided, snapshot) => (
                <div 
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={clsx("py-4 relative", snapshot.isDragging && "-ml-36")} key={workoutStep.workoutStepId}>
                    <div className="p-2 rounded-md group bg-white">
                        <div className="flex items-center">
                            <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white rounded-md bg-blue-50 sm:h-12 sm:w-12 group-hover:bg-gray-100 transition-colors">
                                <MdFitnessCenter className="w-6 h-6 text-blue-500" />
                            </div>
                            <div className="ml-4">
                                <p className="font-medium text-gray-900">{exercise?.name}</p>
                            </div>
                            <div className="ml-auto flex items-center">
                                <WorkoutEditorStepMenu workoutStep={workoutStep} onAddSuperset={onAddSuperset} />
                            </div>
                        </div>
                        {workoutStep.sets.map(workoutSet =>
                            <WorkoutEditorSet 
                                workoutSet={workoutSet}
                                workoutStepId={workoutStep.workoutStepId}
                                exercise={exercise}
                                key={workoutSet.workoutSetId} />  
                        )}
                        <div className="mt-4">
                            <Button variant="gray" size="small" onClick={() => addSet(workoutStep.workoutStepId)}>Add Set +</Button>
                        </div>
                    </div>
                    {supersetSteps?.map(supersetStep =>
                        <WorkoutEditorSupersetStep
                            workoutStep={supersetStep}
                            key={supersetStep.workoutStepId}
                        />    
                    )}
                    {supersetSteps && supersetSteps.length > 0 &&
                        <div className="flex justify-center items-center absolute top-0 -left-4 w-4 h-full py-6">
                            <div className="w-full h-full flex items-center justify-center  bg-blue-400">
                                <span className="uppercase text-white text-sm font-medium transform -rotate-90">Superset</span>
                            </div>
                        </div>
                    }
                </div>
            )}
        </Draggable> 
    );
};