import {
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import { CreateLiveWorkoutPage } from '../pages/admin/CreateLiveWorkout';
import { CreateRecipePage } from '../pages/admin/CreateRecipe';
import { CreateWorkoutProgramPage } from '../pages/admin/CreateWorkoutProgram';
import { EditLiveWorkoutPage } from '../pages/admin/EditLiveWorkout';
import { EditWorkoutProgramPage } from '../pages/admin/EditWorkoutProgram';
import { DashboardPage } from '../pages/Dashboard';
import { ForgottenPasswordPage } from '../pages/ForgottenPassword';
import { ForgottenPasswordConfirmationPage } from '../pages/ForgottenPasswordConfirmation';
import { HomePage } from '../pages/Home';
import { LiveWorkoutDetailPage } from '../pages/LiveWorkoutDetail';
import { LiveWorkoutsPage } from '../pages/LiveWorkouts';
import { LoginPage } from '../pages/Login';
import ProfilePage from '../pages/Profile';
import { ProgramDetailPage } from '../pages/ProgramDetail';
import { ProgramsPage } from '../pages/Programs';
import RecipeDetailPage from '../pages/RecipeDetail';
import { RecipesPage } from '../pages/Recipes';
import { RegisterPage } from '../pages/Register';
import { WorkoutPage } from '../pages/Workout';
import { WorkoutCompletePage } from '../pages/WorkoutComplete';
import WorkoutedLocked from '../pages/WorkoutLocked';
import RouteChangeScrollToTop from './RouteChangeScrollToTop';

export const AppPathsGlobal = {
    
}

export const AppPathsPublic = {
    home: '/',
    signIn: '/login',
    signUp: '/register',
    forgottenPassword: '/forgotten-password',
    ForgottenPasswordConfirmation: (confirmationCode: string) => `/reset/${confirmationCode}`,
    liveWorkouts: '/live',
    liveWorkoutDetail: (id: string) => `/live/${id}`,
    programs: '/programs',
    programDetail: (id: string) => `/program/${id}`,
    recipeDetail: (id: string) => `/recipe/${id}`
}

export const AppPathsLoggedIn = {
    dashboard: '/dashboard',
    liveWorkouts: '/live',
    liveWorkoutDetail: (id: string) => `/live/${id}`,
    programDetail: (id: string) => `/program/${id}`,
    programs: '/programs',
    recipes: '/recipes',
    recipeDetail: (id: string) => `/recipe/${id}`,
    workout: (id: string, step: string) => `/workout/log/${id}/${step}`,
    workoutComplete: (id: string) => `/workout/complete/${id}`,
    logbook: (month: string, year: string) => `/dashboard/logbook/all/${month}/${year}`,
    logbookItem: (id: string) => `/dashboard/logbook/item/${id}`,
    profile: '/profile',
    nutrition: '/dashboard/nutrition',
}

export const AppPathsAdmin = {
    createLiveWorkout: '/admin/live/create',
    editLiveWorkout: (id: string) => `/admin/live/edit/${id}`,
    createWorkoutProgram: '/admin/program/create',
    editWorkoutProgram: (id: string) => `/admin/program/edit/${id}`,
    createRecipe: '/admin/recipe/create'
};

export const AppRouter: React.FC = () => {
    const { user, isAdmin } = useAuth();

    return (
        <>
            <RouteChangeScrollToTop />
            <Switch>
                {!user &&
                    <>
                    <Route path={AppPathsPublic.home} exact>
                        <HomePage />
                    </Route>
                    <Route path={AppPathsPublic.signIn} exact>
                        <LoginPage />
                    </Route>
                    <Route path={AppPathsPublic.signUp} exact>
                        <RegisterPage />
                    </Route>
                    <Route path={AppPathsPublic.forgottenPassword} exact>
                        <ForgottenPasswordPage />
                    </Route>
                    <Route path={AppPathsPublic.ForgottenPasswordConfirmation(':confirmationCode')} exact>
                        <ForgottenPasswordConfirmationPage />
                    </Route>
                    <Route path={AppPathsPublic.liveWorkouts} exact>
                            <LiveWorkoutsPage />
                    </Route>
                    <Route path={AppPathsPublic.liveWorkoutDetail(':id')} exact>
                        <LiveWorkoutDetailPage />
                    </Route>
                    <Route path={AppPathsPublic.programs} exact>
                        <ProgramsPage />
                    </Route>
                    <Route path={AppPathsPublic.programDetail(':id')} exact>
                        <ProgramDetailPage />
                    </Route>
                    </>
                }
                {user &&
                    <>
                        <Route path={AppPathsPublic.home} exact>
                            <HomePage />
                        </Route>
                        <Route path={AppPathsLoggedIn.dashboard}>
                            <DashboardPage />
                        </Route>
                        <Route path={AppPathsLoggedIn.liveWorkouts} exact>
                            <LiveWorkoutsPage />
                        </Route>
                        <Route path={AppPathsLoggedIn.programs} exact>
                            <ProgramsPage />
                        </Route>
                        <Route path={AppPathsLoggedIn.recipes} exact>
                            <RecipesPage />
                        </Route>
                        <Route path={AppPathsLoggedIn.recipeDetail(':id')} exact>
                            <RecipeDetailPage />
                        </Route>
                        <Route path={AppPathsLoggedIn.liveWorkoutDetail(':id')} exact>
                            <LiveWorkoutDetailPage />
                        </Route>
                        <Route path={AppPathsPublic.programDetail(':id')} exact>
                            <ProgramDetailPage />
                        </Route>
                        <Route path={AppPathsLoggedIn.workout(':id', ':step')} exact>
                            <WorkoutPage />
                        </Route>
                        <Route path={AppPathsLoggedIn.workoutComplete(':id')} exact>
                            <WorkoutCompletePage />
                        </Route>
                        <Route path={AppPathsLoggedIn.profile} exact>
                            <ProfilePage />
                        </Route>
                        {isAdmin &&
                            <>
                                <Route path={AppPathsAdmin.createLiveWorkout} exact>
                                    <CreateLiveWorkoutPage />
                                </Route>
                                <Route path={AppPathsAdmin.editLiveWorkout(':id')} exact>
                                    <EditLiveWorkoutPage />
                                </Route>
                                <Route path={AppPathsAdmin.createWorkoutProgram} exact>
                                    <CreateWorkoutProgramPage />
                                </Route>
                                <Route path={AppPathsAdmin.editWorkoutProgram(':id')} exact>
                                    <EditWorkoutProgramPage />
                                </Route>
                                <Route path={AppPathsAdmin.createRecipe} exact>
                                    <CreateRecipePage />
                                </Route>
                            </>
                        }
                    </>
                }
                <Route render={() => <Redirect to='/' />} />
            </Switch>
        </>
    );
};