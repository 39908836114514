import { Link } from "react-router-dom";
import { AppPathsGlobal, AppPathsPublic } from "../../routes/AppRoutes";

export const NavBarItemsLoggedOut: React.FC = () => {
    return (
        <ul className="list-none lg:flex lg:ml-auto">
          <li>
            <Link to="/" className="block py-3 uppercase tracking-widest lg:px-4 lg:py-0 lg:inline-block">Home</Link>
          </li>
          <li>
            <Link to={AppPathsPublic.liveWorkouts} className="block py-3 uppercase tracking-widest lg:px-4 lg:py-0 lg:inline-block">Live</Link>
          </li>
          <li>
            <Link to={AppPathsPublic.programs} className="block py-3 uppercase tracking-widest lg:px-4 lg:py-0 lg:inline-block">Programs</Link>
          </li>
          {/* <li>
            <a href="#0" className="block py-3 uppercase tracking-widest lg:px-4 lg:py-0 lg:inline-block">About Us</a>
          </li> */}
          <li>
            <Link to={AppPathsPublic.signIn} className="block py-3 uppercase tracking-widest lg:px-4 lg:py-0 lg:inline-block">Sign In</Link>
          </li>
        </ul>
    );
};