import { useState } from "react";
import { useQuery } from "react-query";
import { getLiveWorkouts } from "../../api/liveWorkout";
import { FreeTrialBanner } from "../../components/Banners";
import { PublicLayout } from "../../layouts/PublicLayout";
import { LiveWorkoutsFilter } from "./LiveWorkoutsFilter";
import { LiveWorkoutsList } from "./LiveWorkoutsList";

export interface FilterChangeTarget {
    property: 'difficulty' | 'muscleCategory' | 'location';
    value: number;
}

enum SORT_BY {
    LATEST,
    OLDEST
}

export const LiveWorkoutsPage: React.FC = () => {
    const [difficultyFilter, setDifficultyFilter] = useState<number[]>([]);
    const [muscleCategoryFilter, setMuscleCategory] = useState<number[]>([]);
    const [locationFilter, setLocationyFilter] = useState<number[]>([]);
    const [sortBy, setSortBy] = useState(SORT_BY.LATEST);

    const { isLoading, error, data: liveWorkouts = [], refetch } = useQuery(`${[...difficultyFilter, ...muscleCategoryFilter, ...locationFilter]}-live-workouts`, async () => { 
        const result = await getLiveWorkouts(difficultyFilter, muscleCategoryFilter, locationFilter);
        if(!result.isError) {
            return result.content;
        }   
    });

    const handleFilterChange = (target: FilterChangeTarget) => {
        if(target.property === 'difficulty') {
            difficultyFilter.includes(target.value) ? setDifficultyFilter([...difficultyFilter].filter(val => val !== target.value)) : setDifficultyFilter([...difficultyFilter, target.value]);
        }

        if(target.property === 'muscleCategory') {
            muscleCategoryFilter.includes(target.value) ? setMuscleCategory([...muscleCategoryFilter].filter(val => val !== target.value)) : setMuscleCategory([...muscleCategoryFilter, target.value]);
        }

        if(target.property === 'location') {
            locationFilter.includes(target.value) ? setLocationyFilter([...locationFilter].filter(val => val !== target.value)) : setLocationyFilter([...locationFilter, target.value]);
        }
    };

    const sortedLiveWorkouts = liveWorkouts.sort((a, b) => {
        if(sortBy === SORT_BY.LATEST) {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        }

        if(sortBy === SORT_BY.OLDEST) {
            return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        }

        return 1;
    });

    return (
        <PublicLayout>
      <div className="px-6 py-12 sm:py-24 bg-gray-50">
        <div className="w-full max-w-screen-xl m-auto">
            <h1 className="text-2xl sm:text-4xl uppercase font-black font-display mb-4 tracking-widest text-center">Live Workouts</h1>
            <p className="sm:text-lg text-center max-w-3xl m-auto">This is our LIVE workouts page. Choose what workout you would like to do and click play - let’s workout together!</p>
        </div>
      </div>
      <div className="px-6 sm:px-12 py-8">
        <div className="w-full sm:flex">
            <LiveWorkoutsFilter 
                handleFilterChange={handleFilterChange}
                selectedDifficulty={difficultyFilter}
                selectedMuscleCategory={muscleCategoryFilter}
                selectedLocation={locationFilter} />
            <div className="flex-1 sm:px-8">
                <div className="flex items-center">
                    <div className="font-display font-bold uppercase tracking-widest mb-2 opacity-60 text-xs sm:text-base">Showing {liveWorkouts.length} results</div>
                    <div className="-mt-2 flex items-center ml-auto">
                        <span className="font-display uppercase text-xs opacity-60 sm:text-sm">Sort by</span>
                        <select 
                            className="font-display uppercase text-xs opacity-90 sm:text-sm" 
                            style={{marginTop: '-2px'}}
                            value={sortBy}
                            onChange={(e) => setSortBy(parseInt(e.currentTarget.value))}>
                            <option value={SORT_BY.LATEST}>Latest</option>
                            <option value={SORT_BY.OLDEST}>Oldest</option>
                        </select>
                    </div>
                </div>
                <LiveWorkoutsList liveWorkouts={sortedLiveWorkouts} isLoading={isLoading} />
            </div>
        </div>
      </div>
      <FreeTrialBanner />
        </PublicLayout>
    );
};