import { DateTime } from "luxon";
import { useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getNutritionTarget } from "../../../api/nutrition";
import { getWorkoutStatsBetweenDates } from "../../../api/workoutLog";
import { useAuth } from "../../../auth/AuthContext";
import { Button } from "../../../components/Button";
import { NutritionTargetCard } from "../../../components/NutritionTargetCard";
import TodaysScheduleCard from "../../../components/TodaysScheduleCard";
import WorkoutStatsCard from "../../../components/WorkoutStatsCard/WorkoutStatsCard";
import { AppPathsLoggedIn } from "../../../routes/AppRoutes";

export const DashboardOverview: React.FC = () => {
    const { user } = useAuth();
    const [date, setDate] = useState(new Date());

    const { isLoading, error, data: nutritionTarget, refetch } = useQuery(`nutrition-target`, async () => {
        const result = await getNutritionTarget(date);

        if(!result.isError) {
            return result.content;
        }   
    });

    const { isLoading: statIsLoading, error: statsError, data: workoutStats } = useQuery(`past-week-stats`, async () => {
        const start = DateTime.fromJSDate(new Date()).startOf("week").toJSDate().toISOString();
        const end = new Date().toISOString();
        const result = await getWorkoutStatsBetweenDates(start, end);

        if(!result.isError) {
            return result.content;
        }   
    });

    return (
        <>
        <div className="mb-6">
            <div className="flex-1">
                <div className="flex items-center justify-center p-4 border border-gray-200 w-full h-80 mb-4">
                    <div className="flex flex-col justify-center items-center">
                        <div className="w-28 h-28 bg-gray-200 rounded-full"></div>
                        <div className="font-bold text-gray-600 mt-4 mb-6">{user?.firstname} {user?.lastname}</div>
                        <Button variant="gray" size="small" link={AppPathsLoggedIn.profile}>Edit Profile</Button>
                    </div>
                </div>
                <div className="grid sm:grid-cols-2 gap-4 mb-4">
                    <TodaysScheduleCard />
                    <div className="flex flex-col justify-between p-6 border border-gray-200 w-full h-80">
                        {!isLoading && nutritionTarget && nutritionTarget.nutrition !== null &&
                        <>                        
                            <div className="flex justify-between">
                                <div className="font-display font-black uppercase tracking-widest">Nutrition</div>
                                <Link to="/dashboard/nutrition" className="font-semibold text-sm p-0 flex items-center ml-auto">
                                    View my nutrition
                                    <AiOutlineArrowRight className="ml-2" />    
                                </Link>
                            </div>
                            <NutritionTargetCard nutritionTarget={nutritionTarget} />
                        </>
                        }
                        {!isLoading && nutritionTarget && nutritionTarget.nutrition === null &&
                            <div className="flex flex-col justify-center w-full h-full">
                                <div className="flex-1 flex flex-col justify-center items-center text-center">
                                    <div className="mb-8 font-semibold">You currently do not have any nutrition targets.</div>
                                    <Button link={AppPathsLoggedIn.nutrition}>Calculate My Macros</Button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {!statIsLoading && workoutStats &&
                    <WorkoutStatsCard stats={workoutStats} />
                }
            </div>
        </div>
        </>
    );
};