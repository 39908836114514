import { ProgramWeekDay } from ".";
import { IApiProgramWeek } from "../../api/models/program";
import { Draggable } from 'react-beautiful-dnd';
import { useProgramEditor } from "./ProgramEditorContext/ProgramEditorContext";
import { useState } from "react";
import { FiCopy, FiTrash2 } from "react-icons/fi";
import { Modal } from "../Modal/Modal";
import { Button } from "../Button";

interface Props {
    programWeek: IApiProgramWeek;
}

const weekDayCount = 7;

export const ProgramWeek: React.FC<Props> = ({
    programWeek
}) => {
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const { deleteProgramWeek, addWeek } = useProgramEditor();

    return (
        <>
        <Draggable draggableId={programWeek._id} index={programWeek.order}>
            {(provided, snapshot) => (
                <div 
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="rounded-md overflow-hidden bg-white shadow-soft mb-8">
                    <div className="flex items-center w-full p-4 border-b">
                        <div className="font-medium text-lg">Week {programWeek.order + 1}</div>
                        <div className="ml-auto flex items-center">
                            <button onClick={() => setDeleteConfirmation(true)}>
                                <FiTrash2 />
                            </button>
                            <div className="ml-2"></div>
                            <button onClick={() => addWeek(programWeek._id)}>
                                <FiCopy />
                            </button>
                        </div>
                    </div>
                    <div className="w-full flex flex-col md:flex-row">
                        {Array(weekDayCount).fill({}).map((_,i) =>
                            <ProgramWeekDay key={i} dayOfWeekNo={i} programWeekId={programWeek._id} />                
                        )}
                    </div>
                </div>
            )}
        </Draggable>
        <Modal 
            isOpen={deleteConfirmation} 
            closeModal={() => setDeleteConfirmation(false)} 
            title='Delete week' 
            caption={`Are you sure you want to delete Week ${programWeek.order + 1}?`}>
                <div className='mt-4 grid grid-cols-2 gap-4'>
                    <Button type='button' variant='transparent' onClick={() => setDeleteConfirmation(false)}>Cancel</Button>
                    <Button type='button' variant='danger' onClick={() => deleteProgramWeek(programWeek._id)}>Delete</Button>
                </div>
        </Modal>
        </>
    );
};