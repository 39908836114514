import { HttpClientResponse } from "./httpClient";

export type ApiStandardResponse<T> = T;

export type ApiListResponse<T> = T[];

export function mapHttpClientResponse<TResponse, TMapped>(
    response: HttpClientResponse<ApiStandardResponse<TResponse>>,
    mapper: (responseModel: TResponse) => TMapped): HttpClientResponse<TMapped> {
        
    if(response.isError) return response;

    try {
        return {
            ...response,
            content: mapper(response.content)
        };
    } catch (error) {
        console.log('Error parsing api response', error);
        throw error;
    }
}

export function mapHttpListResponse<TResponse, TMapped>(
    response: HttpClientResponse<ApiListResponse<TResponse>>,
    mapper: (responseModel: TResponse) => TMapped): HttpClientResponse<ApiListResponse<TMapped>> {
        
    if(response.isError) return response;

    try {
        return {
            ...response,
            content: response.content.map(mapper)
        };
    } catch (error) {
        console.log('Error parsing api response', error);
        throw error;
    }
}