import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as yup from 'yup';
import { ActivityLevel, NutritionGoal } from "../../api/models/nutrition";
import { Button } from "../../components/Button";
import { InputError } from "../../components/Inputs";
import { SmartInput, SmartSelect } from "../../components/SmartForm";
import { SmartDateInput } from "../../components/SmartForm/SmartDateInput";
import { SmartRadioGroup } from "../../components/SmartForm/SmartRadioGroup";
import { AppPathsPublic } from "../../routes/AppRoutes";

export interface NutritionTargetFormInputs {
    gender: string;
    dob: string;
    weight: number;
    height: number;
    activity: ActivityLevel;
    goal: NutritionGoal;
}

interface NutritionTargetFormProps {
    error?: string,
    onSubmit: (values: NutritionTargetFormInputs) => void
}

const schema = yup.object({
        gender: yup.string().required('Gender is required'),
        dob: yup.string().required('DOB is required'),
        weight: yup.number().required('Weight is required'),
        height: yup.number().required('Height is required'),
        activity: yup.number().required('Activity level is required'),
        goal: yup.number().required('Goal is required')
}).required();

const activityLevelOptions = [
    {
        value: 1,
        label: "Sedentary"
    },
    {
        value: 2,
        label: "Light"
    },
    {
        value: 3,
        label: "Moderate"
    },
    {
        value: 4,
        label: "Very"
    },
];

const goalOptions = [
    {
        value: 1,
        label: "Lose weight"
    },
    {
        value: 2,
        label: "Maintain weight"
    },
    {
        value: 3,
        label: "Gain weight"
    }
];

export const NutritionTargetForm: React.FC<NutritionTargetFormProps> = ({
    error,
    onSubmit
}) => {
    const [gender, setGender] = useState("female");
    
    const { register, handleSubmit, setValue, formState: { errors } } = useForm<NutritionTargetFormInputs>({
        resolver: yupResolver(schema)
    });

    const handleGenderChange = (gender: string) => {
        setGender(gender);
        setValue('gender', gender);
    };

    setValue('gender', gender);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='h-full justify-center items-center'>
            <div className="relative w-full md:grid md:grid-cols-2 md:gap-8">
                <div>
                    <div className="w-full">
                        <label className="block text-sm font-medium text-black mb-1">Gender</label>
                        <SmartRadioGroup 
                            onChange={(val: any) => handleGenderChange(val)}
                            value={gender}
                            options={[
                                {
                                    value: "male",
                                    label: "Male",
                                    description: ""
                                },
                                {
                                    value: "female",
                                    label: "Female",
                                    description: ""
                                }
                            ]} />
                    </div>
                    <div className="mb-4">
                        <SmartDateInput register={register('dob')} label="Date of birth" error={errors.dob?.message} />
                    </div>
                </div>
                <div>
                    <div className="mb-4">
                        <SmartInput register={register('weight')} type='number' step={0.01} placeholder='Weight (kg)' label='Weight (kg)' error={errors.weight?.message} />
                    </div>
                    <div className="mb-4">
                        <SmartInput register={register('height')} type='number' placeholder='Height (cm)' label='Height (cm)' error={errors.height?.message} />
                    </div>
                    <div className="mb-4">
                        <SmartSelect 
                            register={register('activity')} 
                            error={errors.activity?.message}
                            label='Select an activity level'
                            defaultOption='Select an activity level'
                            options={activityLevelOptions} />
                    </div>
                    <div className="mb-4">
                        <SmartSelect 
                            register={register('goal')} 
                            error={errors.goal?.message}
                            label='What is your goal?'
                            defaultOption='What is your goal?'
                            options={goalOptions} />
                    </div>
                </div>
            </div>
            <div className='mt-4 w-full max-w-lg'>
                <Button type='submit'>Calculate Macros</Button>
                {error && <div className="mt-4">
                    <InputError error={error} />
                </div>}
            </div>
        </form>
    );
};