import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IApiGetClientProgramSchedule } from "../../../api/models/program";
import { cancelClientProgram } from "../../../api/program";
import { Button } from "../../../components/Button";
import { Image } from "../../../components/Image";
import { Modal } from "../../../components/Modal/Modal";
import { config } from "../../../config";
import { DashboardActiveProgramItem } from "./DashboardActiveProgramItem";

interface DashboardActiveProgramProps {
    program: IApiGetClientProgramSchedule;
    refetch: () => void;
}

export const DashboardActiveProgram: React.FC<DashboardActiveProgramProps> = ({
    program,
    refetch
}) => {
    const [selectedWeek, setSelectedWeek] = useState<string>();
    const [cancelProgram, setCancelProgram] = useState(false);
    const { apiUri } = config;
    const clientProgram = program.clientPrograms[0];
    const programData = program.programs.find(p => p._id === clientProgram.program);

    const weeks = program.programWeeks.filter(week => week.programId === programData?._id);

    const days = program.programDays.filter(day => weeks.map(week => week._id).includes(day.programWeekId));

    const start = DateTime.fromJSDate(new Date(clientProgram.startDate));
    const today = DateTime.fromJSDate(new Date());
    const weekDiff = Math.ceil(today.diff(start, "weeks").weeks) - 1;

    const handleCancelProgram = async () => {
        const result = await cancelClientProgram(clientProgram._id);

        if(!result.isError) {
            setCancelProgram(false);
            refetch();
            toast.info("program has been cancelled.");
        }
    };

    useEffect(() => {
        if(!selectedWeek) {
            setSelectedWeek(weeks[weekDiff]._id);
        }
    }, [weeks]);

    if(!programData) return null;

    return (
        <>
        <div className="border border-gray-200 sm:flex mb-6">
            <div className="flex-none flex gap-4 sm:block sm:w-56 p-6 sm:pr-2">
                <div className="bg-gray-200 w-20 sm:w-full mb-2 portrait relative transition-opacity hover:opacity-80">
                    <Image className=" object-cover w-full h-full" src={`${apiUri}/api/file/${programData.image}`} />
                </div>
                <div className="">
                    <div className="font-bold uppercase tracking-wide mb-2 text-lg">{programData.name}</div>
                    <Button variant="danger" size="small" onClick={() => setCancelProgram(true)}>Cancel</Button>
                </div>
            </div>
            <div className="flex-1 p-6">
                <div>
                {weeks.filter(week => selectedWeek ? week._id === selectedWeek : week).map(week => {
                    return (
                        <>
                            <div className="flex pb-2 mb-4 border-b border-gray-200">
                                <h2 className="text-xl uppercase font-black font-display mb-2 tracking-wide">My Schedule</h2>
                                <div className="text-lg font-bold ml-auto">Week {week.order + 1} / {weeks.length}</div>
                            </div>
                            {days.filter(day => day.programWeekId === week._id).map((day) => 
                                <DashboardActiveProgramItem day={day} week={week} clientProgram={clientProgram} programLog={program.programDayLogs.find(log => log.programDay === day._id)?._id} key={day._id} />
                            )}
                        </>
                    );
                })}
                </div>
            </div>
        </div>
        <Modal isOpen={cancelProgram} closeModal={() => setCancelProgram(false)} title="Cancel Program">
            <p className="mb-8">Are you sure you want to cancel your active program?</p>
            <div className="grid grid-cols-2 gap-4">
                <Button type="button" variant="transparent" onClick={() => setCancelProgram(false)}>No</Button>
                <Button onClick={handleCancelProgram}>Yes</Button>
            </div>
        </Modal>
        </>
    );
};