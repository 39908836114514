import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getRecipeDetail } from "../../api/recipe";
import { PublicLayout } from "../../layouts/PublicLayout";
import { Image } from "../../components/Image";
import { useAuth } from "../../auth/AuthContext";
import { config } from "../../config";
import { Button } from "../../components/Button";
import { AppPathsAdmin, AppPathsLoggedIn, AppPathsPublic } from "../../routes/AppRoutes";
import { useState } from "react";
import { Modal } from "../../components/Modal/Modal";
import { SmartRadioGroup } from "../../components/SmartForm/SmartRadioGroup";
import { NutritionDiaryCategory } from "../../api/models/nutrition";
import { createNutritionDiaryEntry } from "../../api/nutrition";
import { Input } from "../../components/Inputs/Input";
import { DayPicker } from "react-day-picker";
import { toast } from "react-toastify";

interface Params {
    id: string;
}
export const RecipeDetailPage: React.FC = () => {
    const { id } = useParams<Params>(); 
    const { user, isPremium, isAdmin } = useAuth();
    const { apiUri } = config;
    const [addToDiaryModal, setAddToDiaryModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<NutritionDiaryCategory>(NutritionDiaryCategory.BREAKFAST);
    const [servings, setServings] = useState(1);
    const [date, setDate] = useState(new Date());
    
    const { isLoading, error, data: recipe, refetch } = useQuery(`recipe/${id}`, async () => { 
        const result = await getRecipeDetail(id);

        if(!result.isError) {
            return result.content;
        }   
    });

    const handleDateSelection = (date?: Date) => {
        if(date) {
            setDate(date);
        }
    };

    const addToNutritionDiary = async (recipe: string, servings: number) => {
        const result = await createNutritionDiaryEntry({
            recipe,
            servings,
            date,
            category: selectedCategory ?? 0
        });

        if(!result.isError) {
            toast.success("Item added to nutrition diary.");
            setAddToDiaryModal(false);
        }
    };
    
    return (
        <>
        <PublicLayout>
            {recipe && !isLoading &&
                <div className="relative mb-2 sm:hidden sm:mb-0">
                    <div className="relative bg-gray-200 h-60 md:h-80">
                        <Image src={`${apiUri}/api/file/${recipe.image}`} className="w-full h-full object-fit object-cover" />
                    </div>
                </div>
            }
            <div className="px-6 py-8">
                <div className="w-full max-w-screen-xl m-auto">
                    {recipe && !isLoading &&
                        <div className="sm:grid gap-4 md:grid-cols-2 md:gap-12 mb-16">
                            <div className="hidden relative mb-2 sm:block sm:mb-0">
                                <div className="relative bg-gray-200 h-60 md:h-80">
                                    <Image src={`${apiUri}/api/file/${recipe.image}`} className="w-full h-full object-fit object-cover" />
                                </div>
                            </div>
                            <div>
                                <div className="uppercase tracking-widest text-sm mb-2">Recipe</div>
                                <h1 className="font-black text-xl sm:text-3xl tracking-wide font-display uppercase mb-6">{recipe.name}</h1>
                                <div className="grid grid-cols-2 gap-4 max-w-xs relative wrap mb-8">
                                        <div className="flex-none uppercase tracking-widest font-display font-bold text-sm">Calories</div>
                                        <div className="flex items-center">
                                            <div className="w-4 h-4 rounded-full bg-yellow-500"></div>
                                            <div className="uppercase tracking-widest text-sm ml-4">{recipe.kcals} kcals</div>
                                        </div>
                                        <div className="flex-none uppercase tracking-widest font-display font-bold text-sm">Protein</div>
                                        <div className="flex items-center">
                                            <div className="w-4 h-4 rounded-full bg-pink-500"></div>
                                            <div className="tracking-widest text-sm ml-4">{recipe.protein}g</div>
                                        </div>
                                        <div className="flex-none uppercase tracking-widest font-display font-bold text-sm">Carbohydrates</div>
                                        <div className="flex items-center">
                                            <div className="w-4 h-4 rounded-full bg-blue-500"></div>
                                            <div className="tracking-widest text-sm ml-4">{recipe.carbohydrates}g</div>
                                        </div>
                                        <div className="flex-none uppercase tracking-widest font-display font-bold text-sm">Fats</div>
                                        <div className="flex items-center">
                                            <div className="w-4 h-4 rounded-full bg-green-500"></div>
                                            <div className="tracking-widest text-sm ml-4">{recipe.fats}g</div>
                                        </div>
                                        <div className="flex-none uppercase tracking-widest font-display font-bold text-sm">Servings</div>
                                        <div className="flex items-center">
                                            <div className="w-4 h-4 rounded-full bg-yellow-100"></div>
                                            <div className="uppercase tracking-widest text-sm ml-4">{recipe.servings}</div>
                                        </div>
                                </div>
                                <div className="lg:w-2/3">
                                    {!user &&
                                        <Button link={AppPathsPublic.signUp}>Start your free trial</Button>
                                    }
                                    {user && isPremium &&
                                        <Button onClick={() => setAddToDiaryModal(true)}>Add to diary</Button>
                                    }
                                    {user && !isPremium &&
                                        <Button link={`${AppPathsLoggedIn.profile}#mySubscription`}>Upgrade to Premium</Button>
                                    }
                                </div>
                                {isAdmin &&
                                    <div className="lg:w-2/3 mt-4">
                                        <Button variant="gray" link={AppPathsAdmin.editLiveWorkout(recipe._id!)}>Edit</Button>
                                    </div>
                                }
                            </div>
                            <div className="col-span-2">
                                <hr className="my-8" />
                                <h3 className="font-bold uppercase tracking-wider mb-4">Ingredients:</h3>
                                <ul className="list-disc pl-4">
                                    {recipe.ingredients.map(ingredient =>
                                        <li>{ingredient}</li>    
                                    )}
                                </ul>
                                <hr className="my-8" />
                                <h3 className="font-bold uppercase tracking-wider mb-4">Instructions:</h3>
                                <ol className="list-decimal pl-4">
                                    {recipe.directions.map(direction =>
                                        <li>{direction}</li>    
                                    )}
                                </ol>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </PublicLayout>
        <Modal isOpen={addToDiaryModal} closeModal={() => setAddToDiaryModal(false)} title="Add to diary" caption="Select a data and category to add this recipe to your nutrition diary:">
                <div className="w-full">
                    <DayPicker selected={date} mode="single" onSelect={handleDateSelection} />
                    <SmartRadioGroup 
                        onChange={(val: any) => setSelectedCategory(+val)}
                        value={selectedCategory.toString()}
                        options={[
                            {
                                value: NutritionDiaryCategory.BREAKFAST.toString(),
                                label: "Breakfast",
                                description: ""
                            },
                            {
                                value: NutritionDiaryCategory.LUNCH.toString(),
                                label: "Lunch",
                                description: ""
                            },
                            {
                                value: NutritionDiaryCategory.DINNER.toString(),
                                label: "Dinner",
                                description: ""
                            },
                            {
                                value: NutritionDiaryCategory.SNACKS.toString(),
                                label: "Snacks",
                                description: ""
                            },
                        ]} />
                        <label className="">Servings:</label>
                        <Input value={servings} onChange={(e) => setServings(+e.currentTarget.value)} type="number" step={0.00} />
                        <div className="my-4" />
                        <Button onClick={() => addToNutritionDiary(recipe?._id ?? '', servings)}>Add to diary</Button>
                    </div>
            </Modal>
        </>
    );
};