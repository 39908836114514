interface Config {
    apiUri: string;
    stripePk: string;
    gaMeasurementId?: string;
}

const config = (): Config => {
    const apiUri = process.env.REACT_APP_API_URI as string;
    const stripePk = process.env.REACT_APP_STRIPE_PK as string;
    const gaMeasurementId = process.env.REACT_APP_GA_MEASUREMENT_ID as string | undefined;

    return {
        apiUri,
        stripePk,
        gaMeasurementId
    }
};

export default config();