import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { AuthProvider } from './auth';
import { AppRouter } from './routes/AppRoutes';
import { ExerciseProvider } from './exercise';
import { WorkoutProvider } from './workout';
import { config } from './config';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
  const { stripePk } = config;
  const stripePromise = loadStripe(stripePk);
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Elements stripe={stripePromise}>
        <Router>
          <AuthProvider>
            <ExerciseProvider>
              <WorkoutProvider>
                <AppRouter />
              </WorkoutProvider>
            </ExerciseProvider>
          </AuthProvider>
        </Router>
      </Elements>
      <ToastContainer position="bottom-right" />
    </QueryClientProvider>
  );
};

export default App;
