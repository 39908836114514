import { yupResolver } from "@hookform/resolvers/yup";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import * as yup from 'yup';
import { string } from "yup";
import { forgottenPassword, userRegister } from "../../api/authentication";
import { RegisterDto } from "../../api/models/authentication";
import { useAuth } from "../../auth/AuthContext";
import { Button } from "../../components/Button";
import { InputError } from "../../components/Inputs";
import { SmartInput, SmartSelect } from "../../components/SmartForm";
import { SmartRadioGroup } from "../../components/SmartForm/SmartRadioGroup";
import { USER_ROLES, USER_STATUS } from "../../models/user";

export interface ForgottenPasswordInputs {
    email: string;
}

const schema = yup.object({
    email: yup.string().required('Email address is required'),
}).required();

export const ForgottenPasswordForm: React.FC = () => {
    const [error, setError] = useState<string>();
    const [formConfirmation, setFormConfirmation] = useState(false);

    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm<ForgottenPasswordInputs>({
        resolver: yupResolver(schema)
    });


    const forgottenPasswordRequest = useMutation(`${new Date()}-register`, async (email: string) => {
        const result = await forgottenPassword(email);
        
        if(result) {
            setFormConfirmation(true);
        }
    });
    
    const onSubmit = async (values: ForgottenPasswordInputs) => {
        forgottenPasswordRequest.mutate(values.email);
    };

    return (
        <>
            {formConfirmation && 
                <p className="text-center">Password reset email has been sent to <b>{getValues('email')}</b></p>
            }
            {!formConfirmation &&
                <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col h-full'>
                    <div className="mb-4">
                        <SmartInput register={register('email')} placeholder='Email address' label='Email address' error={errors.email?.message} />
                    </div>
                    <InputError error={error} />
                    <div className="mt-4 w-full">
                        <Button type="submit">Submit</Button>
                    </div>
                </form>
            }
        </>
    );
};