import { RegisterForm } from '../../forms/RegisterForm';
import { PublicLayout } from '../../layouts/PublicLayout';

export const RegisterPage: React.FC = () => {
    return (
        <PublicLayout>
            <div className="px-6 py-12">
                <div className="w-full max-w-lg m-auto">
                <h2 className="text-4xl uppercase font-black font-display mb-4 tracking-widest text-center">Sign Up</h2>
                <p className="text-lg text-center m-auto mb-8">Start your trial in 2 easy steps and get ready to get your sweat on.</p>
                <RegisterForm />
                </div>
            </div>
        </PublicLayout>
    );
};