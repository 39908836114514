import { Link } from "react-router-dom";
import { useAuth } from "../../auth/AuthContext";
import { AppPathsPublic } from "../../routes/AppRoutes";

export const FreeTrialBanner: React.FC = () => {

    const { user } = useAuth();

    if(user) return null;

    return (
        <div className="px-6 py-24 bg-gray-900 text-white">
        <div className="w-full max-w-screen-xl m-auto">
          <div className="lg:flex items-center">
            <div className="flex-none text-center lg:text-left mb-8 lg:mb-0">
              <h3 className="text-3xl uppercase font-black font-display mb-4 tracking-widest">Try free for 7 days</h3>
              <p className="font-medium text-lg">Get started with 7 days free of unlimited workouts, recipes, and more. Cancel anytime.</p>
            </div>
            <div className="flex-1 flex justify-center">
              <Link to={AppPathsPublic.signUp} className="uppercase font-medium bg-white rounded-md px-6 py-4 text-black text-lg transition-all hover:opacity-80">Start your free trial</Link>
            </div>
          </div>
        </div>
      </div>
    );
};